import { useCallback, useState } from "react";
import {
  TBookingType,
  TDashboardView,
  TMainTrackAgeFilters,
} from "../../interfaces";

function useDashboard() {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [dashboardView, setDashboardView] =
    useState<TDashboardView>("SIMPLE_VIEW");
  const [ageFilter, setAgeFilter] = useState<TMainTrackAgeFilters>(
    TMainTrackAgeFilters.ALL
  );

  const changeView = useCallback((val: TBookingType) => {
    setView(val);
  }, []);

  const changeSelectedDate = useCallback((val: Date) => {
    setSelectedDate(val);
  }, []);

  const changeDashboardView = useCallback((val: TDashboardView) => {
    setDashboardView(val);
  }, []);

  const changeAgeFilter = useCallback((val: TMainTrackAgeFilters) => {
    setAgeFilter(val);
  }, []);

  return {
    view,
    selectedDate,
    dashboardView,
    ageFilter,
    changeView,
    changeSelectedDate,
    changeDashboardView,
    changeAgeFilter,
  };
}

export default useDashboard;
