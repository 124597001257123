/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useSession } from "../Session";

const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
  const session = useSession();
  let navigate = useNavigate();

  useEffect(() => {
    if (!session.auth) {
      return navigate(ROUTES.LOGIN);
    }
  }, [session]);


  return children;
};

interface PrivateRouteProps {
  children: JSX.Element;
}

export default PrivateRoute;
