import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { useSmsLogs } from "../../context/SmsLogs";
import Row from "./Row";

const Table = () => {
  const { loading, logs } = useSmsLogs();

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div
        className={`dashboard-session-table-wrapper  ${"h-[calc(100%_-_3.5rem)] m-7"} overflow-auto`}
      >
        <table className="min-w-full h-[1px] ">
          <thead>
            <tr>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 w-[25%]"
                scope="col"
              >
                TYPE
              </th>

              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                PHONE NO
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                DATETIME
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                CUSTOMER_NAME
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                CUSTOMER_EMAIL
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {!logs.length && (
              <tr>
                <td colSpan={5} className="text-center p-2 text-gray-400">
                  No Records found
                </td>
              </tr>
            )}
            {logs.map((log, index) => (
              <Row log={log} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
