import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFirebase } from "../components/Firebase";
import { IDriver } from "../interfaces/BookingRegistration";
import {
  IFirebaseBookingDocument,
  IRegistration,
} from "../interfaces/driverRegistration";
import axios from "axios";
import { createBatches } from "../utilities";

export type TBookings = {
  [key: string]: IFirebaseBookingDocument;
};

type TDrivers = {
  [key: string]: IDriver;
};

export type TDriverIndexResult = Pick<
  IDriver,
  "first_name" | "last_name" | "email" | "phone_number" | "racefacer_uuid"
> & {
  objectID: string;
};

interface IDriverRegistrationContext {
  bookingsLoading: boolean;
  driversLoading: boolean;
  bookings: TBookings;
  drivers: TDrivers;
  addToSession: (data: {
    orderId: string;
    added: IRegistration[];
    removed: IRegistration[];
  }) => Promise<{ success: boolean }>;
  addDriverToRace: (driverId: string, orderId: string) => Promise<unknown>;
  removeDriverFromRace: (
    orderId: string,
    driver_firebase_id: string
  ) => Promise<any>;
}

const DriverRegistrationContext =
  React.createContext<IDriverRegistrationContext>({
    bookingsLoading: false,
    driversLoading: false,
    bookings: {},
    drivers: {},
    addToSession: () =>
      Promise.resolve({
        success: true,
      }),
    addDriverToRace: () => Promise.resolve(),
    removeDriverFromRace: () => Promise.resolve(),
  });

interface Type {
  orderIds: string[];
  children: ReactNode;
}

const DriverRegistrationProvider: FC<Type> = ({ children, orderIds }) => {
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [driversLoading, setDriversLoading] = useState(false);
  const [bookings, setBookings] = useState<TBookings>({});
  const [drivers, setDrivers] = useState<TDrivers>({});

  const firebase = useFirebase();

  const driverIds = useMemo(
    () =>
      Object.values(bookings)
        .map((booking) => booking.drivers)
        .flat(),
    [bookings]
  );

  const db = useMemo(() => firebase.db, [firebase]);
  const bookingRef = useMemo(
    () =>
      collection(
        db,
        "bookings"
      ) as CollectionReference<IFirebaseBookingDocument>,
    [db]
  );
  const driverRef = useMemo(
    () => collection(db, "drivers") as CollectionReference<IDriver>,
    [db]
  );

  const refreshBookings = useCallback(
    async (orderIds: string[]) => {
      setBookingsLoading(true);
      try {
        const chunkedIds = [];

        for (let i = 0; i < orderIds.length; i += 10) {
          const chunk = orderIds.slice(i, i + 10);
          chunkedIds.push(chunk);
        }

        const qsBookings = await Promise.all(
          chunkedIds.map((ids) =>
            getDocs(query(bookingRef, where("orderId", "in", ids)))
          )
        );

        const data: TBookings = {};

        qsBookings.forEach((qs) =>
          qs.docs.forEach((doc) => {
            data[doc.id] = doc.data();
          })
        );

        setBookings(data);
      } catch (error) {
        console.log("🚀 ~ file: DriverRegistration.tsx:102 ~ error:", error);
      } finally {
        setBookingsLoading(false);
      }
    },
    [bookingRef]
  );

  const refreshDrivers = useCallback(
    async (driverIds: string[]) => {
      setDriversLoading(true);
      try {
        const chunkedIds = [];

        for (let i = 0; i < driverIds.length; i += 10) {
          const chunk = driverIds.slice(i, i + 10);
          chunkedIds.push(chunk);
        }

        const qsDrivers = await Promise.all(
          chunkedIds.map((ids) =>
            getDocs(query(driverRef, where("firebase_id", "in", ids)))
          )
        );

        const data: TDrivers = {};

        qsDrivers.forEach((qs) =>
          qs.docs.forEach((doc) => {
            data[doc.id] = doc.data();
          })
        );

        setDrivers(data);
      } catch (error) {
        console.log("🚀 ~ file: DriverRegistration.tsx:153 ~ error:", error);
      } finally {
        setDriversLoading(false);
      }
    },
    [driverRef]
  );

  const addToSession = useCallback(
    async ({
      added,
      removed,
      orderId,
    }: {
      orderId: string;
      added: IRegistration[];
      removed: IRegistration[];
    }) => {
      const { data } = await axios.post(
        "https://us-central1-hyper-karting.cloudfunctions.net/driverSession",
        // "http://127.0.0.1:5001/hyper-karting/us-central1/driverSession",
        { added, removed, orderId }
      );
      await refreshBookings(orderIds);
      return data;
    },
    [orderIds, refreshBookings]
  );

  const addDriverToRace = useCallback(
    async (driverId: string, orderId: string) => {
      const booking = bookings[orderId];
      if (booking?.drivers && booking.drivers.includes(driverId)) {
        alert("Driver already added to race");
        return;
      }
      const snapshot = await getDoc(doc(driverRef, driverId));
      const driver = snapshot.data();
      const { data } = await axios.post(
        "https://us-central1-hyper-karting.cloudfunctions.net/addDriverToBooking",
        // "http://127.0.0.1:5001/hyper-karting/us-central1/addDriverToBooking",
        {
          data: {
            drivers: [driver],
            orderId,
          },
        }
      );
      return data;
    },
    [driverRef, bookings]
  );

  const removeDriverFromRace = useCallback(
    async (orderId: string, driver_firebase_id: string) => {
      const { data } = await axios.post(
        "https://us-central1-hyper-karting.cloudfunctions.net/removeDriverFromBooking",
        // "http://127.0.0.1:5001/hyper-karting/us-central1/removeDriverFromBooking",
        {
          driver_firebase_id,
          orderId,
        }
      );
      return data;
    },
    []
  );

  useEffect(() => {
    refreshBookings(orderIds);
  }, [orderIds, refreshBookings]);

  useEffect(() => {
    refreshDrivers(driverIds);
  }, [driverIds, refreshDrivers]);

  useEffect(() => {
    //Listen realtime changes
    if (!orderIds || orderIds.length === 0) return;

    const batches = createBatches(orderIds, 10)

    const unsubscribes = batches.map(batch=>{
      const q = query(bookingRef, where('__name__', 'in', batch));
  
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedBookings:TBookings = {};
        snapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data()
          if(data){
            updatedBookings[id] = data;
          }
        });
        setBookings((oldState) => ({ ...oldState, ...updatedBookings }));
      });

      return unsubscribe;
    })


    return () => {
      unsubscribes.map(unsubscribe=>unsubscribe())
    };
    
  }, [bookingRef, orderIds]);

  return (
    <DriverRegistrationContext.Provider
      value={{
        bookingsLoading,
        driversLoading,
        bookings,
        drivers,
        addToSession,
        addDriverToRace,
        removeDriverFromRace,
      }}
    >
      {children}
    </DriverRegistrationContext.Provider>
  );
};

export function useDriverRegistration() {
  return useContext(DriverRegistrationContext);
}

export default DriverRegistrationProvider;
