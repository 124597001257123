import React from "react";

interface DashboardProps {
  Header?: React.ReactNode;
  children: React.ReactNode;
}

export const PageLayout = ({ Header, children }: DashboardProps) => {
  return (
    <>
      <div className="min-h-full">
        {/* Main column */}
        <div className="xl:pl-64 flex flex-col">
          {/* Search header */}
          <main className="flex-1">
            {/* Page title & actions */}
            {!!Header && (
              <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:pl-4">
                <div className="flex min-w-3 w-[100%] justify-between items-center gap-4">
                  {Header}
                </div>
              </div>
            )}

            {/* Projects table (small breakpoint and up) */}
            <div
              style={{
                height: `calc(100vh - ${!!Header ? 80 : 0}px)`,
                overflow: `hidden`,
              }}
            >
              <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-scroll w-full h-full bg-gray-200">
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
