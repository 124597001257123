import React, { useCallback, useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { DateTime } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";

interface Props {
  defaultValue?: Date;
  onDateChange?: (date: Date) => void;
}

const Calender = ({ onDateChange, defaultValue }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue || new Date());
  const [showDateRange, setShowDateRange] = useState<boolean>(false);

  const handleSelect = useCallback((date: Date) => {
    setSelectedDate(date);
    setShowDateRange(false);
  }, []);

  const handlePrevDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).minus({ days: 1 });
    setSelectedDate(date.toJSDate());
    setShowDateRange(false);
  }, [selectedDate]);

  const handleNextDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).plus({ days: 1 });
    setSelectedDate(date.toJSDate());
    setShowDateRange(false);
  }, [selectedDate]);

  useEffect(() => {
    onDateChange && onDateChange(selectedDate);
  }, [onDateChange, selectedDate]);

  return (
    <>
      <div className="date-range-wrapper flex justify-center relative h-full">
        <div className="flex items-center bg-[#EBECEF] rounded-lg">
          <ChevronLeftIcon className="w-10 h-10 mr-2 cursor-pointer text-gray-800" onClick={handlePrevDayClick} />
          <span className="cursor-pointer font-medium text-base leading-tight text-gray-700" onClick={() => setShowDateRange(true)}>
            {selectedDate.toDateString()}
          </span>
          <ChevronRightIcon className="w-10 h-10 ml-2 cursor-pointer text-gray-800" onClick={handleNextDayClick} />
        </div>
        <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3 hidden lg:flex" onClick={() => handleSelect(new Date())}>
          <CalendarIcon className="w-6 h-6 cursor-pointer text-gray-800" />
        </div>
        {showDateRange && (
          <>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRange(false);
              }}
            >
              <Calendar className="absolute right-0 top-10 shadow-md z-50" date={selectedDate} onChange={handleSelect} weekStartsOn={1}/>
            </OutsideClickHandler>
          </>
        )}
      </div>
    </>
  );
};

export default Calender;
