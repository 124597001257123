import {
  ArrowUpIcon,
  DocumentIcon,
  EyeIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import Label from "./Label";
import { FC, useCallback, useState } from "react";
import LoadingSpinner from "../Loading";
import axios from "axios";
import Dropzone, { Accept } from "react-dropzone";

interface Props {
  required?: boolean;
  label?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  accept: Accept;
  onChange?: (files: string[]) => void;
  maxFiles?: number;
  prefix: string;
  subPath: string;
}
const FileUpload: FC<Props> = ({
  label,
  hideLabel,
  required,
  accept,
  onChange,
  maxFiles,
  prefix,
  subPath,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [isFileDragging, setIsFileDragging] = useState(false);

  const updateFiles = useCallback(
    (newFiles: string[]) => {
      setFiles(newFiles);
      onChange && onChange(newFiles);
    },
    [onChange]
  );

  const uploadFilesToServer = useCallback(
    (filesToUpload: File[]) => {
      setIsUploading(true);
      let data = new FormData();
      for (const file of filesToUpload) {
        data.append(file.name, file, file.name);
      }
      data.append("prefix", prefix);
      data.append("subPath", subPath);

      axios
        .post(
          // "http://localhost:5001/hyper-karting/us-central1/fileUpload",
          "https://us-central1-hyper-karting.cloudfunctions.net/fileUpload",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.success && Array.isArray(data.data)) {
            const newFiles = [...files, ...data.data];
            updateFiles(newFiles);
          }
        })
        .catch(alert)
        .finally(() => setIsUploading(false));
    },
    [prefix, subPath, files, updateFiles]
  );

  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      console.log("acceptedFiles", acceptedFiles.length);

      const uploadedFiles = [...((acceptedFiles as any) || [])];
      if (uploadedFiles.length) {
        uploadFilesToServer(uploadedFiles);
      }
      setIsFileDragging(false);
    },
    [uploadFilesToServer]
  );

  const deleteFile = useCallback(
    (removeToFile: string) => () => {
      const newFiles = files.filter((file) => file !== removeToFile);
      updateFiles(newFiles);
    },
    [files, updateFiles]
  );
  const viewFile = useCallback(
    (file: string) => () => {
      // window.location.href = file;
      window.open(file, "_blank");
    },
    []
  );

  return (
    <div>
      {!hideLabel && !!label && (
        <Label showRequiredSymbol={required}>{label}</Label>
      )}
      <div className="border border-dashed border-[#C2C0C3] p-5 rounded-lg flex">
        {isUploading ? (
          <div className="flex justify-center w-full">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Dropzone
              onDrop={handleOnDrop}
              onDragEnter={() => setIsFileDragging(true)}
              onDragLeave={() => setIsFileDragging(false)}
              accept={accept}
              maxFiles={maxFiles}
            >
              {({ getRootProps, getInputProps, open }) => (
                <>
                  <div className="flex-1">
                    <label
                      className={`flex flex-col justify-center items-center gap-3 cursor-pointer h-full`}
                      {...getRootProps()}
                    >
                      {isFileDragging ? (
                        <>
                          <p className="text-xs not-italic font-medium leading-[normal] font-[Montserrat]">
                            <span className="text-[#9D62FE]">Drop here...</span>
                          </p>
                        </>
                      ) : (
                        <>
                          <ArrowUpIcon className="text-[#9D62FE] w-10" />
                          <p className="text-xs not-italic font-medium leading-[normal] font-[Montserrat]">
                            Drag and drop files here or{" "}
                            <span className="text-[#9D62FE]">Browse files</span>
                          </p>
                        </>
                      )}
                    </label>
                    <input type="file" {...getInputProps()} />
                  </div>
                </>
              )}
            </Dropzone>
            {!!files.length && (
              <>
                <div className="border border-[#EBECEF] mx-4"></div>
                <div className="flex-1 flex flex-col truncate">
                  {files.map((file) => (
                    <div
                      className="flex gap-2 items-center border-b-[#EBECEF] border-b border-solid p-2 truncate"
                      key={file}
                    >
                      <DocumentIcon className="w-5 text-[#C2C0C3]" />
                      <p
                        className="text-[10px] not-italic font-medium leading-[115%] flex-1 truncate"
                        title={file}
                      >
                        {file}
                      </p>
                      <EyeIcon
                        className="w-5 cursor-pointer"
                        onClick={viewFile(file)}
                      />
                      <TrashIcon
                        className="w-5 text-red-600 cursor-pointer"
                        onClick={deleteFile(file)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
