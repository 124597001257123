import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = (props: ModalProps) => {
  const { show, title, onClose, children, className } = props;
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center m-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full ${
                  title && title?.toString().toLowerCase() === "create new event" ? "max-w-[600px]" : "max-w-screen-2xl"
                } transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-h-[700px] h-full ${className || ""}`}
              >
                <Dialog.Title
                  as="h3"
                  className="text-3xl font-medium leading-6 text-gray-900 flex justify-between"
                >
                  {title || ""}
                  <div onClick={onClose} className="cursor-pointer ml-auto">
                    &times;
                  </div>
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface ModalProps {
  show: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}

export default Modal;
