import React from "react";
// import { collection, query, where, getDocs } from "firebase/firestore";

// import LoadingSpinner from "../Loading";


export const FunctionCalendar = () => {


    return  <>
    <div className="min-h-full">
      {/* Main column */}
      <div className="xl:pl-64 flex flex-col">
        {/* Search header */}
         
        <main className="flex-1">
          <div className="pt-8 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 pb-3">Function Calendar</h1>
                <p className="mt-2 text-sm text-gray-700">
                 
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <iframe title="calendar" className="w-full h-[90vh]" src="https://calendar.google.com/calendar/embed?src=admin%40hyperkarting.com.au&ctz=Australia%2FSydney"></iframe>
          </div>
        </main>
      </div>
    </div>
  </>
}

