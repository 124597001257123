import { IOrder, OrderFinancialStatus } from "shopify-api-node";
import {
  BREAK_BOOKING_NAME,
  SPECIAL_TIMES_WITH_BREAKS,
} from "../../constants/Dashboard";
import { ILineItemWithOrder } from "../../interfaces/Session";

export const getStatusClass = (status: OrderFinancialStatus): string => {
  switch (status) {
    case "paid":
      return "text-white bg-green-600 border border-green-600 rounded px-1 py-1 w-24 mx-1 h-[90%] hidden";
    case "refunded":
      return "text-white bg-red-600 border border-red-600 rounded px-1 py-1 w-24 mx-1 h-[90%]";
    case "partially_refunded":
      // Hide partrefund button till we find other solution to display on ipad
      return "bg-[#fd8f00] text-white bg-orange-600 border border-[#fd8f00] rounded px-1 py-1 w-24 mx-1 h-[90%]";
    case "partially_paid":
      return "bg-[#53ed6d] text-white bg-orange-600 border border-[#53ed6d] rounded px-1 py-1 w-24 mx-1 h-[90%]";
    default:
      return "bg-blue text-white bg-orange-600 border border-orange-600 rounded px-1 py-1 mx-1 h-[90%]";
  }
};

export const getFinancialStatus = (order: IOrder) => {
  switch (order.financial_status) {
    case "paid":
      return "Paid";
    case "refunded":
      return "Refunded";
    case "partially_refunded":
      return "Part Refund";
    case "partially_paid":
      return "Part Paid";
    default:
      return order.financial_status;
  }
};

export const mainTrackSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats === 0:
      return "#ffffff";
    case numSeats <= 4:
      return "#63ff00";
    case numSeats <= 8:
      return "#FFFF00";
    case numSeats <= 12:
      return "#ffb201";
    case numSeats <= 14:
      return "#ff0000";
    case numSeats > 14:
      return "#ff00ff";
  }
};

export const miniTrackSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats >= 0 && numSeats <= 2:
      return "#63ff00";
    case numSeats >= 3 && numSeats <= 4:
      return "#FFFF00";
    case numSeats >= 5 && numSeats <= 6:
      return "#ff0000";
    case numSeats >= 7:
      return "#ff00ff";
  }
};

export const vrSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats > 0 && numSeats <= 2:
      return "#63ff00";
    case numSeats >= 3 && numSeats <= 4:
      return "#FFFF00";
    case numSeats >= 5 && numSeats <= 6:
      return "#ffb201";
    case numSeats >= 7:
      return "#ff0000";
  }
};

export const getDriverCount = (booking: ILineItemWithOrder): number => {
  let driverCount = booking.quantity;
  const lineItemId = booking.id;
  const parentOrder = booking.order;
  const isBookingWithBreak =
    booking.order.customer?.first_name
      ?.toLowerCase()
      .includes(BREAK_BOOKING_NAME.toLowerCase()) &&
    SPECIAL_TIMES_WITH_BREAKS.some((time) =>
      booking.variant_title.includes(time)
    );

  if (isBookingWithBreak) {
    driverCount = 0;
  } else {
    // Remove refunded line items
    parentOrder.refunds.forEach((refund) => {
      const applicableLineItems = refund.refund_line_items.filter(
        (re) => re.line_item_id === lineItemId
      );
      applicableLineItems.forEach((li) => {
        if (li.restock_type === "return" || li.restock_type === "cancel") {
          driverCount = driverCount - li.quantity;
        }
      });
    });
  }

  return driverCount;
};

export const getRestockTypeColorClasses = (type:'no_restock' | 'cancel' | 'return' | 'legacy_restock')=>{
  let classNames = "";
  switch (type) {
    case "cancel":
      classNames += "bg-red-500"
      break;
    case "legacy_restock":
      classNames += "bg-yellow-500"
      break;
    case "no_restock":
      classNames += "bg-amber-500"
      break;
    case "return":
      classNames += "bg-orange-500"
      break;
  
    default:
      break;
    }
    return classNames;
  
}
export const getRestockTypeLabel = (type:'no_restock' | 'cancel' | 'return' | 'legacy_restock', part_refunded:boolean)=>{
  switch (type) {
    case "cancel":
      return "Canceled"
    case "legacy_restock":
    case "no_restock":
      return part_refunded ? "Part Refunded": "Refunded"
    case "return":
      return part_refunded ? "Part Refunded" :"Returned"
    }
  
}