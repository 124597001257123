import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TArcadeGameCardFilters,
  TArcadeGameCardSortOptions,
} from "../interfaces";
import { IFirebaseOrder } from "../interfaces/firebaseEntities";
import { ARCADE_CARD_PRODUCT_ID } from "../constants/Dashboard";
import axios from "axios";
import { IOrderLineItem } from "shopify-api-node";
import { useFirebase } from "../components/Firebase";
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  OrderByDirection,
} from "firebase/firestore";
import { useSession } from "../components/Session";

interface IItem {
  id: string;
  index: number;
  lineItemId: number;
  orderId: number;
  li: IOrderLineItem;
}

interface ISaveCardNumberPayload {
  id: string;
  card_number: string;
  orderId: number;
  lineItemId: number;
  index: number;
}

interface ILoadMoreOrdersArgs {
  searchText?: string;
  startAfterDoc?: QueryDocumentSnapshot<IFirebaseOrder>;
  direction?: OrderByDirection;
}

export interface IArcadeCardContext {
  filter: TArcadeGameCardFilters;
  sort: TArcadeGameCardSortOptions;
  applyFilter: (val: TArcadeGameCardFilters) => void;
  applySorting: (val: TArcadeGameCardSortOptions) => void;
  isOrderLoading: boolean;
  loadingCardNumber: boolean;
  arcadeCardOrders: IFirebaseOrder[];
  cardNumbers: {
    [key: string]: ISaveCardNumberPayload;
  };
  saveCardNumber: (data: ISaveCardNumberPayload) => void;
  generateId: (orderId: number, lineItemId: number, index: number) => string;
  getItemsFromOrder: (order: IFirebaseOrder) => IItem[];
  applySearch: (val: string) => void;
  refresh: () => void;
  pageNumber: number;
  pageSize: number;
  onNextPageBtnClick: () => void;
  onPrevPageBtnClick: () => void;
  updaseAllOrders: (data: IFirebaseOrder[]) => void;
  totalRecords: number;
  paginatedOrders: IFirebaseOrder[];
  totalPages: number;
  withPagination: boolean;
}

const ArcadeCardContext = React.createContext<IArcadeCardContext>({
  filter: "NOT_ARCHIVED",
  sort: "DESCENDING",
  applyFilter: () => {},
  applySorting: () => {},
  arcadeCardOrders: [],
  isOrderLoading: false,
  cardNumbers: {},
  saveCardNumber: () => {},
  loadingCardNumber: false,
  generateId: () => "",
  getItemsFromOrder: () => [],
  applySearch: () => {},
  refresh: () => {},
  pageNumber: 0,
  pageSize: 0,
  onNextPageBtnClick: () => {},
  onPrevPageBtnClick: () => {},
  updaseAllOrders: () => {},
  totalRecords: 0,
  paginatedOrders: [],
  totalPages: 0,
  withPagination: false,
});

interface Type {
  children: ReactNode;
  type: "order-level" | "arcade-cards-tab";
}

const ArcadeCardProvider: FC<Type> = ({ children, type }) => {
  const pageSize = 250;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingCardNumber, setLoadingCardNumber] = useState(false);
  const [filter, setFilter] = useState<TArcadeGameCardFilters>("NOT_ARCHIVED");
  const [sort, setSort] = useState<TArcadeGameCardSortOptions>("DESCENDING");
  const [searchText, setSearchText] = useState("");
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const firebase = useFirebase();
  const { getToken } = useSession();

  const db = useMemo(() => firebase.db, [firebase]);
  const orderRef = useMemo(() => collection(db, "orders"), [db]);
  const arcadeCardsRef = useMemo(() => collection(db, "arcade_cards"), [db]);

  const isMounted = useRef<boolean>();
  const [startAfterDoc, setStartAfterDoc] = useState<
    QueryDocumentSnapshot<IFirebaseOrder> | undefined
  >();

  const [allArcadeCardOrders, setAllArcadeCardOrders] = useState<
    IFirebaseOrder[]
  >([]);
  const [arcadeCardOrders, setArcadeCardOrders] = useState<IFirebaseOrder[]>(
    []
  );

  const [cardNumbers, setCardNumbers] = useState<{
    [key: string]: ISaveCardNumberPayload;
  }>({});

  const applySearch = useCallback((val: string) => {
    setSearchText(val);
  }, []);

  const updaseAllOrders = useCallback((data: IFirebaseOrder[]) => {
    setAllArcadeCardOrders(data);
  }, []);

  //loading cardNumber based on orders
  useEffect(() => {
    (async () => {
      try {
        setLoadingCardNumber(true);

        const orderIds = allArcadeCardOrders.map((order) => order.id);

        const chunkedIds = [];

        for (let i = 0; i < orderIds.length; i += 10) {
          const chunk = orderIds.slice(i, i + 10);
          chunkedIds.push(chunk);
        }

        const qsArcadeCards = await Promise.all(
          chunkedIds.map((ids) =>
            getDocs(query(arcadeCardsRef, where("orderId", "in", ids)))
          )
        );
        const data: {
          [key: string]: any;
        } = {};

        qsArcadeCards.forEach((qs) =>
          qs.docs.forEach((doc) => {
            data[doc.id] = doc.data();
          })
        );

        setCardNumbers(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCardNumber(false);
      }
    })();
  }, [allArcadeCardOrders, arcadeCardsRef]);

  const saveCardNumber = useCallback(
    async ({
      id,
      card_number,
      orderId,
      lineItemId,
      index,
    }: ISaveCardNumberPayload) => {
      try {
        const token = await getToken();

        const { data } = await axios.post(
          // "http://localhost:5000/hyper-karting/us-central1/arcadeCard",
          "https://us-central1-hyper-karting.cloudfunctions.net/arcadeCard",
          {
            orderId,
            lineItemId,
            index,
            card_number,
            id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCardNumbers((oldState) => ({
          ...oldState,
          [id]: {
            id,
            card_number,
            orderId,
            lineItemId,
            index,
          },
        }));
        console.log("data", data);
      } catch (error) {
        console.log(error);
      }
    },
    [getToken]
  );

  const generateId = useCallback(
    (orderId: number, lineItemId: number, index: number) => {
      return `${orderId}:${lineItemId}#${index}`;
    },
    []
  );

  const getItemsFromOrder = useCallback(
    (order: IFirebaseOrder) => {
      const lineItems = order.line_items.filter(
        (li) => li.product_id === ARCADE_CARD_PRODUCT_ID
      );
      const items: IItem[] = [];

      lineItems.forEach((li) => {
        new Array(li.quantity)
          .fill(0)
          .map((_, i) => i + 1)
          .forEach((i) =>
            items.push({
              id: generateId(order.id, li.id, i),
              index: i,
              li: li,
              lineItemId: li.id,
              orderId: order.id,
            })
          );
      });

      return items;
    },
    [generateId]
  );

  //paginated get orders
  const loadMoreOrders = useCallback(
    async (data?: ILoadMoreOrdersArgs) => {
      try {
        console.log("Fetching ********");

        // const text = Object.keys(data || {}).includes("searchText")
        //   ? data?.searchText
        //   : searchText;

        const after = Object.keys(data || {}).includes("startAfterDoc")
          ? data?.startAfterDoc
          : startAfterDoc;

        const searchDirection: OrderByDirection = data?.direction
          ? data.direction
          : sort === "ASCENDING"
          ? "asc"
          : "desc";

        setIsOrderLoading(true);

        const quertyInput = [
          orderRef,
          where("product_ids", "array-contains", ARCADE_CARD_PRODUCT_ID),
          orderBy("created_at_ts", searchDirection),
          limit(pageSize),
        ];

        // if (text) {
        //   quertyInput.splice(
        //     1,
        //     0,
        //     where("customer.first_name", "array-contains", text)
        //   );
        // }

        if (after) {
          quertyInput.splice(quertyInput.length - 1, 0, startAfter(after));
        }

        // const orderQuery = searchText
        //   ? query(
        //       orderRef,
        //       where("customer.first_name", "array-contains", searchText),
        //       where("product_ids", "array-contains", ARCADE_CARD_PRODUCT_ID),
        //       orderBy("created_at_ts", searchDirection),
        //       limit(pageSize)
        //     )
        //   : query(
        //       orderRef,
        //       where("product_ids", "array-contains", ARCADE_CARD_PRODUCT_ID),
        //       orderBy("created_at_ts", "asc"),
        //       limit(pageSize)
        //     );

        // @ts-ignore
        const qs = await getDocs<IFirebaseOrder>(query(...quertyInput));
        // const qs = await getDocs<IFirebaseOrder>(orderQuery);

        if (!after) {
          const noOfRecords = // @ts-ignore
            (await getDocs(query(...quertyInput.slice(0, -1)))).size;
          setTotalRecords(noOfRecords);
        }

        // console.log("count===>", qs.size);

        setIsOrderLoading(false);

        // const temp = await getDocs(
        //   query(
        //     orderRef,
        //     where("product_ids", "array-contains", ARCADE_CARD_PRODUCT_ID),
        //     orderBy("created_at_ts", searchDirection),
        //     startAfter(qs.docs[qs.docs.length - 1]),
        //     limit(pageSize)
        //   )
        // );
        const docs = qs.docs;
        const lastDocArr = docs.slice(-1);
        setStartAfterDoc(lastDocArr.length ? lastDocArr[0] : undefined);
        const newOrders = docs.map((doc) => doc.data());

        return newOrders;
      } catch (error) {
        console.log("error", error);
        return [];
      }
    },
    [orderRef, pageSize, startAfterDoc, sort]
  );

  const applyFilter = useCallback(
    async (filterType: TArcadeGameCardFilters) => {
      //start from first page because this filter is handeled from firebase query
      setPageNumber(1);
      setFilter(filterType);
    },
    []
  );

  const applySorting = useCallback(
    async (sortOption: TArcadeGameCardSortOptions) => {
      //start from first page because this filter is handeled from firebase query
      setPageNumber(1);
      let orders = JSON.parse(
        JSON.stringify(allArcadeCardOrders)
      ) as IFirebaseOrder[];
      setStartAfterDoc(undefined);
      orders = await loadMoreOrders({
        startAfterDoc: undefined,
        direction: sortOption === "ASCENDING" ? "asc" : "desc",
      });
      setAllArcadeCardOrders(orders);
      setArcadeCardOrders(orders);

      setSort(sortOption);
    },
    [allArcadeCardOrders, loadMoreOrders]
  );

  const loadInitialPage = useCallback(async () => {
    const orders = await loadMoreOrders();
    setAllArcadeCardOrders(orders);
  }, [loadMoreOrders]);

  const refresh = useCallback(() => {
    setAllArcadeCardOrders([]);
    setArcadeCardOrders([]);
    setSearchText("");
    setFilter("NOT_ARCHIVED");
    setSort("DESCENDING");
    setPageNumber(1);
    loadInitialPage();
  }, [loadInitialPage]);

  const onNextPageBtnClick = useCallback(async () => {
    const newPageNumber = pageNumber + 1;
    setPageNumber(newPageNumber);
    if (arcadeCardOrders.length < newPageNumber * pageSize) {
      const orders = await loadMoreOrders();
      setAllArcadeCardOrders((oldState) => [...oldState, ...orders]);
    }
  }, [loadMoreOrders, arcadeCardOrders, pageNumber, setAllArcadeCardOrders]);

  const onPrevPageBtnClick = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  //load order initially
  useEffect(() => {
    //For arcade card tab load initial records
    if (!isMounted.current && type === "arcade-cards-tab") {
      isMounted.current = true;
      loadInitialPage();
    }
  }, [loadInitialPage, type]);

  //Handling of client side filter
  useEffect(() => {
    let orders = JSON.parse(
      JSON.stringify(allArcadeCardOrders)
    ) as IFirebaseOrder[];

    if (filter === "ARCHIVED") {
      orders = orders.filter((order) => {
        const items = getItemsFromOrder(order);
        return items.some((item) => cardNumbers[item.id]?.card_number);
      });
    }

    if (searchText) {
      orders = orders.filter((order) => {
        const name =
          `${order.customer?.first_name} ${order.customer?.last_name}`.toLowerCase();
        return (
          name.includes(searchText.toLowerCase()) ||
          (!isNaN(searchText as any) && +searchText === order.id)
        );
      });
    }
    setArcadeCardOrders(orders);
  }, [allArcadeCardOrders, filter, cardNumbers, searchText, getItemsFromOrder]);

  const withPagination = useMemo(
    () => filter !== "ARCHIVED" && !searchText && type !== "order-level",
    [searchText, filter, type]
  );

  const paginatedOrders = useMemo(() => {
    if (!withPagination) {
      return arcadeCardOrders;
    }
    return arcadeCardOrders.slice(
      (pageNumber - 1) * pageSize,
      (pageNumber - 1) * pageSize + pageSize
    );
  }, [arcadeCardOrders, pageNumber, pageSize, withPagination]);

  const totalPages = useMemo(
    () => Math.floor(totalRecords / pageSize),
    [totalRecords, pageSize]
  );

  return (
    <ArcadeCardContext.Provider
      value={{
        filter,
        sort,
        applyFilter,
        applySorting,
        isOrderLoading,
        arcadeCardOrders,
        cardNumbers,
        saveCardNumber,
        loadingCardNumber,
        generateId,
        getItemsFromOrder,
        applySearch,
        refresh,
        pageNumber,
        pageSize,
        onNextPageBtnClick,
        onPrevPageBtnClick,
        updaseAllOrders,
        totalRecords,
        paginatedOrders,
        totalPages,
        withPagination,
      }}
    >
      {children}
    </ArcadeCardContext.Provider>
  );
};

export function useArcadeCard() {
  return useContext(ArcadeCardContext);
}

export default ArcadeCardProvider;
