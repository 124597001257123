import React, { useMemo } from 'react'
import { ISessionEditorLog } from '../../interfaces/types'
import { TLogType } from '../../interfaces'
import { DateTime } from 'luxon'
import { getMaxNumberOfSession, getTrackDisplayName, getTrackFromSku } from '../../utilities'
import { IProductVariant } from 'shopify-api-node'

const TRow = ({ logType, log, lastLog, variantId }: { log: ISessionEditorLog, logType: TLogType, lastLog?:ISessionEditorLog, variantId: number }) => {
    if(logType === "QUANTITY_ADJUSTED"){
      const variant = log.newVariants.find(v=>v.id === variantId);
      return (
        <tr>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-start flex-shrink-0 h-full">
              <SessionDetail variant={variant!}/>
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-center flex-shrink-0 h-full">
              {log.quantity_changes[variantId] === 0 ? (
                <div className="truncate text-white bg-red-500 p-1">Closed</div>
              ):(
                <div className="truncate text-white bg-green-500 p-1">Opened</div>
              )}
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-center flex-shrink-0 h-full">
              <div className="truncate">{log.user.name}</div>
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex flex-col justify-center flex-shrink-0 h-full items-center">
              <p className="text-[#777]">
                {DateTime.fromMillis(log.ts).toFormat("dd LLL yyyy hh:mma")}
              </p>
            </div>
          </td>
        </tr>
      )
    }

    if(logType === "PRICE_CHANGER"){
        const variant = log.newVariants.find(v=>v.id === variantId);
        const oldVariant = log.oldVariants.find(v=>v.id === variantId);
        const updatedAfter = lastLog ? DateTime.fromMillis(log.ts).diff(DateTime.fromMillis(lastLog.ts), [ 'days', 'hours' ]).toHuman({ maximumFractionDigits: 0 }) : "";

        return (
          <tr>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-start flex-shrink-0 h-full">
              <SessionDetail variant={variant!}/>
            </div>
          </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{lastLog?.user?.name}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{updatedAfter}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{log.user.name}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate"><del>{oldVariant?.price}</del> ↔︎ {variant?.price}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{variant?.inventory_quantity}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{(variant?.inventory_quantity === 0) ? 0 : (getMaxNumberOfSession(getTrackFromSku(variant?.sku!)) - variant?.inventory_quantity!)}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{variant?.sku}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex flex-col justify-center flex-shrink-0 h-full items-center">
                <p className="text-[#777]">
                  {DateTime.fromMillis(log.ts).toFormat("dd LLL yyyy hh:mma")}
                </p>
              </div>
            </td>
          </tr>
        )
    }

    return (
        <></>
    )
}

const SessionDetail = ({ variant }:{ variant: IProductVariant })=>{
  const type = useMemo(() => getTrackFromSku(variant.sku), [variant]);
  const options = useMemo(() => {
    if(type === "MAIN_TRACK"){
      return `${variant.option3} - ${variant.option1} - ${variant.option2}`
    }else if (type === "MINI_TRACK"){
      return `${variant.option1}`
    }else{
      return `${variant.option1} - ${variant.option2}`
    }
  }, [type, variant])
  
  return (
    <div className='flex flex-col justify-center items-start gap-1'>
      {(variant && type) && (
        <>
          <div className={`font-bold ${type === "MAIN_TRACK" ? "text-[#FF00FF]" : (type === "MINI_TRACK" ? "text-[#9D62FE]" : "text-[#41BDFE]")}`}>{getTrackDisplayName(type)}</div>
          <div>{options}</div>
          {/* <div>{getDatetimeFromSku(variant.sku, type)?.toFormat("hh:mma")}</div> */}
        </>
      )}
      <div><a href={`https://admin.shopify.com/store/hyper-karting/products/${variant.product_id}/variants/${variant.id}`} target='_blank' rel="noreferrer" className='text-black underline'>View Session</a></div>
    </div>
  )
}

export default TRow