export const NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK = 16;
export const NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK = 8;
export const NUMBER_OF_SEATS_IN_A_VR = 6;
export const BREAK_BOOKING_NAME = "BREAK";
export const MAIN_TRACK = "MAIN_TRACK";
export const MINI_TRACK = "MINI_TRACK";
export const ACTIVE_SUBSCRIBER = "Active Subscriber";
export const BOOKING_INSURANCE_PRODUCT_ID = 7463817281696;
export const ARCADE_CARD_PRODUCT_ID = 8875132387488;
export const PIZZA_PRODUCT_ID = 14362671644832;
export const INACTIVE_SUBSCRIBER = "Inactive Subscriber";
export const TAG_KPR_ORDER = "KPR";
export const TRIAL_SUBSCRIBER = "Trial Member";
export const VIP_MEMBER = ["VIP Level 3", "VIP Level 4"];
export const EXPERT_RACE_TIMINGS = ["7:30am", "8:00am", "8:45pm", "9:15pm"];
export const HIDDEN_TIMES = [
  "6:00am",
  "6:15am",
  "12:30am",
  "12:45am",
  "1:00am",
  "1:15am",
  "1:30am",
  "1:45am",
  "2:00am",
];
export const SPECIAL_TIMES_WITH_BREAKS = ["7:00am", "7:15am"];
export const TAG_FRAUDULENCE_CHECKED = "Fraudulence Checked"
