import React from "react";
import SearchBar from "../../components/Shared/SearchBar";
import { RefreshIcon } from "@heroicons/react/outline";
import DropDown from "../../components/Shared/DropDown";
import { useArcadeCard } from "../../context/ArcadeCard";
import { TArcadeGameCardSortOptions } from "../../interfaces";
import ComboButton from "../../components/Shared/ComboButton";

const Header = () => {
  const { sort, applySorting, applySearch, refresh, applyFilter } =
    useArcadeCard();

  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
      <div className="w-1/3 h-full">
        <SearchBar onChange={applySearch} />
      </div>

      <div className="flex-1"></div>

      <ComboButton
        leftButtonName="Not Archived"
        rightButtonName="Archived"
        onChange={(val) => {
          if (val === "LEFT") {
            applyFilter("NOT_ARCHIVED");
          } else if (val === "RIGHT") {
            applyFilter("ARCHIVED");
          }
        }}
      />

      <DropDown
        items={[
          {
            key: "ASCENDING",
            label: "Ascending",
          },
          {
            key: "DESCENDING",
            label: "Descending",
          },
        ]}
        selected={sort}
        onSelect={(val) => applySorting(val as TArcadeGameCardSortOptions)}
      />

      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={console.log}
      >
        <RefreshIcon className="text-white w-6" onClick={refresh} />
      </div>
    </div>
  );
};

export default Header;
