import SmsLogsContextProvider from "../../context/SmsLogs";
import { PageLayout } from "../../layouts";
import Header from "./Header";
import Table from "./Table";

export const SmsLogs = () => {
  return (
    <SmsLogsContextProvider>
      <PageLayout Header={<Header />}>
        <Table />
      </PageLayout>
    </SmsLogsContextProvider>
  );
};
