import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import TextInput from "./TextInput";
import TextAreaInput from "./TextAreaInput";

interface Props {
  onSubmit: (val: string) => Promise<void>;
  variant?: "small" | "medium";
  defaultValue?: string;
  updatable?: boolean;
  placeholder?: string;
  type?: "text" | "text-area";
  className?: string;
}
const EditTextField: FC<Props> = ({
  defaultValue = "",
  updatable = true,
  variant = "medium",
  onSubmit,
  placeholder = "",
  type = "text",
  className = ""
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isEditMode, setIsEditMode] = useState(false);

  const [saving, setSaving] = useState(false);

  const changed = useMemo(
    () => !!(defaultValue ? defaultValue !== value : value),
    [defaultValue, value]
  );

  const handleSubmit = useCallback(async () => {
    setSaving(true);
    onSubmit(value)
      .then(() => setIsEditMode(false))
      .catch(console.log)
      .finally(() => setSaving(false));
  }, [value, onSubmit]);

  const handleCancelUpdate = useCallback(() => {
    setValue(defaultValue || "");
    setIsEditMode(false);
  }, [defaultValue]);

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <div className={`truncate py-2 flex gap-1 items-center ${className}`}>
      {type === "text" ? (
        <TextInput
          hideLabel
          className={`border border-solid border-1 border-gray-300 rounded-md ${
            variant === "small" ? "text-xs italic font-medium p-1" : "p-2"
          }`}
          placeholder={placeholder}
          defaultValue={value}
          onChange={setValue}
          disabled={saving || !isEditMode || !updatable}
        />
      ) : (
        <TextAreaInput
          hideLabel
          className={`border border-solid border-1 border-gray-300 rounded-md ${
            variant === "small" ? "text-xs italic font-medium p-1" : "p-2"
          }`}
          placeholder={placeholder}
          defaultValue={value}
          onChange={setValue}
          disabled={saving || !isEditMode || !updatable}
        />
      )}
      {changed && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-green-500  hover:bg-green-700 h-full"
          onClick={handleSubmit}
          disabled={saving}
          type="button"
        >
          {saving ? "..." : "Save"}
        </button>
      )}
      {(isEditMode || changed) && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-red-500  hover:bg-red-700 h-full"
          onClick={handleCancelUpdate}
          type="button"
        >
          <XIcon className="text-white w-3" />
        </button>
      )}
      {updatable && !isEditMode && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-gray-800  hover:bg-gray-900 h-full"
          onClick={() => setIsEditMode(true)}
          disabled={saving}
          type="button"
        >
          Edit
        </button>
      )}
    </div>
  );
};

export default EditTextField;
