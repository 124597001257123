import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageLayout } from '../../layouts';
import Header from './Header';
import { TLogType, TObjFromArray } from '../../interfaces';
import Table from './Table';
import { ISessionEditorLog, TLogDoc } from '../../interfaces/types';
import { useFirebase } from '../../components/Firebase';
import { CollectionReference, QuerySnapshot, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { getProductHandle, handleQuerySnapshot } from '../../utilities';
import LoadingSpinner from '../../components/Shared/LoadingSpinner';
import { IProduct } from 'shopify-api-node';

const AppLogs = () => {
  const [searchText, setSearchText] = useState("")
  const [logType, setLogType] = useState<TLogType>(defaultLogType);
  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate)
  const [logs, setLogs] = useState<TObjFromArray<TLogDoc>>()
  const [sessionEditorLogs, setSessionEditorLogs] = useState<TObjFromArray<ISessionEditorLog>>()
  const [fetchingLogs, setFetchingLogs] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const productIdsRef = useRef<any>({})
  const [products, setProducts] = useState<IProduct[]>([])

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const logRef = useMemo(() => collection(db, "logs") as CollectionReference<TLogDoc>,[db]);
  const productRef = useMemo(() => collection(db, "products") as CollectionReference<IProduct>,[db]);

  const getProducts = useCallback(async (date: Date) => {
    const key = date.getTime().toString();
    if(productIdsRef.current[key]){
      return productIdsRef.current[key] as IProduct[];
    }

    const handles = [getProductHandle(date, "MAIN_TRACK"), getProductHandle(date, "MINI_TRACK"), getProductHandle(date, "VR")].filter(val=>val);

    if(handles.length){
      const qs = await getDocs(query(productRef, where("handle", "in", handles)))
      const data = handleQuerySnapshot<IProduct>(qs)
      const products = Object.values(data);
      productIdsRef.current[key] = products;
      return products;
    }

    return []
  },[productRef])
  
  const getSessionEditorLogs = useCallback(async (date: Date) => {
    setIsLoading(true)
    const products = await getProducts(date);
    const productIds = products.map(p=>p.id);
    const qs = await getDocs(query(logRef, orderBy("ts", "desc"), where("type", "==", "SESSION_EDITOR"), where("productId", "in", productIds)))
    const logDocs = handleQuerySnapshot<ISessionEditorLog>(qs as QuerySnapshot<ISessionEditorLog>)
    setSessionEditorLogs(logDocs)
    setIsLoading(false)
  },[logRef, getProducts])

  const fetchLogs = useCallback(async (logType: TLogType, selectedDate: Date)=>{
    setFetchingLogs(true)

    const queryInput = [
      logRef,
      orderBy("ts", "desc"),
      where("type", "==", logType)
    ];

    const products = await getProducts(selectedDate);
    setProducts(products)

    if(logType === "QUANTITY_ADJUSTED"){
      queryInput.push(where("productId", "in", products.map(p=>p.id)))
    }else if(logType === "PRICE_CHANGER"){
      queryInput.push(where("priductId", "in", products.map(p=>p.id)))
    }

    // @ts-ignore
    const qs = await getDocs(query(...queryInput))
    const logDocs = handleQuerySnapshot<TLogDoc>(qs as QuerySnapshot<TLogDoc>)
    setLogs(logDocs)
    setFetchingLogs(false)
    return logDocs;
  },[logRef, getProducts])

  const handleLogTypeChange = useCallback((type: TLogType) => {
    setLogType(type);
    fetchLogs(type,selectedDate)
  },[selectedDate, fetchLogs])

  const handleSelectedDateChange = useCallback((date: Date) => {
    setSelectedDate(date);
    fetchLogs(logType,date)
    getSessionEditorLogs(date)
  }, [logType, fetchLogs, getSessionEditorLogs]);

  const handleSearchChange = useCallback((val: string) => {
    setSearchText(val);
  }, []);

  const refreshLogs = useCallback(() => {
    fetchLogs(logType,selectedDate)
  },[logType, selectedDate, fetchLogs]);

  useEffect(() => {
    if(!logs){
      fetchLogs(defaultLogType, defaultSelectedDate)
    }
    if(!sessionEditorLogs){
      getSessionEditorLogs(defaultSelectedDate)
    }
  }, [logs, fetchLogs, sessionEditorLogs, getSessionEditorLogs])

  return (
    <PageLayout Header={<Header logType={logType} searchText={searchText} handleSearchChange={handleSearchChange} handleLogTypeChange={handleLogTypeChange} handleSelectedDateChange={handleSelectedDateChange} refreshLogs={refreshLogs}/>}>
      {(!logs || fetchingLogs || !sessionEditorLogs || isLoading) ? (
        <LoadingSpinner/>
      ) : (
        <Table logType={logType} logs={logs} products={products} searchText={searchText} sessionEditorLogs={sessionEditorLogs}/>
      )}
    </PageLayout>
  )
}

const defaultLogType: TLogType = "QUANTITY_ADJUSTED"
const defaultSelectedDate: Date = new Date();

export default AppLogs;