import React, { useEffect, useMemo, useState } from "react";
import { useEventContext } from "./useEventsContext";
import { DateTime } from "luxon";
import { useFirebase } from "../../components/Firebase";
import {
  CollectionReference,
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import { TEvent } from "../../interfaces";

const EventTableBody: React.FC<TEvent> = ({
  id,
  eventName,
  eventClosure,
  date,
  isEmail,
  isSMS,
  enableEditing,
  isClosed,
}) => {
  const { createEventsList, dispatch, modalSettings } = useEventContext();
  const [eventDetails, setEventDetails] = useState("")
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const eventDayRef = useMemo(
    () => collection(db, "event_day") as CollectionReference<TEvent>,
    [db]
  );

  const updateEventsList = async (id: string, newData: Partial<TEvent>) => {
    const updatedList = createEventsList.map((event: TEvent) =>
      event.id === id ? { ...event, ...newData } : event
    ) as TEvent[];

    await setDoc(doc(eventDayRef, id), newData, { merge: true });

    dispatch({
      type: "SET_CREATED_EVENTS_LISTS_STATE",
      payload: { createEventsList: updatedList },
    });
  };

  const handleEdit = () => {
    updateEventsList(id, { enableEditing: true });
  };

  const handleSaveChanges = (data={}) => {
    updateEventsList(id, { enableEditing: false, ...data });
    console.log("changes have been saved for id:", id);
  };

  const handleCancelChanges = () => {
    updateEventsList(id, { enableEditing: false });
  };

  const handleCloseOrOpen = (eventStatus: "Close" | "Open") => {
    updateEventsList(id, { isClosed: eventStatus === "Close" });
  };

  const handleIsSMSOrIsEmail = (notificationStatus: "SMS" | "Email") => {
    dispatch({
      type: "SET_IS_MODAL_DISPLAYED",
      payload: {
        modalSettings: {
          displayModal: true,
          displayFor: "Email Or SMS Confirmation",
          confirmationFor: notificationStatus,
          eventId: id,
        },
      },
    });
  };

  const handleViewEventDetails = () => {
    const viewEventDetailsById = createEventsList.find(
      (event: TEvent) => event.id === id
    ) as TEvent;
    dispatch({
      type: "SET_VIEW_EVENT_DETAILS",
      payload: { viewEventDetails: viewEventDetailsById },
    });
    dispatch({
      type: "SET_IS_MODAL_DISPLAYED",
      payload: {
        modalSettings: {
          ...modalSettings,
          displayFor: "View Event Details",
          displayModal: true,
        },
      },
    });
  };

  const formattedDate = date
    ? DateTime.fromMillis(date).toFormat("cccc, dd LLLL yyyy")
    : "-";

  useEffect(() => {
    setEventDetails(eventClosure)
  }, [eventClosure])

  return (
    <tr>
      <td className="p-[15px] capitalize">{eventName}</td>
      <td className="p-[15px]">{formattedDate}</td>
      <td className="p-[15px]">
        <div className="flex items-center gap-[10px]">
          <div>
            <textarea
              className="border-[0.5px] px-[10px]"
              name="event relevent closures"
              id="event-text-area"
              cols={50}
              rows={3}
              value={eventDetails}
              onChange={(e)=>setEventDetails(e.target.value)}
              readOnly={!enableEditing}
            />
            {enableEditing && (
              <div className="flex gap-[10px] bg-[#F3F4F7] rounded-full w-fit">
                <button
                  type="button"
                  className={`capitalize py-[5px] px-[20px] rounded-full font-[500] ${
                    !isClosed ? "bg-[#9D62FE]" : "bg-[#F3F4F7] text-[#777777]"
                  } text-white`}
                  onClick={() => handleCloseOrOpen("Open")}
                >
                  Open
                </button>
                <button
                  type="button"
                  className={`capitalize py-[5px] px-[20px] rounded-full font-[500] ${
                    isClosed ? "bg-[#9D62FE]" : "bg-[#F3F4F7] text-[#777777]"
                  } text-white`}
                  onClick={() => handleCloseOrOpen("Close")}
                >
                  Close
                </button>
              </div>
            )}
          </div>

          {enableEditing ? (
            <div className="flex gap-[10px]">
              <button
                type="button"
                className="capitalize py-[5px] px-[20px] rounded-full bg-[#22c55e] text-white"
                onClick={()=>handleSaveChanges({
                  eventClosure: eventDetails
                })}
              >
                Save
              </button>
              <button
                type="button"
                className="capitalize py-[5px] px-[20px] rounded-full bg-[#b91c1c] text-white"
                onClick={handleCancelChanges}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="capitalize py-[5px] px-[20px] rounded-full bg-[#393B41] text-white"
              onClick={handleEdit}
            >
              edit
            </button>
          )}
        </div>
      </td>
      <td className="p-[15px]">
        <div
          className="cursor-pointer"
          onClick={() => handleIsSMSOrIsEmail("SMS")}
        >
          {isSMS ? (
            <svg
              width="48"
              height="25"
              viewBox="0 0 48 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="25" rx="12.5" fill="#2EBF3C" />
              <circle cx="35.5" cy="12.5" r="10.5" fill="white" />
            </svg>
          ) : (
            <svg
              width="48"
              height="25"
              viewBox="0 0 48 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="25" rx="12.5" fill="#D9D9D9" />
              <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
            </svg>
          )}
        </div>
      </td>
      <td className="p-[15px]">
        <div
          className="cursor-pointer pointer-events-none"
          onClick={() => handleIsSMSOrIsEmail("Email")}
        >
          {isEmail ? (
            <svg
              width="48"
              height="25"
              viewBox="0 0 48 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="25" rx="12.5" fill="#2EBF3C" />
              <circle cx="35.5" cy="12.5" r="10.5" fill="white" />
            </svg>
          ) : (
            <svg
              width="48"
              height="25"
              viewBox="0 0 48 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="25" rx="12.5" fill="#D9D9D9" />
              <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
            </svg>
          )}
        </div>
      </td>
      <td className="p-[15px]">
        <button
          onClick={handleViewEventDetails}
          type="button"
          className="bg-[#9D62FE] capitalize text-white font-[700] text-[14px] py-[6px] px-[20px] rounded-full"
        >
          event details
        </button>
      </td>
    </tr>
  );
};

export default EventTableBody;
