import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "../../components/Shared/Modal";
import QrCode from "qrcode.react";
import { IOrder, IOrderLineItem } from "shopify-api-node";
import ModalSection from "../../components/Modal/ModalSection";
import {
  TDriverIndexResult,
  useDriverRegistration,
} from "../../context/DriverRegistration";
import LoadingSpinner from "../../components/Loading";
import {
  getAgeInYears,
  getDatetimeFromSku,
  getTrackFromSku,
} from "../../utilities";
import { TBookingType } from "../../interfaces";
import {
  IFirebaseBookingDocument,
  IRegistration,
} from "../../interfaces/driverRegistration";
import { DateTime } from "luxon";
import Button from "../../components/Shared/Button";
import { Combobox } from "@headlessui/react";
import AlgoliaSearch from "algoliasearch";
import { AlgoliaIndexes } from "../../constants";
import { EyeIcon, SearchIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { ILineItemWithOrder } from "../../interfaces/Session";

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  order: IOrder;
  view: TBookingType;
  selectedDate: Date;
  handleViewOrderClick: (booking: ILineItemWithOrder) => void;
}

type TIsChanged = "ADDED" | "REMOVED" | boolean;

export default function DriverRegistrationModal(props: ModalProps) {
  const { order, show, handleClose, view, selectedDate, handleViewOrderClick } = props;
  const {bookings, bookingsLoading, driversLoading, drivers, addToSession, addDriverToRace} = useDriverRegistration();
  const qrCodeParent = useRef<HTMLDivElement | null>(null);
  const [added, setAdded] = useState<IRegistration[]>([]);
  const [removed, setRemoved] = useState<IRegistration[]>([]);
  const [qrCodeWidth, setQrCodeWidth] = useState(0);
  const [isAddedingToSession, setIsAddedingToSession] = useState(false);
  const tableRef = useRef<TRegistrationTableHandle>(null);
  const [selectedDrivers, setSelectedDrivers] = useState(0);
  const [newSelectedDriver, setNewSelectedDriver] =
    useState<TDriverIndexResult | null>(null);
  const [addingDriverToRace, setAddingDriverToRace] = useState(false);

  const booking = useMemo(() => {
    const b = bookings?.[order?.id];
    if(b && b.drivers && b.drivers.length){
      //sort drivers
      b.drivers.sort((a,b) => {
        if (drivers?.[a]?.first_name?.toLowerCase() < drivers?.[b]?.first_name?.toLowerCase()) return -1;
        if (drivers?.[a]?.first_name?.toLowerCase() > drivers?.[b]?.first_name?.toLowerCase()) return 1;
        return 0;
      })
    }
    return b
  }, [order, bookings, drivers]);

  const RegistrationQRContent = useMemo(
    () => `https://registration.hyperkarting.com.au/bookings/${order.id}`,
    [order]
  );
  const orderId = useMemo(() => String(order.id), [order]);

  const updateChanges = useCallback(
    (registration: IRegistration, add: boolean) => {
      if (add) {
        setRemoved((oldRemoved) => {
          const exists = oldRemoved.find(
            (data) =>
              data.sku === registration.sku &&
              data.driverId === registration.driverId
          );
          if (exists) {
            return oldRemoved.filter(
              (data) =>
                !(
                  data.sku === registration.sku &&
                  data.driverId === registration.driverId
                )
            );
          } else {
            setAdded((oldState) => [...oldState, registration]);
          }
          return oldRemoved;
        });
      } else {
        setAdded((oldAdded) => {
          const exists = oldAdded.find(
            (data) =>
              data.sku === registration.sku &&
              data.driverId === registration.driverId
          );
          if (exists) {
            return oldAdded.filter(
              (data) =>
                !(
                  data.sku === registration.sku &&
                  data.driverId === registration.driverId
                )
            );
          } else {
            setRemoved((oldState) => [...oldState, registration]);
          }
          return oldAdded;
        });
      }
    },
    []
  );

  const someThingChanged = useMemo(
    () => !!(added.length || removed.length),
    [added, removed]
  );

  const isAnythingChanged = useCallback(
    (firebaseId: string, sku: string) => {
      if (added.find((obj) => obj.sku === sku && obj.driverId === firebaseId)) {
        return "ADDED";
      }
      if (
        removed.find((obj) => obj.sku === sku && obj.driverId === firebaseId)
      ) {
        return "REMOVED";
      }
      return false;
    },
    [added, removed]
  );

  const handleAddToSession = useCallback(async () => {
    try {
      setIsAddedingToSession(true);
      const data = await addToSession({
        added,
        removed,
        orderId: orderId,
      });
      if (data.success) {
        setAdded([]);
        setRemoved([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddedingToSession(false);
    }
  }, [addToSession, added, removed, orderId]);

  const handleSelectAll = useCallback(() => {
    tableRef.current?.handleAddToSession?.();
  }, []);

  const handleAddDriverToBooking = useCallback(() => {
    if (!newSelectedDriver) {
      return;
    }
    setAddingDriverToRace(true);
    addDriverToRace(newSelectedDriver.objectID, String(orderId))
      .then((data) => {
        console.log(data);
        setNewSelectedDriver(null);
      })
      .catch(console.error)
      .finally(() => {
        setAddingDriverToRace(false);
      });
  }, [newSelectedDriver, orderId, addDriverToRace]);

  //TODO for today's races
  const lineItems = useMemo(
    () =>
      order.line_items
        .filter((li) => {
          //It should match the track
          const track = getTrackFromSku(li.sku);
          if (li.sku && track !== view) {
            return false;
          }

          // session should not br refunded
          const isRefunded = (order?.refunds ?? []).find((refund) =>
            (refund?.refund_line_items ?? []).find(
              (rli) =>
                rli.line_item_id === li.id && rli.quantity === li.quantity //If full refunded then ignore it
            )
          );
          if (isRefunded) {
            return false;
          }

          //check date it should be same as selected date
          const dt = getDatetimeFromSku(li.sku, view);
          const dtSelected = DateTime.fromJSDate(selectedDate, {
            zone: "Australia/Sydney",
          });

          return (
            dt?.isValid &&
            dt.toFormat("dd/MM/yyyy") === dtSelected.toFormat("dd/MM/yyyy")
          );
        })
        .sort((prev, next) => {
          const prevDt = getDatetimeFromSku(prev.sku, view);
          const nextDt = getDatetimeFromSku(next.sku, view);
          return prevDt!.toMillis() - nextDt!.toMillis();
        }),
    [order, view, selectedDate]
  );

  const noOfDriversRegistered = useMemo(() => {
    return Array.isArray(booking?.drivers) ? booking.drivers.length : 0;
  }, [booking]);

  const noOfRacesSelected = useMemo(
    () => new Set(added.map((registration) => registration.sku)).size,
    [added]
  );

  useEffect(() => {
    let resizeObserver = new ResizeObserver(() => {
      const width = qrCodeParent.current?.getBoundingClientRect?.()?.width;

      if (qrCodeParent.current && width !== undefined) {
        setQrCodeWidth(width);
      }
    });

    if (qrCodeParent.current) {
      resizeObserver.observe(qrCodeParent.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Modal
      title={
        handleViewOrderClick ? (
          <div className="flex items-center gap-2">
            <p>Registration Details</p>
            <Button
              variant="small"
              type="secondary"
              onClick={() => {
                handleClose();
                handleViewOrderClick({
                  ...order.line_items[0], // This is not correct but here we need only order
                  order,
                });
              }}
            >
              Booking Details
            </Button>
          </div>
        ) : (
          "Registration Details"
        )
      }
      show={show}
      onClose={handleClose}
      className="max-w-[95vw] h-[95vh]"
    >
      <div className="flex justify-between p-5 gap-6 h-[calc(100%-65px)] overflow-hidden">
        <div className="w-[25%] flex flex-col gap-2 overflow-y-auto">
          <ModalSection title="QR Code">
            <div ref={qrCodeParent}>
              {RegistrationQRContent && (
                <>
                  <div className="flex justify-center">
                    <QrCode
                      renderAs="svg"
                      value={RegistrationQRContent}
                      level="H"
                      includeMargin
                      size={qrCodeWidth * 0.7}
                    />
                  </div>
                  <a
                    href={RegistrationQRContent}
                    target="_blank"
                    className="break-words text-gray-800 not-italic font-medium text-xs leading-none"
                    rel="noreferrer"
                  >
                    {RegistrationQRContent}
                  </a>
                </>
              )}
            </div>
          </ModalSection>
          <ModalSection title="Status">
            <div className="text-gray-800 not-italic font-medium text-xs leading-none flex flex-col gap-2">
              <p>{noOfRacesSelected} Races Selected</p>
              <p>{selectedDrivers} Drivers Selected</p>
              <p>{noOfDriversRegistered} Drivers Registered</p>
            </div>
          </ModalSection>
        </div>
        <div className="w-[75%] overflow-auto">
          {bookingsLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <ModalSection
              title="Racers"
              headerContent={
                <div className="ml-auto">
                  <Button                  
                    variant="small"
                    onClick={handleSelectAll}
                    type="primary"
                    className="bg-[#ff00ff]"
                  >
                    Select all time
                  </Button>
                </div>
              }
            >
              <div className="flex gap-4">
                <div className="flex-1">
                  <AddNewDriver
                    selectedDriver={newSelectedDriver}
                    setSelectedDriver={setNewSelectedDriver}
                    handleAddDriverToBooking={handleAddDriverToBooking}
                  />
                </div>
                <Button
                  variant="small"
                  className="ml-auto"
                  disabled={!newSelectedDriver}
                  type="secondary"
                  onClick={handleAddDriverToBooking}
                  loading={addingDriverToRace}
                >
                  Add to Group
                </Button>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                {driversLoading ? (
                  <LoadingSpinner />
                ) : (
                  <RegistrationTable
                    lineItems={lineItems}
                    view={view}
                    booking={booking}
                    orderId={orderId}
                    isAnythingChanged={isAnythingChanged}
                    updateChanges={updateChanges}
                    ref={tableRef}
                    updateSelectedDriverCount={setSelectedDrivers}
                  />
                )}
              </div>
            </ModalSection>
          )}
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <div
          className="cursor-pointer inline-flex justify-center rounded-full border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-600"
          onClick={handleClose}
        >
          Back
        </div>
        <button
          className={`inline-flex justify-center rounded-full border border-transparent  px-4 py-2 text-sm font-medium text-white  ${
            someThingChanged || isAddedingToSession
              ? "cursor-pointer bg-purple-500 hover:bg-purple-600"
              : "bg-purple-300 pointer-events-none"
          }`}
          disabled={!someThingChanged || isAddedingToSession}
          onClick={handleAddToSession}
        >
          {isAddedingToSession ? "..." : "Add to Session"}
        </button>
      </div>
    </Modal>
  );
}

interface IRegistrationTableProps {
  lineItems: IOrderLineItem[];
  view: TBookingType;
  booking: IFirebaseBookingDocument;
  orderId: string;
  isAnythingChanged: (firebaseId: string, sku: string) => TIsChanged;
  updateChanges: (registration: IRegistration, add: boolean) => void;
  updateSelectedDriverCount: (count: number) => void;
}
type TRegistrationTableHandle = {
  isAllTimeAdded: boolean;
  handleAddToSession: () => void;
};

const RegistrationTable = forwardRef<TRegistrationTableHandle,IRegistrationTableProps>(({lineItems,view,booking,orderId,isAnythingChanged,updateChanges,updateSelectedDriverCount,},ref) => {
  const rows = useRef<Array<TDriverRowHandle | null>>([]);

  const [editableFirebaseIds, setEditableFirebaseIds] = useState<string[]>([]);
  const selfCheckedInDriverIds = useMemo(() => (booking?.selfCheckIn || []).map((registration) => registration.driverId), [booking]);

  const handleChangeEditable = useCallback((firebaseId: string, val: boolean) => {
    if (val) {
      setEditableFirebaseIds((oldState) => [...oldState, firebaseId]);
    } else {
      setEditableFirebaseIds((oldState) =>
        oldState.filter((id) => id !== firebaseId)
      );
    }
  },[]);

  const handleAddToSession = useCallback(() => {
    setEditableFirebaseIds(booking.drivers);
    rows.current.forEach((row) => {
      const firebaseId = row?.firebaseId;
      if (firebaseId) {
        row.selectAll();
      }
    });
  }, [booking]);

  const isAllTimeAdded = useMemo(() => {
    let flag = true;
    for (let row of rows.current) {
      if (!row) {
        continue;
      }
      const firebaseId = row?.firebaseId;
      if (firebaseId && editableFirebaseIds.includes(firebaseId)) {
        if (row.skus.length !== lineItems.length) {
          flag = false;
          break;
        }
      }
    }
    return flag;
  }, [editableFirebaseIds, lineItems]);

  const isAllDriversSelected = useMemo(() => booking?.drivers?.length === editableFirebaseIds.length,[editableFirebaseIds, booking]);

  const handleSelectAllDriverChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    if (e.target.checked) {
      setEditableFirebaseIds(booking.drivers);
    } else {
      setEditableFirebaseIds([]);
      rows.current.forEach((row) => {
        if (row) {
          row.unCheckDriverRow();
        }
      });
    }
  },[booking]);

  useImperativeHandle(ref, () => ({
    handleAddToSession,
    isAllTimeAdded,
  }));

  useEffect(() => {
    updateSelectedDriverCount(
      (booking?.drivers || []).reduce(
        (acc, id) => acc + (editableFirebaseIds.includes(id) ? 1 : 0),
        0
      )
    );
  }, [booking, editableFirebaseIds, updateSelectedDriverCount]);

  useEffect(() => {
    setEditableFirebaseIds(selfCheckedInDriverIds)
  }, [selfCheckedInDriverIds])

  if (!booking?.drivers?.length) {
    return <div>No registration found</div>;
  }

  return (
    <table className="min-w-full h-[1px] border-separate border-spacing-y-2">
      <thead>
        <tr>
          <th className="text-gray-800 not-italic font-medium font-[montserrat] text-xs leading-10 flex gap-2 pl-2">
            <input
              type="checkbox"
              className="accent-[#7886FF] w-4"
              value="Editable"
              onChange={handleSelectAllDriverChange}
              checked={isAllDriversSelected}
            />
            Name
          </th>
          {lineItems.map((li) => (
            <th
              key={li.id}
              className="text-gray-800 not-italic font-medium font-[montserrat] text-xs leading-10 text-center"
            >
              <div className="mx-2">
                {getDatetimeFromSku(li.sku, view)?.toFormat("hh:mma")}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {booking.drivers.map((firebaseId, index) => (
          <DriverRow
            firebaseId={firebaseId}
            index={index + 1}
            key={firebaseId}
            lineItems={lineItems}
            orderId={orderId}
            registrations={booking.registrations || []}
            selfCheckIn={booking.selfCheckIn || []}
            editable={editableFirebaseIds.includes(firebaseId)}
            handleChangeEditable={handleChangeEditable}
            isAnythingChanged={isAnythingChanged}
            updateChanges={updateChanges}
            view={view}
            ref={(el: any) => (rows.current[index] = el)}
          />
        ))}
      </tbody>
    </table>
  );
});

interface IDriverRowProps {
  lineItems: IOrderLineItem[];
  firebaseId: string;
  orderId: string;
  index: number;
  registrations: IRegistration[];
  selfCheckIn: IRegistration[];
  editable: boolean;
  view: TBookingType;
  handleChangeEditable: (firebaseId: string, val: boolean) => void;
  isAnythingChanged: (firebaseId: string, sku: string) => TIsChanged;
  updateChanges: (registration: IRegistration, add: boolean) => void;
}
type TDriverRowHandle = {
  firebaseId: string;
  selectAll: () => void;
  skus: string[];
  unCheckDriverRow: () => void;
};
const DriverRow = forwardRef<TDriverRowHandle, IDriverRowProps>(
  (
    {
      firebaseId,
      index,
      lineItems,
      orderId,
      registrations,
      selfCheckIn,
      editable,
      handleChangeEditable,
      isAnythingChanged,
      updateChanges,
      view,
    },
    ref
  ) => {
    const [isDeletingDriver, setIsDeletingDriver] = useState(false);
    const sessionsRef = useRef<Array<HTMLInputElement | null>>([]);

    const { drivers, removeDriverFromRace } = useDriverRegistration();

    const driverData = useMemo(
      () => drivers[firebaseId],
      [drivers, firebaseId]
    );

    const isDriverAddedToAnySession = useMemo(
      () =>
        registrations.find(
          (registration) =>
            registration.orderId === orderId &&
            registration.driverId === firebaseId
        ),
      [registrations, orderId, firebaseId]
    );

    const unCheckDriverRow = useCallback(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);
      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;

        if (sku) {
          const change = isAnythingChanged(firebaseId, sku);
          const existingVal = ele.checked;

          if (
            (change === "ADDED" && existingVal) ||
            (change === "REMOVED" && !existingVal)
          ) {
            ele.checked = !existingVal;
            updateChanges(
              {
                driverId: firebaseId,
                orderId,
                sku,
                raceType: view,
              },
              ele.checked
            );
          }
        }
      });
    }, [firebaseId, view, orderId, isAnythingChanged, updateChanges]);

    const hadleEditableChange: React.ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (e) => {
          handleChangeEditable(firebaseId, e.target.checked);
          if (!e.target.checked) {
            //If unchecking then remove local changes
            unCheckDriverRow();
          }
        },
        [firebaseId, handleChangeEditable, unCheckDriverRow]
      );

    const onChangeCheckbox: (
      lineIten: IOrderLineItem
    ) => React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (li) => (e) => {
        updateChanges(
          {
            driverId: firebaseId,
            orderId: orderId,
            sku: li.sku,
            raceType: view,
          },
          e.target.checked
        );
      },
      [updateChanges, firebaseId, orderId, view]
    );

    const selectAll = useCallback(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);

      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;

        if (sku) {
          const change = isAnythingChanged(firebaseId, sku);
          const existingVal = ele.checked;

          if (!existingVal && change !== "ADDED") {
            ele.checked = true;
            updateChanges(
              {
                driverId: firebaseId,
                orderId,
                sku,
                raceType: view,
              },
              true
            );
          }
        }
      });
    }, [firebaseId, isAnythingChanged, updateChanges, orderId, view]);

    const handleRemoveDriverFromBooking = useCallback(async () => {
      try {
        setIsDeletingDriver(true);
        const data = await removeDriverFromRace(orderId, firebaseId);
        if (data.success) {
          alert("Driver removed successfully.");
        }
      } catch (error) {
        console.log("error", error);
        alert(error);
      } finally {
        setIsDeletingDriver(false);
      }
    }, [firebaseId, orderId, removeDriverFromRace]);

    useEffect(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);

      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;
        const shouldTickCheckbox = selfCheckIn.find(e => e.sku === sku && e.driverId === firebaseId && e.orderId === orderId)

        if (sku && shouldTickCheckbox) {
          ele.checked = true;
          updateChanges(
            {
              driverId: firebaseId,
              orderId,
              sku,
              raceType: view,
            },
            true
          );
        }
      });
    }, [selfCheckIn, firebaseId, orderId, view, updateChanges])
    

    const age = useMemo(() => {
      const dt = DateTime.fromFormat(driverData?.dob ?? "", "yyyy-MM-dd");
      return dt.isValid ? getAgeInYears(dt) + " Year Old" : null;
    }, [driverData]);

    useImperativeHandle(ref, () => ({
      selectAll,
      firebaseId,
      skus: sessionsRef.current
        .map((ele) => ele?.dataset?.value)
        .filter((ele) => ele)
        .map((ele) => String(ele)),
      unCheckDriverRow,
    }));

    if (!driverData) {
      return <tr className="bg-[#F3F4F7]"></tr>;
    }

    return (
      <tr
        className="bg-[#F3F4F7]"
        data-racefacer-uuid={driverData.racefacer_uuid}
      >
        <td className="w-[100%] bg-white p-0">
          <div className="text-gray-700 not-italic font-medium font-[montserrat] text-xs leading-10 bg-[#F3F4F7] rounded-md px-2 mr-2 flex gap-2 capitalize items-center">
            <input
              type="checkbox"
              className="accent-[#7886FF] w-4"
              value="Editable"
              onChange={hadleEditableChange}
              checked={editable}
            />
            <a href={`http://192.168.43.100/en/administration/clients/clients?uuid=${driverData.racefacer_uuid}`} rel="noreferrer" target="_blank" className="underline"><b>#{index}</b> {driverData.first_name} {driverData.last_name}</a>
            {age && (
              <p className="text-xs text-gray-400 flex-1 text-right">{age}</p>
            )}
            {isDeletingDriver ? (
              <div>Deleting...</div>
            ) : !isDriverAddedToAnySession ? (
              <TrashIcon
                className="w-4 ml-auto text-red-400 hover:text-red-600 cursor-pointer"
                onClick={handleRemoveDriverFromBooking}
              />
            ) : (
              <></>
            )}
          </div>
        </td>
        {lineItems.map((li, index) => (
          <td key={li.id} className="text-center bg-white p-0">
            <div
              className={`h-full flex justify-center bg-[#F3F4F7] ${
                index === 0
                  ? "rounded-l-md"
                  : index === lineItems.length - 1
                  ? "rounded-r-md"
                  : ""
              }`}
            >
              <AddedToSessionCheckbox
                value={li.sku}
                onChange={onChangeCheckbox(li)}
                disabled={!editable}
                // ref={sessionsRef.current[index]}
                ref={(el) => (sessionsRef.current[index] = el)}
                defaultChecked={
                  !!registrations.find(
                    (registration) =>
                      registration.driverId === firebaseId &&
                      registration.sku === li.sku &&
                      registration.orderId === orderId
                  )
                }
              />
            </div>
          </td>
        ))}
      </tr>
    );
  }
);

interface IAddedToSessionCheckboxProps {
  defaultChecked: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const AddedToSessionCheckbox = forwardRef<
  HTMLInputElement,
  IAddedToSessionCheckboxProps
>(
  (
    { value, onChange, defaultChecked, disabled }: IAddedToSessionCheckboxProps,
    ref
  ) => {
    return (
      <input
        type="checkbox"
        className="accent-[#7886FF] w-4"
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        disabled={disabled}
        ref={ref}
        data-value={value}
      />
    );
  }
);

const AddNewDriver = ({
  setSelectedDriver,
  selectedDriver,
  handleAddDriverToBooking,
}: {
  selectedDriver: TDriverIndexResult | null;
  setSelectedDriver: Dispatch<SetStateAction<TDriverIndexResult | null>>;
  handleAddDriverToBooking: () => void;
}) => {
  const [drivers, setDrivers] = useState<TDriverIndexResult[]>([]);
  const [loading, setLoading] = useState(false);
  const debounceSearchTimeoutRef = useRef<ReturnType<
    typeof setInterval
  > | null>(null);

  const client = AlgoliaSearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
  );
  const index = client.initIndex(AlgoliaIndexes.Drivers);

  const searchDriver: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setLoading(true);
      setSelectedDriver(null);
      const q = event.target.value;
      if (debounceSearchTimeoutRef.current) {
        clearTimeout(debounceSearchTimeoutRef.current);
        debounceSearchTimeoutRef.current = null;
      }
      debounceSearchTimeoutRef.current = setTimeout(() => {
        index
          .search(q)
          .then(({ hits }) => {
            setDrivers(hits as TDriverIndexResult[]);
          })
          .catch(console.error)
          .finally(() => {
            setLoading(false);
          });
      }, 300);
    },
    [index, setSelectedDriver]
  );

  const handleClearSelectedDriver = useCallback(() => {
    setSelectedDriver(null);
  }, [setSelectedDriver]);

  //When enter key is pressed and driver is selected then add driver to session
  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13 && selectedDriver) {
        handleAddDriverToBooking();
      }
    },
    [selectedDriver, handleAddDriverToBooking]
  );

  return (
    <Combobox value={selectedDriver} onChange={setSelectedDriver}>
      <div className="relative w-full flex items-center">
        <Combobox.Input
          onChange={searchDriver}
          className="block w-full pl-2 text-gray-900 border border-solid border-gray-300 rounded-full bg-gray-100 font-normal text-sm px-2.5 py-1"
          placeholder="Search..."
          displayValue={(driver: TDriverIndexResult) =>
            driver
              ? `${driver?.first_name} ${driver?.last_name} (${driver.email}, ${driver.phone_number})`
              : ""
          }
          onKeyDown={handleKeyDown}
        />
        {!!selectedDriver ? (
          <div
            className="absolute right-2.5 cursor-pointer text-[#200E32]"
            onClick={handleClearSelectedDriver}
          >
            <XIcon className="w-6" />
          </div>
        ) : (
          <div className="absolute right-2.5 text-[#200E32] pointer-events-none">
            <SearchIcon className="w-6" />
          </div>
        )}
      </div>
      <Combobox.Options className="bg-gray-100 rounded-lg p-2 max-h-[30vh] overflow-auto">
        {loading && <LoadingSpinner />}
        {drivers.map((driver) => (
          <div className="flex items-center gap-1">
            <a href={`http://192.168.43.100/en/administration/clients/clients?uuid=${driver.racefacer_uuid}`} rel="noreferrer" target="_blank" className="underline"><EyeIcon className="w-5 text-gray-600"/></a>

            <Combobox.Option
              key={driver.objectID}
              value={driver}
              className="text-sm py-1 cursor-pointer hover:bg-gray-300"
            >
              {({ active }) => (
                <div
                  className={`flex gap-2 justify-between ${
                    active ? "bg-gray-300" : ""
                  }`}
                >
                  <span>
                    {driver.first_name} {driver.last_name}
                  </span>
                  <span className="text-xs text-gray-400">
                    {driver.email} ({driver.phone_number})
                  </span>
                </div>
              )}
            </Combobox.Option>
          </div>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};
