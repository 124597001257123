import React, { useCallback, useState } from "react";
import Row from "./Row";
import { useArcadeCard } from "../../context/ArcadeCard";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import BookingDetailsModal from "../dashboard/BookingDetailsModal";
import { IOrder } from "shopify-api-node";

const Table = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>();
  const {
    isOrderLoading,
    arcadeCardOrders,
    loadingCardNumber,
    onNextPageBtnClick,
    onPrevPageBtnClick,
    pageNumber,
    paginatedOrders,
    totalPages,
    withPagination,
  } = useArcadeCard();

  const handleCloseModal = useCallback(() => {
    setSelectedOrder(undefined);
    setShowModal(false);
  }, []);

  const handleViewOrderClick = useCallback((order: IOrder) => {
    setSelectedOrder(order);
    setShowModal(true);
  }, []);

  if (isOrderLoading || loadingCardNumber) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {withPagination && (
        <div className="flex py-2 justify-end gap-4 mx-7 items-center">
          <p className="text-xs font-medium">Page : {pageNumber}</p>
          {pageNumber > 1 && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-gray-500  hover:bg-gray-700"
              onClick={onPrevPageBtnClick}
            >
              Prev
            </button>
          )}
          {pageNumber < totalPages && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-gray-500  hover:bg-gray-700"
              onClick={onNextPageBtnClick}
            >
              Next
            </button>
          )}
        </div>
      )}
      <div
        className={`dashboard-session-table-wrapper  ${
          withPagination
            ? "h-[calc(100%_-_4.5rem)] mx-7"
            : "h-[calc(100%_-_3.5rem)] m-7"
        } overflow-auto`}
      >
        <table className="min-w-full h-[1px] ">
          <thead>
            <tr>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                BOOKING NAME
              </th>

              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                PRICE
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              ></th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                QTY
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                CARD NUMBERS
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                ORDER DATES
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                ORDER ID
              </th>
              <th
                className="min-w-[60px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {!arcadeCardOrders.length && (
              <tr>
                <td colSpan={8} className="text-center p-2 text-gray-400">
                  No Records found
                </td>
              </tr>
            )}
            {paginatedOrders.map((order, index) => (
              <Row
                order={order}
                key={order.id}
                handleViewOrderClick={handleViewOrderClick}
              />
            ))}
          </tbody>
        </table>

        {showModal && !!selectedOrder && (
          <BookingDetailsModal
            modal="ARCADE_CARDS"
            show={showModal}
            order={selectedOrder}
            handleClose={handleCloseModal}
            handleViewRegistrationModalOpen={undefined}
          />
        )}
      </div>
    </>
  );
};

export default Table;
