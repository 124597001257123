import { FC, useCallback, useState } from "react";
import { DateTime } from "luxon";
import { IIssue } from "../../interfaces/Issue";
import EditTextField from "../../components/Shared/EditTextField";
import { useIssue } from "../../context/Issue";

interface Type {
  id: string;
  issue: IIssue;
}
const Row: FC<Type> = ({ id, issue }) => {
  const [closingIssue, setClosingIssue] = useState(false);
  const { statusFilter, updateField, viewIssueBtnClick, closeIssue } =
    useIssue();

  const handleCloseIssue = useCallback(() => {
    setClosingIssue(true);
    closeIssue(id).finally(() => setClosingIssue(false));
  }, [closeIssue, id]);

  return (
    <tr>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{issue.tower}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div>{issue?.headsets || "--"}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div>{issue?.vests || "--"}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div>{issue?.controllers?.join(",") || "--"}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div>{issue.staff_member_name}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex flex-col justify-center flex-shrink-0 h-full">
          <p className="text-[#777]">
            {DateTime.fromMillis(issue.timestamp as number).toFormat("hh:mm a")}
          </p>
          <p className="text-[#393B41]">
            {DateTime.fromMillis(issue.timestamp as number).toFormat(
              "dd LLL yyyy"
            )}
          </p>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full truncate hover:text-gray-600">
          <EditTextField
            onSubmit={updateField(id, "discription")}
            defaultValue={issue?.discription}
            placeholder="Enter Discription Here"
            updatable={statusFilter === "OPEN"}
            type="text-area"
          />
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full truncate hover:text-gray-600">
          <EditTextField
            onSubmit={updateField(id, "troubleshootingAttempt")}
            defaultValue={issue?.troubleshootingAttempt}
            placeholder="Enter Troubleshooting"
            updatable={statusFilter === "OPEN"}
            type="text-area"
          />
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div className="flex gap-2">
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
              onClick={() => viewIssueBtnClick(id)}
            >
              View
            </button>
            {statusFilter === "OPEN" && (
              <button
                className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-green-500  hover:bg-green-700 rounded-full"
                onClick={handleCloseIssue}
              >
                {closingIssue ? "Closing" : "Resolve"}
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
