import { useMemo } from "react";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { useIssue } from "../../context/Issue";
import Row from "./Row";

const Table = () => {
  const {
    loading,
    issues,
    searchText,
    pageNumber,
    totalPages,
    onNextPageBtnClick,
    onPrevPageBtnClick,
  } = useIssue();

  const withPagination = useMemo(() => !searchText, [searchText]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {withPagination && (
        <div className="flex py-2 justify-end gap-4 mx-7 items-center">
          <p className="text-xs font-medium">
            Page : {pageNumber} / {totalPages}
          </p>
          {pageNumber > 1 && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-gray-500  hover:bg-gray-700"
              onClick={onPrevPageBtnClick}
            >
              Prev
            </button>
          )}
          {pageNumber < totalPages && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-gray-500  hover:bg-gray-700"
              onClick={onNextPageBtnClick}
            >
              Next
            </button>
          )}
        </div>
      )}
      <div
        className={`dashboard-session-table-wrapper overflow-auto
        ${
          withPagination
            ? "h-[calc(100%_-_4.5rem)] mx-7"
            : "h-[calc(100%_-_3.5rem)] m-7"
        }
        `}
      >
        <table className="min-w-full h-[1px] ">
          <thead>
            <tr>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                TOWER (A/B)
              </th>

              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                HEADSET(S)
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                VEST
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                CONTROLLERS
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                STAFF
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                TIMESTAMP
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                DESCRIPTION
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              >
                TROUBLESHOOTING ATTEMPTED
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
                scope="col"
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {!Object.keys(issues).length && (
              <tr>
                <td colSpan={9} className="text-center p-2 text-gray-400">
                  No Records found
                </td>
              </tr>
            )}
            {Object.keys(issues).map((id) => (
              <Row id={id} issue={issues[id]} key={id} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
