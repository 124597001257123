import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ISMSLogDocument } from "../interfaces/Logs";
import { useFirebase } from "../components/Firebase";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  WhereFilterOp,
  FieldPath,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { TObjFromArray } from "../interfaces";
import { createBatches, handleQuerySnapshot } from "../utilities";
import AlgoliaSearch from "algoliasearch";
import app from "firebase/compat/app";
import { AlgoliaIndexes } from "../constants";

interface ISmsLogsContextType {
  logs: ISMSLogDocument[];
  loading: boolean;
  searchText: string;
  changeSelectedDate: (date: Date) => void;
  refresh: () => void;
  applySearch: (val: string) => void;
}

const SmsLogsContext = createContext<ISmsLogsContextType>(
  undefined as unknown as ISmsLogsContextType
);

const SmsLogsContextProviderComponent: FC<
  PropsWithChildren<{ value: ISmsLogsContextType }>
> = ({ value, children }) => {
  return (
    <SmsLogsContext.Provider value={value}>{children}</SmsLogsContext.Provider>
  );
};

export const useSmsLogs = () => {
  return useContext<ISmsLogsContextType>(SmsLogsContext);
};

interface Type {}
const SmsLogsContextProvider: FC<PropsWithChildren<Type>> = ({ children }) => {
  const client = AlgoliaSearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
  );
  const index = client.initIndex(AlgoliaIndexes.SmsLogs);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<TObjFromArray<ISMSLogDocument>>({});
  const firebase = useFirebase();
  const [searchText, setSearchText] = useState("");
  const debounceSearchTimeoutRef = useRef<ReturnType<
    typeof setInterval
  > | null>(null);

  const db = useMemo(() => firebase.db, [firebase]);
  const smsLogRef = useMemo(() => collection(db, "sms_logs"), [db]);

  const logDocs: ISMSLogDocument[] = useMemo(
    () => Object.values(JSON.parse(JSON.stringify(logs))),
    [logs]
  );

  const loadDateWiseData = useCallback(
    (date: Date) => {
      return new Promise<TObjFromArray<ISMSLogDocument>>((resolve) => {
        const startTs = DateTime.fromJSDate(date).startOf("day").toMillis();
        const endTs = DateTime.fromJSDate(date).endOf("day").toMillis();
        setLoading(true);
        getDocs(
          query(
            smsLogRef,
            where("ts", ">=", startTs),
            where("ts", "<=", endTs),
            orderBy("ts", "desc")
          )
        )
          .then((querySnapshot) => {
            resolve(handleQuerySnapshot<ISMSLogDocument>(querySnapshot));
          })
          .catch(console.log)
          .finally(() => {
            setLoading(false);
            resolve({});
          });
      });
    },
    [smsLogRef]
  );

  const loadFilteredData = useCallback(
    (field: string | FieldPath, filter: WhereFilterOp, value: unknown) => {
      return new Promise<TObjFromArray<ISMSLogDocument>>((resolve) => {
        setLoading(true);
        getDocs(query(smsLogRef, where(field, filter, value)))
          .then((querySnapshot) => {
            resolve(handleQuerySnapshot<ISMSLogDocument>(querySnapshot));
          })
          .catch(console.log)
          .finally(() => {
            setLoading(false);
            return resolve({});
          });
      });
    },
    [smsLogRef]
  );

  const changeSelectedDate = useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const refresh = useCallback(() => {
    loadDateWiseData(selectedDate).then(setLogs);
  }, [loadDateWiseData, selectedDate]);

  const applySearch = useCallback(
    (val: string) => {
      setLoading(true);
      if (debounceSearchTimeoutRef.current) {
        clearTimeout(debounceSearchTimeoutRef.current);
        debounceSearchTimeoutRef.current = null;
      }
      debounceSearchTimeoutRef.current = setTimeout(() => {
        if (val) {
          index.search(val).then(({ hits }) => {
            if (hits?.length) {
              Promise.allSettled(
                createBatches(hits, 10).map((chunkHits) =>
                  loadFilteredData(
                    app.firestore.FieldPath.documentId(),
                    "in",
                    chunkHits.map((hit) => hit.objectID)
                  )
                )
              ).then((results) => {
                let data = {};
                results.forEach((result) => {
                  if (result.status === "fulfilled") {
                    data = { ...data, ...result.value };
                  }
                });
                setLogs(data);
              });
            } else {
              setLogs({});
              setLoading(false);
            }
          });
        } else {
          // If not searched by text
          loadDateWiseData(selectedDate).then(setLogs);
        }
        setSearchText(val);
      }, 300);
    },
    [selectedDate, index, loadDateWiseData, loadFilteredData]
  );

  useEffect(() => {
    loadDateWiseData(selectedDate).then(setLogs);
  }, [loadDateWiseData, selectedDate]);

  return (
    <SmsLogsContextProviderComponent
      value={{
        logs: logDocs,
        loading,
        searchText,
        changeSelectedDate,
        refresh,
        applySearch,
      }}
    >
      {children}
    </SmsLogsContextProviderComponent>
  );
};

export default SmsLogsContextProvider;
