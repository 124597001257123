import { ReactElement, cloneElement } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  Wrapper: ReactElement;
  children: ReactElement | ReactElement[];
}

const ConditionalWrapper = ({ 
  condition, 
  Wrapper, 
  children 
}: ConditionalWrapperProps): ReactElement => {
  return condition ? cloneElement(Wrapper, { children }) : children as ReactElement;
};

export default ConditionalWrapper;