import React, { useCallback } from "react";
import Calender from "../../components/Shared/Calender";
import DropDown from "../../components/Shared/DropDown";
import SearchBar from "../../components/Shared/SearchBar";
import { RefreshIcon } from "@heroicons/react/solid";
import { useBookingSession } from "../../context/BookingSession";
import Button from "../../components/Shared/Button";
import { usePricechanger } from "../../context/PriceChanger";
import { useModal } from "../../context/Modal";
import { getTrackDisplayName } from "../../utilities";

interface Props {
  changeView: (val: string) => void;
  changeSelectedDate: (val: Date) => void;
}
function Header({ changeView, changeSelectedDate }: Props) {
  const { setModalData, setshowModal } = useModal();
  const { refreshSessions, applySearchFilter, track, product } = useBookingSession();
  const { isUpdating, isUpdateButtonActive } = usePricechanger();

  const onUpdateBtnClick = useCallback(() => {
    if(product?.id){
      setModalData({
        id: "PRICE_CHANGER_CONFIRMATION",
        onClose: () => setshowModal(false),
        title: "Updating Sessions",
        content: (
          <ConformationModalContent 
            onCancel={() => setshowModal(false)} 
            onUpdate={()=>{
              if(product?.id){
                setshowModal(false)
              }
            }} 
          />
        ),
      });
      setshowModal(true);
    }
  },[product, setModalData, setshowModal])

  const handleDateChange = useCallback((val: Date)=>{
    changeSelectedDate(val)
  },[changeSelectedDate])
  
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
      <div className="w-1/3 h-full">
        <SearchBar onChange={applySearchFilter} />
      </div>

      <Calender onDateChange={handleDateChange} />

      <div className="flex-1"></div>

      <Button disabled={isUpdating || !isUpdateButtonActive} loading={isUpdating} onClick={onUpdateBtnClick} className={`@apply bg-[linear-gradient(282.87deg,#d32efe_14.09%,#05fffa_103.26%)] text-white ${isUpdateButtonActive ? "" : "opacity-50"}`} withCustomColor>
        Update
      </Button>

      <DropDown
        items={[
          {
            key: "MAIN_TRACK",
            label: getTrackDisplayName("MAIN_TRACK"),
          },
          {
            key: "MINI_TRACK",
            label: getTrackDisplayName("MINI_TRACK"),
          },
          {
            key: "VR",
            label: getTrackDisplayName("VR"),
          },
          {
            key: "JUNIOR_TRACK",
            label: getTrackDisplayName("JUNIOR_TRACK"),
          },
          {
            key: "INTERMEDIATE_TRACK",
            label: getTrackDisplayName("INTERMEDIATE_TRACK"),
          },
        ]}
        selected={track}
        onSelect={changeView}
      />

      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={refreshSessions}
      >
        <RefreshIcon className="text-white w-6" />
      </div>
    </div>
  );
}

const ConformationModalContent = ({ onCancel, onUpdate }: { onCancel: ()=> void, onUpdate: ()=> void })=>{

  const { refreshSessions, product } = useBookingSession();
  const { updateItems, isUpdating } = usePricechanger();

  const onUpdatePriceButtonClick = useCallback(() => {
    if(product?.id){
      updateItems(product.id, () => {
        refreshSessions();
        onUpdate();
      })
    }
  },[product, refreshSessions, updateItems, onUpdate])
  

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">
        {isUpdating ? "Sessions are currently being updated. Please wait…" :"Are you sure want to update the sessions?"}
      </p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">

        <Button onClick={onCancel} className="bg-red-500">
          Cancel
        </Button>
        
        <Button onClick={onUpdatePriceButtonClick} loading={isUpdating} disabled={isUpdating}>
          Update
        </Button>
      </div>
    </div>
  )
}

export default Header;
