import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  ILineItemWithOrder,
  ISessionWithBookings,
} from "../../interfaces/Session";
import { TBookingType, TTransactionStatus } from "../../interfaces";
import { useModal } from "../../context/Modal";
import { useSession } from "../../components/Session";

const RaceReadyMsg = (props: {
  session: ISessionWithBookings;
  view: TBookingType;
  rrmLog: any;
  filteredBookings: ILineItemWithOrder[];
}) => {
  const { session, view, rrmLog, filteredBookings } = props;
  const { setModalData, setshowModal } = useModal();

  const rrmStatus: TTransactionStatus | undefined = useMemo(
    () => rrmLog?.status,
    [rrmLog]
  );

  const showConfirmationPopup = useCallback(() => {
    setModalData({
      id: "RACE_READY_MSG_CONFIRMATION",
      onClose: () => setshowModal(false),
      title: "Send Race Ready Message",
      content: (
        <ConformationModalContent
          onCancel={() => setshowModal(false)}
          session={session}
          view={view}
        />
      ),
    });
    setshowModal(true);
  }, [setModalData, setshowModal, session, view]);

  const buttonLabel = useMemo(() => {
    switch (rrmStatus) {
      case "IN_PROGRESS":
        return "Sending";

      case "COMPLETED":
        return "Sent";

      case "FAILED":
      default:
        return "Send SMS";
    }
  }, [rrmStatus]);

  const isSendBtnDisable = useMemo(
    () => rrmStatus && rrmStatus !== "FAILED",
    [rrmStatus]
  );

  useEffect(() => {
    setshowModal(false);
  }, [isSendBtnDisable, setshowModal]);

  return (
    <>
      {!!filteredBookings.length && (
        <button
          onClick={showConfirmationPopup}
          className={`items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full ${
            isSendBtnDisable
              ? "bg-gray-500  hover:bg-gray-700"
              : "bg-rose-500  hover:bg-rose-700"
          }`}
          disabled={isSendBtnDisable}
        >
          {buttonLabel}
        </button>
      )}
    </>
  );
};

function ConformationModalContent({
  onCancel,
  session,
  view,
}: {
  onCancel: () => void;
  session: ISessionWithBookings;
  view: TBookingType;
}) {
  const [loading, setLoading] = useState(false);
  const { getToken } = useSession();

  const sendSms = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getToken();

      if (!token) {
        alert("User not loggeedin");
      }

      onCancel();
      alert(`Race Ready SMS for the ${view} ${session.option1} ${session.option2} ${session.option3} has been triggered`)

      const { data } = await axios.post(
        // "http://localhost:5001/hyper-karting/us-central1/sendRaceReadyMessage",
        "https://us-central1-hyper-karting.cloudfunctions.net/sendRaceReadyMessage",
        {
          sku: session.sku,
          type: view,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      data.message && alert(data.message);
    } catch (error) {
      console.log("Error in send sms", error);
      alert(String(error));
    } finally {
      setLoading(false);
    }
  }, [session, view, getToken, onCancel]);

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">Are you sure want to message ?</p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-red-500  hover:bg-red-700 rounded-full"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-green-500  hover:bg-green-700 rounded-full"
          onClick={sendSms}
          disabled={loading}
        >
          {loading ? "Sending..." : "Confirm"}
        </button>
      </div>
    </div>
  );
}

export default RaceReadyMsg;
