import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TIssueStatus,
  TObjFromArray,
  EVrIssueSortOptions,
} from "../interfaces";
import { IIssue } from "../interfaces/Issue";
import { useFirebase } from "../components/Firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  OrderByDirection,
  QueryDocumentSnapshot,
  limit,
  CollectionReference,
  QuerySnapshot,
  startAfter,
} from "firebase/firestore";
import { deepCopy, handleQuerySnapshot } from "../utilities";
import { DecodedIdToken, IVrIssueLog } from "../interfaces/types";
import { useSession } from "../components/Session";

export enum EIssueModalTypes {
  CREATE_ISSUE = "create-issue",
  VIEW_ISSUE = "view-issue",
}

type TModalData =
  | {
      id: EIssueModalTypes.CREATE_ISSUE;
      title: string;
    }
  | {
      id: EIssueModalTypes.VIEW_ISSUE;
      title: string;
      issue: IIssue;
    };

interface IIssueContextType {
  loading: boolean;
  issues: TObjFromArray<IIssue>;
  statusFilter: TIssueStatus;
  sort: EVrIssueSortOptions;
  showModal: boolean;
  commonModalData: TModalData;
  searchText: string;
  pageNumber: number;
  totalPages: number;
  applySearchFilter: (val: string) => void;
  applyStatusFilter: (val: TIssueStatus) => void;
  applySort: (val: EVrIssueSortOptions) => void;
  onCloseModal: (type: EIssueModalTypes) => void;
  createIssueBtnClick: () => void;
  viewIssueBtnClick: (id: string) => void;
  createIssue: (data: IIssue) => Promise<string>;
  updateField: <K extends keyof IIssue>(
    id: string,
    key: K
  ) => (value: IIssue[K]) => Promise<void>;
  closeIssue: (id: string) => Promise<void>;
  refresh: () => void;
  onPrevPageBtnClick: () => void;
  onNextPageBtnClick: () => void;
}

const IssueContext = createContext<IIssueContextType>(
  undefined as unknown as IIssueContextType
);

const IssueContextProviderComponent: FC<
  PropsWithChildren<{ value: IIssueContextType }>
> = ({ value, children }) => {
  return (
    <IssueContext.Provider value={value}>{children}</IssueContext.Provider>
  );
};

export const useIssue = () => {
  return useContext<IIssueContextType>(IssueContext);
};

interface Type {}

const pageSize = 250;
const IssueContextProvider: FC<PropsWithChildren<Type>> = ({ children }) => {
  const isMounted = useRef<boolean>();

  const [sort, setSort] = useState<EVrIssueSortOptions>(
    EVrIssueSortOptions.DESCENDING
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [startAfterDoc, setStartAfterDoc] = useState<
    QueryDocumentSnapshot<IIssue> | undefined
  >();

  const [loading, setLoading] = useState(false);
  const [allIssues, setAllIssues] = useState<TObjFromArray<IIssue>>({});
  const [filteredIssues, setFilteredIssues] = useState<TObjFromArray<IIssue>>(
    {}
  );
  const [statusFilter, setStatusFilter] = useState<TIssueStatus>("OPEN");
  const [showModal, setShowModal] = useState(false);
  const [commonModalData, setCommonModalData] = useState<TModalData>({
    id: EIssueModalTypes.CREATE_ISSUE,
    title: "",
  });

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const issueRef = useMemo(
    () => collection(db, "issues") as CollectionReference<IIssue>,
    [db]
  );
  const logRef = useMemo(
    () => collection(db, "logs") as CollectionReference<IVrIssueLog>,
    [db]
  );
  const { auth } = useSession();

  const [searchText, setSearchText] = useState("");

  const totalPages = useMemo(
    () => Math.ceil(totalRecords / pageSize),
    [totalRecords]
  );

  const fetchIssues: (data?: {
    searchText?: string;
    startAfterDoc?: QueryDocumentSnapshot<IIssue>;
    direction?: OrderByDirection;
    status?: TIssueStatus;
  }) => Promise<TObjFromArray<IIssue>> = useCallback(
    async (data = {}) => {
      try {
        setLoading(true);
        const after = Object.keys(data || {}).includes("startAfterDoc")
          ? data?.startAfterDoc
          : startAfterDoc;

        const searchDirection: OrderByDirection = data?.direction
          ? data?.direction
          : sort === "ASCENDING"
          ? "asc"
          : "desc";

        const quertyInput = [
          issueRef,
          where("status", "==", data?.status || statusFilter),
          orderBy("timestamp", searchDirection),
          limit(pageSize),
        ];

        if (after) {
          quertyInput.splice(quertyInput.length - 1, 0, startAfter(after));
        } else {
          const noOfRecords = // @ts-ignore
            (await getDocs(query(...quertyInput.slice(0, -1)))).size;
          setTotalRecords(noOfRecords);
        }

        const qs = (await getDocs(
          // @ts-ignore
          query(...quertyInput)
        )) as QuerySnapshot<IIssue>;

        const docs = qs.docs;
        const lastDocArr = docs.slice(-1);
        setStartAfterDoc(lastDocArr.length ? lastDocArr[0] : undefined);
        return handleQuerySnapshot<IIssue>(qs);
      } catch (error) {
        console.error(error);
        return {};
      } finally {
        setLoading(false);
      }
    },
    [issueRef, sort, startAfterDoc, statusFilter]
  );

  const applySearchFilter = useCallback((val: string) => {
    setSearchText(val);
  }, []);

  const applyStatusFilter = useCallback(
    (val: TIssueStatus) => {
      setStatusFilter(val);
      fetchIssues({
        status: val,
        startAfterDoc: undefined,
      }).then(setAllIssues);
    },
    [fetchIssues]
  );

  const applySort = useCallback(
    (val: EVrIssueSortOptions) => {
      setSort(val);
      fetchIssues({
        direction: val === EVrIssueSortOptions.ASCENDING ? "asc" : "desc",
        startAfterDoc: undefined,
      }).then(setAllIssues);
    },
    [fetchIssues]
  );

  const onNextPageBtnClick = useCallback(async () => {
    const newPageNumber = pageNumber + 1;
    setPageNumber(newPageNumber);
    if (Object.keys(allIssues).length < newPageNumber * pageSize) {
      fetchIssues().then((newData) =>
        setAllIssues((oldData) => ({ ...oldData, ...newData }))
      );
    }
  }, [pageNumber, allIssues, fetchIssues]);

  const onPrevPageBtnClick = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const onCloseModal = useCallback((type: EIssueModalTypes) => {
    setShowModal(false);
  }, []);

  const createIssueBtnClick = useCallback(() => {
    setCommonModalData({
      id: EIssueModalTypes.CREATE_ISSUE,
      title: "Create New Issue",
    });
    setShowModal(true);
  }, []);

  const viewIssueBtnClick = useCallback(
    (id: string) => {
      setCommonModalData({
        id: EIssueModalTypes.VIEW_ISSUE,
        title: "View Issue",
        issue: allIssues[id],
      });
      setShowModal(true);
    },
    [allIssues]
  );

  const createIssue = useCallback(
    async (data: IIssue) => {
      const snapshot = await addDoc(issueRef, data);

      const logPayload: IVrIssueLog = {
        type: "VR_ISSUE",
        id: snapshot.id,
        user: {
          email: auth?.email,
          uid: auth?.uid,
          name: auth?.displayName,
        } as DecodedIdToken,
        ts: Date.now(),
        uid: auth?.uid ?? "",
        action: "CREATED",
        issue: data,
      };
      await addDoc(logRef, logPayload);
      return snapshot.id;
    },
    [issueRef, logRef, auth]
  );

  const updateField = useCallback(
    <K extends keyof IIssue>(id: string, key: K, doNotTrack?: boolean) =>
      async (value: IIssue[K]) => {
        await updateDoc(doc(issueRef, id), {
          [key]: value,
        });
        setAllIssues((oldState) => {
          const newState = deepCopy(oldState);
          newState[id][key] = value;
          if (!doNotTrack) {
            const logPayload: IVrIssueLog = {
              type: "VR_ISSUE",
              id,
              user: {
                email: auth?.email,
                uid: auth?.uid,
                name: auth?.displayName,
              } as DecodedIdToken,
              ts: Date.now(),
              uid: auth?.uid ?? "",
              action: "UPDATED",
              issue: newState[id],
            };
            addDoc(logRef, logPayload);
          }
          return newState;
        });
      },
    [issueRef, logRef, auth]
  );

  const closeIssue = useCallback(
    async (id: string) => {
      await updateField(id, "status", true)("CLOSED");
      const logPayload: IVrIssueLog = {
        type: "VR_ISSUE",
        id,
        user: {
          email: auth?.email,
          uid: auth?.uid,
          name: auth?.displayName,
        } as DecodedIdToken,
        ts: Date.now(),
        uid: auth?.uid ?? "",
        action: "CLOSED",
        issue: allIssues[id],
      };
      await addDoc(logRef, logPayload);
    },
    [allIssues, updateField, auth, logRef]
  );

  const refresh = useCallback(() => {
    setSort(EVrIssueSortOptions.DESCENDING);
    setStatusFilter("OPEN");
    setPageNumber(1);
    setSearchText("");
    fetchIssues({
      startAfterDoc: undefined,
    }).then(setAllIssues);
  }, [fetchIssues]);

  useEffect(() => {
    setFilteredIssues(
      Object.keys(allIssues)
        .filter((id, index) => {
          const {
            staff_member_name,
            discription = "",
            note = "",
            troubleshootingAttempt = "",
            status,
          } = allIssues[id];

          if (
            searchText &&
            !`${staff_member_name} ${discription} ${note} ${troubleshootingAttempt}`
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            return false;
          }

          if (
            !searchText &&
            (pageNumber * pageSize <= index ||
              (pageNumber - 1) * pageSize > index)
          ) {
            return false;
          }

          //when we close any issue still it displays there so to handle that
          if (status !== statusFilter) {
            return false;
          }

          return true;
        })
        .reduce((acc, id) => ({ ...acc, [id]: allIssues[id] }), {})
    );
  }, [searchText, pageNumber, statusFilter, allIssues]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      fetchIssues().then(setAllIssues);
    }
  }, [fetchIssues]);

  return (
    <IssueContextProviderComponent
      value={{
        loading,
        issues: filteredIssues,
        statusFilter,
        showModal,
        commonModalData,
        sort,
        searchText,
        pageNumber,
        totalPages,
        applySearchFilter,
        applyStatusFilter,
        onCloseModal,
        createIssueBtnClick,
        viewIssueBtnClick,
        createIssue,
        updateField,
        closeIssue,
        refresh,
        applySort,
        onNextPageBtnClick,
        onPrevPageBtnClick,
      }}
    >
      {children}
    </IssueContextProviderComponent>
  );
};

export default IssueContextProvider;
