import { useCallback, useState } from "react";

export type TDashboardView = "Open" | "Closed"
export enum EEventsSortOptions {
  "ASCENDING" = "ASCENDING",
  "DESCENDING" = "DESCENDING",
}
function useEventDay() {
  const [dashboardView, setDashboardView] = useState<TDashboardView>("Open");
  const [sort, setSort] = useState<EEventsSortOptions>(
    EEventsSortOptions.DESCENDING
  );

  const changeDashboardView = useCallback((val: TDashboardView) => {
    setDashboardView(val);
  }, []);

  const applySort = useCallback(
    (val: EEventsSortOptions) => {
      setSort(val);
    },
    []
  );


  return {
    dashboardView,
    sort,
    changeDashboardView,
    applySort
  };
}

export default useEventDay;
