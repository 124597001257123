import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { ChartData, ChartOptions } from "chart.js";
import { useStaffInfo } from "../../context/Staff";
import { useBookingSession } from "../../context/BookingSession";
import { TTrack } from "../../interfaces";
import { getDriverCount } from "../Utilities/dashboard";

interface StaffInfoChartProps {
  date: DateTime;
  labels: string[];
  track: TTrack;
}
const StaffInfoChart: React.FC<StaffInfoChartProps> = ({
  labels,
  date,
  track,
}) => {
  const { data: staffData } = useStaffInfo();
  const { filteredSessions } = useBookingSession();

  const formattedTodaysDate = useMemo(
    () => date.toFormat("ccc LLL dd kkkk") + " (Today)",
    [date]
  );

  const { mainTrack, miniTrack } = staffData;

  const staffDataset = useMemo(
    () =>
      Object.keys(track === "MAIN_TRACK" ? mainTrack : miniTrack)
        .map((time) => ({
          x: time as any,
          y:
            (track === "MAIN_TRACK" ? mainTrack : miniTrack)[time]?.length || 0,
        }))
        .filter((obj) => obj.x && labels.includes(obj.x)),
    [mainTrack, track, miniTrack, labels]
  );

  const bookingsDataset = useMemo(
    () =>
      filteredSessions
        .map((session) => ({
          x: track === "MAIN_TRACK" ? session.option3 : session.option1,
          y: session.bookings.length,
        }))
        .filter((obj) => obj.x && labels.includes(obj.x)),
    [filteredSessions, track, labels]
  );

  const inventoryQuantityData = useMemo(() => {
    const quantityData: {
      [key: string]: number;
    } = {};
    filteredSessions.forEach((session) => {
      const x = track === "MAIN_TRACK" ? session.option3 : session.option1;
      if (x && labels.includes(x) && session.inventory_quantity >= 0) {
        quantityData[x] = session.inventory_quantity;
      }
    });
    return quantityData;
  }, [filteredSessions, labels, track]);

  const rollingAverageDataset = useMemo(() => {
    const rollingAverageData = labels
      .map((x, i) =>
        !isNaN(inventoryQuantityData[x])
          ? {
              x,
              y: +Math.round(
                ((inventoryQuantityData[Math.max(0, i - 1)] || 0) +
                  inventoryQuantityData[x] +
                  (inventoryQuantityData[
                    Math.min(
                      Object.keys(inventoryQuantityData).length - 1,
                      i + 1
                    )
                  ] || 0)) /
                  3
              ).toFixed(2),
            }
          : null
      )
      .filter((e) => e !== null);

    return rollingAverageData as { x: string; y: number }[];
  }, [labels, inventoryQuantityData]);

  const data: ChartData<"line"> = {
    labels,
    datasets: [
      {
        label: "Staff",
        data: staffDataset,
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
      {
        label: "Bookings",
        data: bookingsDataset,
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
      {
        label: "Rolling Average",
        data: rollingAverageDataset,
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: `${formattedTodaysDate}`,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const { x, y } = context.raw as any;

            return context.datasetIndex === 0
              ? (track === "MAIN_TRACK" ? mainTrack : miniTrack)[x]
                  .sort((a, b) => +a.role.split(".")[0] - +b.role.split(".")[0])
                  .map(
                    (data) =>
                      `${data.role} - ${data.name} (${DateTime.fromMillis(
                        data.startTime * 1000,
                        {
                          zone: "Australia/Sydney",
                        }
                      ).toFormat("h:mma")}-${DateTime.fromMillis(
                        data.endTime * 1000,
                        {
                          zone: "Australia/Sydney",
                        }
                      ).toFormat("h:mma")})`
                  )
              : context.datasetIndex === 1
              ? filteredSessions
                  .find(
                    (session) =>
                      (track === "MAIN_TRACK"
                        ? session.option3
                        : session.option1) === x
                  )
                  ?.bookings.map(
                    (booking) =>
                      `${booking.order.customer?.first_name || ""} ${
                        booking.order.customer?.last_name || ""
                      } : ${getDriverCount(booking)}`
                  )
              : [
                  `Rolling Average: ${y}`,
                  `Inventory Quantity: ${inventoryQuantityData[x]}`,
                ];
          },
        },
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default StaffInfoChart;
