import Modal from "../../components/Shared/Modal";
import { EIssueModalTypes, useIssue } from "../../context/Issue";
import CreateIssueModalContent from "./CreateIssueModalContent";
import Table from "./Table";

const IssuesPage = () => {
  const { showModal, onCloseModal, commonModalData } = useIssue();

  return (
    <>
      <Table />

      <Modal
        show={showModal}
        onClose={() => onCloseModal(commonModalData.id)}
        title={commonModalData.title}
        className="h-[95vh] max-w-4xl"
      >
        {commonModalData.id === EIssueModalTypes.CREATE_ISSUE && (
          <CreateIssueModalContent type={EIssueModalTypes.CREATE_ISSUE} />
        )}
        {commonModalData.id === EIssueModalTypes.VIEW_ISSUE && (
          <CreateIssueModalContent
            type={EIssueModalTypes.VIEW_ISSUE}
            issue={commonModalData.issue}
          />
        )}
      </Modal>
    </>
  );
};

export default IssuesPage;
