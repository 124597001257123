import { FC, ReactNode } from "react";

interface Props {
  children?: ReactNode | string;
  showRequiredSymbol?: boolean;
}
const Label: FC<Props> = ({ children, showRequiredSymbol = false }) => {
  return (
    <label className="text-[#2F1160] text-xs not-italic font-bold leading-[normal] font-[Montserrat] mb-2">
      {children} {showRequiredSymbol && <span className="text-red-600">*</span>}
    </label>
  );
};

export default Label;
