import React, { ChangeEvent, useCallback, useState } from "react";
import { SearchIcon, XIcon } from "@heroicons/react/solid";

interface Props {
  onChange: (val: string) => void;
  placeholder?: string;
  showClearTextIcon?: boolean;
}
function SearchBar({
  onChange,
  placeholder = "",
  showClearTextIcon = false,
}: Props) {
  const [text, setText] = useState("");

  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setText(val);
      if (onChange) onChange(val);
    },
    [onChange]
  );

  const handleClearBtnClick = useCallback(() => {
    setText("");
    if (onChange) onChange("");
  }, [onChange]);

  return (
    <div className="relative flex items-center h-full">
      <input
        type="text"
        className="block w-full h-full pl-2 text-gray-900 border border-solid border-gray-300 rounded-full bg-gray-100 font-normal text-base leading-6"
        placeholder={placeholder}
        value={text}
        onChange={handleTextChange}
      />
      {!!text && showClearTextIcon ? (
        <div
          className="absolute right-2.5 cursor-pointer text-[#200E32]"
          onClick={handleClearBtnClick}
        >
          <XIcon className="w-6" />
        </div>
      ) : (
        <div className="absolute right-2.5 cursor-pointer text-[#200E32]">
          <SearchIcon className="w-6" />
        </div>
      )}
    </div>
  );
}

export default SearchBar;
