import Button from "../../components/Shared/Button";
import { useSpeedControl } from "../../context/SpeedControl";
import InputSlider from "../../components/Shared/InputSlider";
import { useCallback } from "react";

const STEP = 0.001;
const MIN = 0.95;
const MAX = 1.05;

const Header = () => {
  const {
    isScriptRunning,
    difficulty,
    isChangingScriptStatus,
    startScript,
    stopScript,
    changeDifficulty,
  } = useSpeedControl();

  const generateTooltip = useCallback((value:number) => {
    if(value === 1){
      return "Normal";
    }
    const percentage = Math.abs(1-value);
    return `${value>1 ? "+":"-"}${(percentage * 100).toFixed(2)}%`;
  },[])
   
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12 ">
      <div className="flex-1"></div>      
      <div className="w-[40%] flex flex-col justify-center items-center">
        <div>Current Setting: {difficulty}</div>
        <div className="w-full">
          <InputSlider STEP={STEP} MIN={MIN} MAX={MAX} value={+difficulty} onFinalChange={changeDifficulty as any} getTooltip={generateTooltip}/>
        </div>
      </div>

      <div
        className={`w-4 h-4 ${
          isScriptRunning ? "bg-green-500" : "bg-red-500"
        } rounded-lg`}
      />
      <div>{isScriptRunning ? "Running" : "Not Running"}</div>
      <Button
        onClick={isScriptRunning ? stopScript : startScript}
        loading={isChangingScriptStatus}
        disabled={isChangingScriptStatus}
      >
        {isScriptRunning ? "Stop" : "Start"}
      </Button>
    </div>
  );
};

export default Header;
