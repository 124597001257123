import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';

interface Props{
  rtl?: boolean,
  STEP:number,
  MIN:number,
  MAX:number,
  onChange?: (val:number)=>void
  onFinalChange?: (val:number)=>void,
  value?: number,
  getTooltip ?: (value:number) => any
}
const InputSlider: React.FC<Props> = ({ rtl=false,STEP,MIN,MAX,onFinalChange,onChange,value,getTooltip }) => {
    const [values, setValues] = React.useState([1]);

    const handleOnFinalChange = React.useCallback((values:number[]) => {
      onFinalChange && onFinalChange(values[0])
    },[onFinalChange])
    
    const handleOnChange = React.useCallback((values:number[]) => {
      setValues(values)
      onChange && onChange(values[0])
    },[onChange])
    
    React.useEffect(() => {
      if(typeof value === 'number'){
        setValues([value])
      }
    }, [value])
    
    
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          rtl={rtl}
          onChange={handleOnChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values,
                    colors: ["#9D62FE", '#d1d5db'],
                    min: MIN,
                    max: MAX,
                    rtl
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                borderRadius: '16px',
                backgroundColor: '#548BF4',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  // top: '-28px',
                  top: '18px',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor: '#548BF4'
                }}
              >
                {getTooltip ? getTooltip(values[0]):values[0]}
              </div>
              {/* <div
                style={{
                  height: '16px',
                  width: '16px',
                  borderRadius: '16px',
                  backgroundColor: "transparent",
                  border: "2px solid #9D62FE"
                }}
              /> */}
            </div>
          )}
          onFinalChange={handleOnFinalChange}
        />
      </div>
    );
  };


  export default InputSlider;