import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { IStaff } from "../interfaces/Staff";

export interface IStaffInfo {
  mainTrack: {
    [key: string]: IStaff[];
  };
  miniTrack: {
    [key: string]: IStaff[];
  };
  vr: {
    [key: string]: IStaff[];
  };
}

export interface IStaffInfoContext {
  data: IStaffInfo;
  loadStaffData: () => void;
}

const StaffInfoContext = React.createContext<IStaffInfoContext>({
  data: {
    mainTrack: {},
    miniTrack: {},
    vr: {},
  },
  loadStaffData: () => {},
});

interface Type {
  date: Date;
  children: ReactNode;
}
const StaffInfoProvider: FC<Type> = ({ children, date }) => {
  const [data, setData] = useState({
    mainTrack: {},
    miniTrack: {},
    vr: {},
  });
  const loadStaffData = useCallback(
    async (d = date) => {
      const { data } = await axios.get(
        // "http://localhost:5001/hyper-karting/us-central1/staffInfo",
        "https://us-central1-hyper-karting.cloudfunctions.net/staffInfo",
        {
          params: {
            ts: d.getTime(),
          },
        }
      );
      setData(data);
    },
    [date]
  );

  useEffect(() => {
    loadStaffData();
  }, [loadStaffData]);
  return (
    <StaffInfoContext.Provider value={{ data, loadStaffData }}>
      {children}
    </StaffInfoContext.Provider>
  );
};
export default StaffInfoProvider;

export function useStaffInfo() {
  return useContext(StaffInfoContext);
}
