import { useState, useEffect, useMemo } from "react";
import CommissionTable from "./CommissionTable";
import { DateTime } from "luxon";
import { ICommissionRow } from "../../interfaces/Commission";
import axios from "axios";

const getDateStr = (date: DateTime) => {
  const todayFmtDate = DateTime.local({
    zone: "Australia/Sydney",
  }).toFormat("yyyy-MM-dd");
  const fmtDate = date.toFormat("yyyy-MM-dd");

  if (todayFmtDate === fmtDate) {
    return "TODAY";
  }
  return fmtDate;
};

interface IDateRange {
  from: DateTime;
  to: DateTime;
}

export const CommissionScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<DateTime>(
    DateTime.local({
      zone: "Australia/Sydney",
    })
  );

  const [dateRange, setDateRange] = useState<IDateRange>({
    from: date,
    to: date,
  });

  const todayDate = useMemo(
    () =>
      DateTime.local({
        zone: "Australia/Sydney",
      }),
    []
  );

  const [commissionData, setCommissionData] = useState([] as ICommissionRow[]);

  const intervals: IDateRange[] = useMemo(
    () => [
      {
        from: date
          .minus({
            days: 41,
          })
          .startOf("day"),
        to: date.minus({
          days: 28,
        }),
      },
      {
        from: date
          .minus({
            days: 27,
          })
          .startOf("day"),
        to: date.minus({
          days: 14,
        }),
      },
      {
        from: date
          .minus({
            days: 13,
          })
          .startOf("day"),
        to: date,
      },
      {
        from: todayDate.startOf("day"),
        to: todayDate,
      },
    ],
    [date, todayDate]
  );

  useEffect(() => {
    let intervalDate = DateTime.fromFormat("2022-07-03", "yyyy-MM-dd", {
      zone: "Australia/Sydney",
    }).endOf("day");

    const todayStartTS = todayDate.startOf("day").toMillis();
    do {
      intervalDate = intervalDate.plus({
        days: 14,
      });
    } while (intervalDate.toMillis() < todayStartTS);

    setDate(intervalDate);
  }, [todayDate]);

  useEffect(() => {
    if (intervals.length) {
      setDateRange(intervals[intervals.length - 1]);
    }
  }, [intervals]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        // "http://127.0.0.1:5001/hyper-karting/us-central1/commission"
        "https://us-central1-hyper-karting.cloudfunctions.net/commission",
        {
          params: {
            from: dateRange.from.toMillis(),
            to: dateRange.to.toMillis(),
          },
        }
      )
      .then((data) => {
        if (data.data.success) {
          setCommissionData(data.data.data as ICommissionRow[]);
        }
      })
      .finally(() => setIsLoading(false));
  }, [dateRange]);

  return (
    <>
      <div className="min-h-full">
        {/* Main column */}
        <div className="xl:pl-64 flex flex-col">
          {/* Search header */}

          <main className="flex-1">
            <div className="pt-8 px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Commission
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Displays commission from {getDateStr(dateRange.from)} to{" "}
                    {getDateStr(dateRange.to)}
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {intervals.map((interval, index) => (
                    <DateRangeButton
                      key={index}
                      range={interval}
                      onClick={setDateRange}
                      selected={
                        getDateStr(interval.from) === getDateStr(dateRange.from)
                      }
                    />
                  ))}
                </div>
              </div>
              <CommissionTable data={commissionData} isLoading={isLoading} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

interface DateRangeButtonProps {
  range: IDateRange;
  onClick: (range: IDateRange) => void;
  selected: boolean;
}
function DateRangeButton(props: DateRangeButtonProps) {
  const { range, onClick, selected } = props;
  const name = useMemo(() => {
    const fromDate = getDateStr(range.from);
    const toDate = getDateStr(range.to);
    if (fromDate === toDate) {
      return fromDate;
    }
    return `${fromDate} - ${toDate}`;
  }, [range]);
  return (
    <button
      type="button"
      className={`inline-flex items-center justify-center rounded-md border border-transparent ${
        selected ? "bg-indigo-600" : "bg-gray-400"
      } px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-2`}
      onClick={() => onClick(range)}
    >
      {name}
    </button>
  );
}
