import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IPriceRecommendation, TBookingType } from "../interfaces";
import axios from "axios";
import { useSession } from "../components/Session";
import { IProductVariant } from "shopify-api-node";

type IItem = 
  | { variantId: string; price: number }
  | { variantId: string; quantity: number }
  | { variantId: string; option1: string }
  | { variantId: string; option2: string }
  | { variantId: string; option3: string };
interface IItemState { 
  price: { [key: string]: number };
  quantity: { [key: string]: number };
  option1: { [key: string]: string };
  option2: { [key: string]: string };
  option3: { [key: string]: string };
}

const defaultItems:IItemState = { price: {}, quantity: {}, option1: {}, option2: {}, option3: {} };


export interface IPriceChangerContext {
  isUpdating: boolean;
  view: TBookingType;
  selectedDate: Date;
  changeView: (val: TBookingType) => void;
  changeSelectedDate: (val: Date) => void;
  toggleItem: (data: IItem) => void;
  removeItemByVariant: (variantId: string, type?: keyof IItemState) => void;
  clearItems: () => void;
  updateItems: (productId: number, cb: () => void) => void;
  recommendedPrices: IPriceRecommendation[];
  items: IItemState;
  getBgColor: (view: TBookingType) => string;
  isUpdateButtonActive: boolean;
  isSessionSelected: (variantId: string | number) => boolean;
  config: Record<string,string>[][];
}

const PriceChangerContext = React.createContext<IPriceChangerContext>({
  isUpdating: false,
  view: "MAIN_TRACK",
  selectedDate: new Date(),
  changeView: (val: TBookingType) => {},
  changeSelectedDate: (val: Date) => {},
  toggleItem: (data: IItem) => {},
  removeItemByVariant: () => {},
  clearItems: () => {},
  updateItems: (id: number, cb: () => void) => {},
  recommendedPrices: [],
  items: defaultItems,
  getBgColor: () => "",
  isUpdateButtonActive: false,
  isSessionSelected: () => false,
  config: []
});

interface Type {
  children: ReactNode;
}

const PriceChangerProvider: FC<Type> = ({ children }) => {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [items, setItems] = useState<IItemState>(defaultItems);
  const [isUpdating, setIsUpdating] = useState(false);
  const [recommendedPrices, setRecommendedPrices] = useState([])
  const [config, setConfig] = useState<Record<string,string>[][]>([])

  const { getToken } = useSession();
  const isUpdateButtonActive = useMemo(() => !!(Object.keys(items.price).length || Object.keys(items.quantity).length) || !!(Object.keys(items.option1).length || Object.keys(items.option2).length || Object.keys(items.option3).length), [items])

  console.log("items", items)

  const isSessionSelected = useCallback((variantId: string | number ) => !!(items.price[variantId] || items.quantity[variantId] || items.option1[variantId] || items.option2[variantId] || items.option3[variantId]),[items]);
  
  const toggleItem = useCallback((data: IItem) => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState));
      const variantId = String(data.variantId);

      (Object.keys(data) as Array<keyof IItem>).forEach(key => {
        if (key !== 'variantId' && key in newState) {
          const stateKey = key as keyof IItemState;
          if (newState[stateKey][variantId] === data[key]) {
            delete newState[stateKey][variantId];
          } else {
            newState[stateKey][variantId] = data[key] as any;
          }
        }
      });

      return newState
    });
  }, []);

  const removeItemByVariant = useCallback((variantId: string, type?: keyof IItemState) => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState));
      if(type){
        if (Object.keys(newState[type]).includes(variantId)) {
          delete newState[type][variantId];
        }
      }else{
        (Object.keys(newState) as Array<keyof IItemState>).forEach(key => {
          delete newState[key][variantId];
        });
      }
      return newState
    });
  }, []);

  const clearItems = useCallback(() => {
    setItems(defaultItems);
  }, []);

  const changeView = useCallback((val: TBookingType) => {
    setView(val);
    setItems(defaultItems)
  },[]);

  const changeSelectedDate = useCallback((val: Date) => {
    setSelectedDate(val);
    setItems(defaultItems)
  },[]);

  const getBgColor = useCallback((view: TBookingType) => view === "MAIN_TRACK" ? "bg-[#F609FF]" : (view === "MINI_TRACK" ? "bg-[#9D62FE]" : "bg-[#41BDFE]"),[])

  const updateItems = useCallback(
    async (productId: number, cb: () => void) => {
      try {
        const token = await getToken();
        if (!token) {
          return {
            status: false,
            message: "Token not generated",
          };
        }
        setIsUpdating(true);
        const variants: { id: number; updates: Partial<IProductVariant> }[] = [];

        const updateFields: (keyof IItemState)[] = ['price', 'quantity', 'option1', 'option2', 'option3'];

        updateFields.forEach((field) => {
          Object.entries(items[field]).forEach(([variantId, value]) => {
            const sanitizedKey: keyof IProductVariant = field === "quantity" ? "inventory_quantity" : field;
            const sanitizedVal = field === "price" ? String(value) : value;
            
            const id = Number(variantId);            
            const existingVariant = variants.find(v => v.id === id);
            if (existingVariant) {
              existingVariant.updates[sanitizedKey] = sanitizedVal;
            } else {
              variants.push({
                id,
                updates: { [sanitizedKey]: sanitizedVal }
              });
            }
          });
        });
        
        await axios.post(
          // "http://127.0.0.1:5001/hyper-karting/us-central1/sessionEditorV2",
          "https://us-central1-hyper-karting.cloudfunctions.net/sessionEditorV2",
          { productId, variants },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsUpdating(false);
        setItems(defaultItems);
        cb();
      } catch (error) {
        setIsUpdating(false);
        return {
          status: false,
          message: error,
        };
      }
    },
    [items, getToken]
  );

  useEffect(() => {
    // axios.get("http://127.0.0.1:5001/hyper-karting/us-central1/recommendedVariantsPrice?ts="+selectedDate.getTime()).then(({data})=>{
    axios.get("https://us-central1-hyper-karting.cloudfunctions.net/recommendedVariantsPrice?ts="+selectedDate.getTime()).then(({data})=>{
      if(data.success){
        setRecommendedPrices(data.data)
        const config = data?.config;
        if(config?.length){
          setConfig(config)
        }
      }
    }).catch(console.log)
  }, [selectedDate])
  

  return (
    <PriceChangerContext.Provider
      value={{
        isUpdating,
        changeSelectedDate,
        changeView,
        selectedDate,
        toggleItem,
        view,
        removeItemByVariant,
        clearItems,
        updateItems,
        recommendedPrices,
        items,
        getBgColor,
        isUpdateButtonActive,
        isSessionSelected,
        config
      }}
    >
      {children}
    </PriceChangerContext.Provider>
  );
};

export function usePricechanger() {
  return useContext(PriceChangerContext);
}

export default PriceChangerProvider;
