import React from "react";
import { TLogDoc } from "../../interfaces/types";
import { getDatetimeFromSku } from "../../utilities";
import { DateTime } from "luxon";
import { IProductVariant } from "shopify-api-node";

const Description = ({ log }: { log: TLogDoc }) => {
  switch (log.type) {
    case "RACE_READY_MESSAGE":
      const raceReadyMessageLogdt = getDatetimeFromSku(
        log.sku,
        log.productType
      );

      return (
        <p>
          <b>{log.user.name}</b> sent race ready sms for{" "}
          <b>
            {raceReadyMessageLogdt && raceReadyMessageLogdt.isValid
              ? raceReadyMessageLogdt.toFormat("dd-MM-yyyy hh:mma")
              : log.sku}
          </b>{" "}
          session of <b>{log.productType}</b>
        </p>
      );
    case "DRIVER_ADDED_TO_SESSION_MESSAGE":
      const { uuids } = log;
      return (
        <p>
          Cron triggered added to session sms for <b>{uuids.length}</b> drivers
        </p>
      );
    case "GOOGLE_REVIEW_MESSAGE":
      return (
        <p>
          <b>{log.user.name}</b> sent google review sms to{" "}
          <b>{log.customerId}</b> customer for <b>{log.orderId} order</b>
        </p>
      );
    case "HK_REFUND":
      return (
        <p>
          Refunds of <b>{log.orderId}</b> order:
          <ul>
            {log.refunds.map((refundData) => (
              <li key={refundData.ts}>
                <p>
                  <b>{refundData.user.name}</b> created refund with giftcard:{" "}
                  <b>{refundData.giftCard.code}</b> of balance:{" "}
                  <b>{refundData.giftCard.balance}</b> and assigned to{" "}
                  <b>{refundData.giftCard.customer_id}</b> customer at{" "}
                  <b>
                    {DateTime.fromMillis(refundData.ts).toFormat(
                      "dd-MM-yyyy hh:mma"
                    )}
                  </b>
                </p>
              </li>
            ))}
          </ul>
        </p>
      );
    case "PRICE_CHANGER":
      return (
        <p>
          <b>{log.user.name}</b> changed price of <b>{log.variant.title}</b>{" "}
          <b>{log.productHandle}</b> productId: <b>{log.priductId}</b> and
          variantId: <b>{log.variantId}</b> from old price{" "}
          <b>{log.variant.price}</b> to new price <b>{log.updatedPrice}</b>
        </p>
      );
    case "QUANTITY_ADJUSTED":
      return (
        <p>
          <b>{log.user.name}</b> <b>{log.adjust ? "adjusted" : "changed"}</b>{" "}
          the quantity of productId: <b>{log.productId}</b> and variantId:{" "}
          <b>{log.variantId}</b> with value <b>{log.quantity}</b> so updated
          quantity for the same is <b>{log.updatedQuantity}</b>
        </p>
      );
    case "VR_ISSUE":
      return (
        <p>
          issue id:<b>{log.id}</b> <b>{log.action}</b> by <b>{log.user.name}</b>
        </p>
      );
    case "ARCADE_CARD_NUMBER_SUBMITTED":
      return (
        <p>
          <b>{log.user.name}</b> changed the cardnumber of <b>{log.id}</b> with{" "}
          <b>{log.card_number}</b>
        </p>
      );
    case "VARIANT_UPDATE":
      const updatesString = Object.keys(log.updates)
        .map((key) => `${key}: ${log.updates[key as keyof IProductVariant]}`)
        .join(", ");
      return (
        <p>
          <b>{log.user.name}</b> updated variant <b>{log.variantId}</b> and
          changes are <b>{updatesString}</b>
        </p>
      );
    case "DRIVER_REGISTRATION_REMINDER":
      return (
        <p>
          Cron triggered sms to the customer of order: <b>{log.orderId}</b> for
          registration reminder.
        </p>
      );
    case "EVENT_DAY_SMS_NOTIFICATION":
      const { customerId, eventId, orderId } = log;
      return (
        <p>
          Cron triggered event notification for <b>${eventId} event</b> and send notification to <b>${customerId} customer</b> for placing <b>${orderId} order</b>
        </p>
      )
    default:
      return <></>;
  }
};

export default Description;
