import React from 'react'
import { RefreshIcon } from "@heroicons/react/outline";
import DropDown from '../../components/Shared/DropDown'
import Calender from '../../components/Shared/Calender';
import { TLogType } from '../../interfaces';
import SearchBar from '../../components/Shared/SearchBar';

interface Props{
    logType: TLogType;
    searchText: string;
    handleLogTypeChange: (type:TLogType) => void;
    handleSelectedDateChange: (date: Date) => void;
    refreshLogs: () => void;
    handleSearchChange: (val: string)=>void;
}
const Header = ({ logType, searchText, handleLogTypeChange, handleSelectedDateChange, refreshLogs, handleSearchChange }:Props) => {
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12 ">
      <div className={`${searchText ? "w-2/3" : "w-1/3"} h-full transition-width duration-500 ease-in-out`}>
        <SearchBar onChange={handleSearchChange} showClearTextIcon />
      </div>
      <div className={`${searchText ? "hidden" : ""}`}>
        <Calender onDateChange={handleSelectedDateChange} />
      </div>
      <div className="flex-1"></div>
      <DropDown
        items={[
          {
            key: "PRICE_CHANGER",
            label: "Price Changer",
          },
          {
            key: "QUANTITY_ADJUSTED",
            label: "Races Opens/Closes",
          },
        ]}
        selected={logType}
        onSelect={(val) => handleLogTypeChange(val as TLogType)}
        selectedBgColorCode='#F609FF'
      />
      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={refreshLogs}
      >
        <RefreshIcon className="text-white w-6" />
      </div>
    </div>
  )
}

export default Header