import React from "react";
import { PageLayout } from "../../layouts";
import Table from "./Table";
import OrderProvider from "../../context/OrderContext";
import ArcadeCardProvider from "../../context/ArcadeCard";
import Header from "./Header";

export const ArcadeGames = () => {
  return (
    <>
      <OrderProvider>
        <ArcadeCardProvider type="arcade-cards-tab">
          <PageLayout Header={<Header />}>
            <Table />
          </PageLayout>
        </ArcadeCardProvider>
      </OrderProvider>
    </>
  );
};
