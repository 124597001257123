import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useFirebase } from "../Firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { ICustomer } from "shopify-api-node";
import LoadingSpinner from "../Loading";
import MembersTable from "./MembersTable";

interface ICustomerWithTags extends ICustomer {
  tagsArray: string[];
}

type FilterMemmberParams = {
  q?: string;
};

export const MembersScreen = () => {
  const firebase = useFirebase();

  const [loading, setLoading] = useState(true as boolean);
  const [members, setMembers] = useState([] as ICustomerWithTags[]);
  const [filteredMembers, setFilteredMembers] = useState(
    [] as ICustomerWithTags[]
  );

  useEffect(() => {
    console.log("Loading customers");
    const { db } = firebase;
    const customerRef = collection(db, "customers");
    const customerQuery = query(
      customerRef,
      where("tagsArray", "array-contains", "Active Subscriber")
    );

    getDocs(customerQuery)
      .then((qs) => {
        const returnedCustomers = [] as ICustomerWithTags[];
        console.log(qs);
        if (qs.empty) {
          console.log("Query results empty");
        }
        qs.forEach((doc) => {
          const customerData = doc.data() as ICustomerWithTags;
          returnedCustomers.push(customerData);
          console.log("Got customeruct data", customerData);
        });

        setMembers(returnedCustomers);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // setLoading(false);
      });
  }, [firebase]);

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  const applyFilter = useCallback(
    ({ q }: FilterMemmberParams) => {
      if (!q) {
        setFilteredMembers(members);
        return;
      }
      setFilteredMembers(
        members.filter((member) => {
          if (q) {
            const fName = member?.first_name?.toLowerCase();
            const lName = member?.last_name?.toLowerCase();
            const email = member?.email?.toLowerCase();

            if (fName?.includes(q) || lName?.includes(q) || email?.includes(q))
              return true;
          }
          return false;
        })
      );
    },
    [members]
  );

  return (
    <>
      <div className="min-h-full">
        {/* Main column */}
        <div className="xl:pl-64 flex flex-col">
          {/* Search header */}

          <main className="flex-1">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:pl-4">
              <div className="flex min-w-3 w-[100%] justify-between items-center">
                <input
                  type="text"
                  name="search-bar"
                  id="search-bar"
                  className="shadow-sm focus:border-grey-500 block w-1/3 sm:text-sm border border-gray-300 rounded-md h-12 pl-2"
                  placeholder="Search"
                  onChange={(e) => {
                    applyFilter({ q: e?.target?.value?.toLowerCase() });
                  }}
                />
              </div>
            </div>
            <div className="pt-8 px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900 pb-3">
                    Hyper Karting Members
                  </h1>
                  <p className="mt-2 text-sm text-gray-700"></p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {/* BUTTON TO USE IN FUTURE? */}
                  {/* <button
                  type="button"
                  className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-2`}
                >
                  Something
                </button> */}
                </div>
              </div>
            </div>
            <div
              className="hidden sm:block"
              style={{ height: `calc(100vh - 80px)`, overflow: `hidden` }}
            >
              <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-scroll h-[100%]">
                {loading && <LoadingSpinner />}
                {!loading && <MembersTable members={filteredMembers} />}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
