import { collection, getDocs, query, where } from "firebase/firestore";
import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useFirebase } from "../components/Firebase";
import { IFirebaseOrder } from "../interfaces/firebaseEntities";

export interface IOrderContext {
  orders: IFirebaseOrder[];
  loadOrders: (from: number, to: number) => Promise<IFirebaseOrder[]>;
  loading: boolean;
}

const OrderContext = React.createContext<IOrderContext>({
  orders: [],
  loadOrders: () => Promise.resolve([]),
  loading: false,
});

interface Type {
  children: ReactNode;
}

const OrderProvider: FC<Type> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IFirebaseOrder[]>([]);
  const firebase = useFirebase();

  const db = useMemo(() => firebase.db, [firebase]);

  const orderRef = useMemo(() => collection(db, "orders"), [db]);

  const loadOrders = useCallback(
    async (from: number, to: number) => {
      try {
        setLoading(true);
        const orderQuery = query(
          orderRef,
          where("created_at_ts", ">=", from),
          where("created_at_ts", "<=", to)
        );
        const qs = await getDocs(orderQuery);

        const newOrders = qs.docs.map((doc) => doc.data() as IFirebaseOrder);
        setOrders(newOrders);
        return newOrders;
      } catch (error) {
        console.log(error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [orderRef]
  );

  return (
    <OrderContext.Provider value={{ orders, loadOrders, loading }}>
      {children}
    </OrderContext.Provider>
  );
};

export function useOrders() {
  return useContext(OrderContext);
}

export default OrderProvider;
