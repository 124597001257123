import { RefreshIcon } from "@heroicons/react/outline";
import Calender from "../../components/Shared/Calender";
import { useSmsLogs } from "../../context/SmsLogs";
import SearchBar from "../../components/Shared/SearchBar";

const Header = () => {
  const { searchText, changeSelectedDate, refresh, applySearch } = useSmsLogs();
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12 ">
      <div
        className={`${
          searchText ? "w-2/3" : "w-1/3"
        } h-full transition-width duration-500 ease-in-out`}
      >
        <SearchBar onChange={applySearch} showClearTextIcon />
      </div>
      {!searchText && <Calender onDateChange={changeSelectedDate} />}

      <div className="flex-1"></div>

      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={refresh}
      >
        <RefreshIcon className="text-white w-6" />
      </div>
    </div>
  );
};

export default Header;
