import { MenuAlt1Icon } from '@heroicons/react/outline'
import React from 'react'

interface HamBurgerProps {
    setSidebarOpen: (value: boolean) => void;
  }
  
export default function HamBurger({setSidebarOpen}:HamBurgerProps) {
  return (
    <button
      type="button"
      className="p-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 xl:hidden"
      onClick={() => setSidebarOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}
