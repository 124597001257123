import Log from "../../context/Log";
import LogsPage from "./LogsPage";

export const Logs = () => {
  return (
    <Log>
      <LogsPage />;
    </Log>
  );
};
