import React, { useCallback, useMemo } from "react";
import { ISessionWithBookings } from "../../interfaces/Session";
import { useBookingSession } from "../../context/BookingSession";
import { EXPERT_RACE_TIMINGS } from "../../constants/Dashboard";
import { CheckIcon } from "@heroicons/react/solid";
import { usePricechanger } from "../../context/PriceChanger";
import {
  MAIN_TRACK_PRICE_OPTIONS,
  MINI_TRACK_PRICE_OPTIONS,
  VR_PRICE_OPTIONS,
} from "../../constants/priceChanger";
import { IPriceRecommendation, TMainTrackAgeFilters, TMainTrackRaceTypeFilter } from "../../interfaces";
import { isBreakOrder } from "../../utilities";
import PriceBlock from "./PriceBlock";
import QuantityBlock from "./QuantityBlock";
import EditTextField from "../../components/Shared/EditTextField";
import { IProduct } from "shopify-api-node";

interface Props {
  session: ISessionWithBookings;
  recommendation?: IPriceRecommendation;
  config: Record<string,string>[][];
  product?: IProduct;
}
function PriceRow({ session, recommendation, config, product }: Props) {
  const { track: view } = useBookingSession();
  const { items, toggleItem, removeItemByVariant, getBgColor, isSessionSelected } = usePricechanger();

  const sessionTime = useMemo(() => (["MAIN_TRACK", "JUNIOR_TRACK", "INTERMEDIATE_TRACK"].includes(view)) ? session.option3?.trim()?.toLowerCase() : session.option1?.trim()?.toLowerCase(),[view, session]);
  const bgColor = useMemo(() => getBgColor(view),[view, getBgColor]);
  const price = useMemo(() => Number(session.price), [session]);
  const variantId = useMemo(() => session.id, [session]);
  const priceOptions = useMemo(() => view === "MAIN_TRACK" ? MAIN_TRACK_PRICE_OPTIONS : (view === "MINI_TRACK" ? MINI_TRACK_PRICE_OPTIONS : VR_PRICE_OPTIONS),[view]);
  const showOpenOrCloseRace = useMemo(() => session.bookings.length === 0 || session.bookings.every(booking => isBreakOrder(booking.order)), [session]);

  const onChangeQuantity = useCallback(async (val: number | string) => {
    toggleItem({ variantId: variantId.toString(), quantity: +val });
  }, [toggleItem, variantId]);

  const handleChangeAgeFilter: React.ChangeEventHandler<HTMLSelectElement> = useCallback((e) => {
    toggleItem({ variantId: variantId.toString(), option1: e.target.value });
  }, [toggleItem, variantId]);

  const handleChangeRaceTypeFilter: React.ChangeEventHandler<HTMLSelectElement> = useCallback((e) => {
    toggleItem({ variantId: variantId.toString(), option2: e.target.value });
  }, [toggleItem, variantId]);

  const checkPriceDisabled = useCallback((variantId: number, productId?: number)=>{
    const productGqId = `gid://shopify/Product/${productId}`
    const variantGqId = `gid://shopify/ProductVariant/${variantId}`

    const productConfig = config.find(c=> c.find(v=>v.type === "list.product_reference" && v.value.includes(productGqId)))
    const variantConfig = config.find(c=> c.find(v=>v.type === "list.variant_reference" && v.value.includes(variantGqId)))

    if(productConfig){
      return productConfig.find(c=>c.type === "single_line_text_field")?.value
    }
    if(variantConfig){
      return variantConfig.find(c=>c.type === "single_line_text_field")?.value
    }
    return undefined
  },[config])

  return (
    <tr data-variant-id={variantId}>
      {/* checkbox */}
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            {isSessionSelected(variantId) ? (
              <div
                className={`rounded-md p-0.5 ${bgColor}`}
                onClick={() => {removeItemByVariant(variantId.toString())}}
              >
                <CheckIcon className={`w-4 cursor-pointer text-white`} />
              </div>
            ) : (
              <div className="border-[3px] border-solid border-gray-200 rounded-md p-1.5"></div>
            )}
          </div>
        </div>
      </td>

      {/* Session/Time */}
      <td className={`px-2 py-3 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {sessionTime}
              <br />
              {view === "MAIN_TRACK" ? (
                <>
                  <select
                    value={items.option1[variantId] || session.option1 || ""}
                    onChange={handleChangeAgeFilter}
                    className="w-full rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-bold text-xs leading-none text-gray-700"
                  >
                    <option value={TMainTrackAgeFilters["Ages 12-15"]}>
                      {`12-15`}
                    </option>
                    <option value={TMainTrackAgeFilters["Ages 16+"]}>{`16+`}</option>
                    <option value={TMainTrackAgeFilters["140cm+ | Ages under 12"]}>
                      {`<12`}
                    </option>
                  </select>
                  <br />
                  <select
                    value={items.option2[variantId] || session.option2 || ""}
                    onChange={handleChangeRaceTypeFilter}
                    className="w-full rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-bold text-xs leading-none text-gray-700"
                  >
                    <option value={TMainTrackRaceTypeFilter.Expert}>
                      {TMainTrackRaceTypeFilter.Expert}
                    </option>
                    <option value={TMainTrackRaceTypeFilter.Standard}>
                      {TMainTrackRaceTypeFilter.Standard}
                    </option>
                  </select>
                </>
              ) : view === "MINI_TRACK" ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option3}
                  </span>
                  <br />
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {EXPERT_RACE_TIMINGS.includes(
                      session.option1?.trim()?.toLowerCase() || ""
                    )
                      ? "Expert"
                      : "Standard"}
                  </span>
                </>
              ) : (view === "INTERMEDIATE_TRACK" || view === "JUNIOR_TRACK") ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option2}
                  </span>
                </>
              ) : (
                <>
                  <small className="font-medium text-xs leading-tight text-gray-700">
                    {session.option2}
                  </small>
                </>
              )}
              <br />
            </span>
          </div>
        </div>
      </td>
      {/* Quantity */}
      <td className={`px-2 py-3 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0 justify-center">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {/* {session.inventory_quantity} */}
              <EditTextField
                defaultValue={(items.quantity[variantId] || session.inventory_quantity).toString()}
                onSubmit={onChangeQuantity}
                variant="small"
                className="session-editor-quantity-container"
              />
            </span>
          </div>
        </div>
      </td>

      {/* prices */}
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 hover:text-gray-600 gap-2">
          {priceOptions.map((p) => (
            <PriceBlock
              key={p}
              bgColor={bgColor}
              isActualPrice={price === p}
              isSelected={items.price[variantId] === p}
              onClick={() => toggleItem({ variantId: variantId.toString(), price: p })}
              price={p}
              recommendation={recommendation?.VARIANT_RECOMMENDED_PRICE === p ? recommendation : undefined}
              disabled={checkPriceDisabled(variantId, product?.id)}
            />
          ))}
        </div>
      </td>

      {/* open close session */}
      <td className={`px-2 py-3 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0 justify-center">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {showOpenOrCloseRace && <QuantityBlock session={session} selected={Object.keys(items.quantity).includes(variantId.toString())} onClick={onChangeQuantity} view={view} />}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default PriceRow;
