import React, { FC, ReactNode, useContext, useState } from "react";
import Modal from "../components/Shared/Modal";

export interface IModalData {
  id: string;
  title: string;
  onClose: () => void;
  content: ReactNode;
  className?: string;
}

export interface IModalContext {
  showModal: boolean;
  modalData: IModalData | null;
  setshowModal: (val: boolean) => void;
  setModalData: (val: IModalData) => void;
}

const ModalContext = React.createContext<IModalContext>({
  showModal: false,
  modalData: null,
  setshowModal: () => {},
  setModalData: () => {},
});

interface Type {
  children: ReactNode;
}
const ModalProvider: FC<Type> = ({ children }) => {
  const [showModal, setshowModal] = useState(false);
  const [modalData, setModalData] = useState<IModalData | null>(null);

  return (
    <ModalContext.Provider value={{ showModal, modalData, setshowModal, setModalData }}>
      {children}
      {showModal && modalData && (
        <Modal show={showModal} onClose={modalData.onClose} title={modalData.title} className={modalData.className ?? ""}>
          {modalData.content}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};
export default ModalProvider;

export function useModal() {
  return useContext(ModalContext);
}
