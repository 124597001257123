import { useCallback } from 'react'
import Button from '../../components/Shared/Button'
import { ISessionWithBookings } from '../../interfaces/Session'
import { TBookingType } from '../../interfaces'
import { NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK, NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK, NUMBER_OF_SEATS_IN_A_VR } from '../../constants/Dashboard'

interface Props{
    session: ISessionWithBookings,
    selected: boolean,
    onClick: (val: number) => Promise<void>,
    view: TBookingType
}

const QuantityBlock = ({ session, selected,view, onClick }: Props) => {
    const isClosedRace = session.inventory_quantity === 0;
    const maxQuantity = view === "MAIN_TRACK" ? NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK : view === "MINI_TRACK" ? NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK : NUMBER_OF_SEATS_IN_A_VR;

    const handleCloseButtonClick = useCallback(() => {
        onClick(0)
    }, [onClick])
    
    const handleOpenButtonClick = useCallback(() => {
        onClick(maxQuantity)
    }, [onClick, maxQuantity])

    return (
        <div className='flex gap-2'>
            <div className={`p-1 rounded-[100px] ${(selected && !isClosedRace) ? "hk-gradient-bg" : ""}`}>
                <Button withCustomColor className={`${isClosedRace ? "bg-red-300 text-white" : "bg-red-500 text-white"}`} disableMode={isClosedRace} onClick={handleCloseButtonClick}>
                    {isClosedRace ? "Closed": "Close"}
                </Button>
            </div>
            <div className={`p-1 rounded-[100px] ${(selected && isClosedRace) ? "hk-gradient-bg" : ""}`}>
                <Button withCustomColor className={`${isClosedRace ? "bg-green-500 text-white" :  "bg-green-300 text-white"}`} disableMode={!isClosedRace} onClick={handleOpenButtonClick}>
                    {!isClosedRace ? "Opened" : "Open"}
                </Button>
            </div>
        </div>
    )
}

export default QuantityBlock