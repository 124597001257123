import { PageLayout } from "../../layouts";
import ModalProvider from "../../context/Modal";
import Header from "./Header";
import useEventDay from "./useEventDay";
import EventTable from "./EventTable";
import { EventNotificationProvider } from "./useEventsContext";
export const EventsDay = () => {
  const { dashboardView, sort, applySort, changeDashboardView } = useEventDay();
  return (
    <EventNotificationProvider>
      <ModalProvider>
        <PageLayout
          Header={
            <Header
              changeDashboardView={changeDashboardView}
              sort={sort}
              applySort={applySort}
            />
          }
        >
          <EventTable dashboardView={dashboardView} sort={sort} />
        </PageLayout>
      </ModalProvider>
    </EventNotificationProvider>
  );
};
