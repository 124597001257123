import { TLogDoc } from "../../interfaces/types";
import { DateTime } from "luxon";
import Description from "./Description";

const Row = ({ log }: { log: TLogDoc }) => {
  return (
    <tr>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-start flex-shrink-0 h-full">
          <div className="truncate">{log.type}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex flex-col justify-center flex-shrink-0 h-full items-center">
          <p className="text-[#777]">
            {DateTime.fromMillis(log.ts).toFormat("dd LLL yyyy hh:mma")}
          </p>
        </div>
      </td>
      <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div data-log={JSON.stringify(log, null, 2)}>
            <Description log={log} />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
