import { CalendarIcon } from "@heroicons/react/solid";
import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Calendar } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import Label from "./Label";

interface Props {
  required?: boolean;
  defaultValue?: Date;
  value?: Date;
  onChange?: (date: Date) => void;
  label?: string;
  disabled?: boolean;
}
const DateInput: FC<Props> = ({
  defaultValue = new Date(),
  required = false,
  label,
  onChange,
  value,
  disabled = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const [showDateRange, setShowDateRange] = useState(false);

  const handleSelect = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      setShowDateRange(false);
      onChange && onChange(date);
    },
    [onChange]
  );

  const dateStr = useMemo(
    () => DateTime.fromJSDate(selectedDate).toFormat("EEE, dd LLLL yyyy"),
    [selectedDate]
  );

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  return (
    <div className="flex flex-col w-full">
      {!!label && <Label showRequiredSymbol={required}>{label}</Label>}
      <div className="relative">
        <input
          type="text"
          className={`flex-1 p-3 rounded-lg border border-solid border-[#C2C0C3] text-[#393B41] text-xs not-italic font-medium leading-[normal] font-[Montserrat] w-full`}
          disabled
          value={dateStr}
        />
        <CalendarIcon
          className={`w-6 h-6 text-[#9D62FE] absolute -translate-y-2/4 right-2.5 top-2/4 ${
            disabled ? "pointer-events-none" : "cursor-pointer"
          }`}
          onClick={() => setShowDateRange(true)}
        />
        {showDateRange && (
          <>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRange(false);
              }}
            >
              <Calendar
                className="absolute right-0 top-10 shadow-md z-50"
                date={selectedDate}
                onChange={handleSelect}
              />
            </OutsideClickHandler>
          </>
        )}
      </div>
    </div>
  );
};

export default DateInput;
