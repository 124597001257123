import { useCallback, useEffect, useState } from "react";

export function useDevice() {
  const [isIPad, setIsIPad] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    if (window) {
      setIsIPad(window.innerWidth >= 768 && window.innerWidth < 1280);
      setIsMobile(window.innerWidth < 768);
    }
  }, []);

  useEffect(() => {
    handleResize();

    window && window.addEventListener("resize", handleResize);
    return () => {
      window && window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return {
    isIPad,
    isMobile,
  };
}
