import * as React from 'react'
import { ICustomer } from "shopify-api-node";

interface ICustomerWithTags extends ICustomer {
    tagsArray: string[]
} 

interface MembersTableProps {
    members: ICustomerWithTags[];
}

const MembersTable = (props: MembersTableProps) => {
  const { members } = props;

  return (
    <table className="min-w-full">
      <thead>
        <tr className="border-t border-gray-200">
          <th
            className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky -top-1 z-40"
            scope="col"
          >
            Name
          </th>
          <th
            className="pl-3 pr-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky -top-1 z-40"
            scope="col"
          >
            Email
          </th>
          <th
            className="pl-3 pr-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky -top-1 z-40"
            scope="col"
          >
            View on Shopify
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-300">
        {members?.length !== 0 &&
          members.map((member: ICustomerWithTags) => (
            <tr key={member.id}>
                {/* Start booking */}
                <td className="hidden px-3 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="flex items-center flex-shrink-0">
                    <button
                    type="button"
                    className="inline-flex items-center mx-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                    Start
                    </button>
                </div>
                </td>

                {/* Session/Time */}
                <td className={`px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900`}>
                <div className="flex items-center flex-shrink-0">
                    <div className="truncate hover:text-gray-600">
                    {member.first_name} {member.last_name}
                    </div>
                </div>
                </td>

                {/* Session/Time */}
                <td className={`px-3 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900`}>
                <div className="flex items-center flex-shrink-0">
                    <div className="truncate hover:text-gray-600">
                        {member.email}
                    </div>
                </div>
                </td>

                {/* Session/Time */}
                <td className={`px-3 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900`}>
                <div className="flex items-center flex-shrink-0">
                    <a
                        href={`https://hyper-karting.myshopify.com/admin/customers/${member.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        View on Shopify
                    </a>
                </div>
                </td>
        </tr>
          ))}
        {members.length === 0 && (
          <tr>
            <td colSpan={7}>
              <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">
                No records to display
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};



export default MembersTable