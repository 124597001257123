import { FC, useCallback, useEffect, useRef, useState } from "react";
import Label from "./Label";

interface Props {
  required?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: (val: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  hideLabel?: boolean;
  rows?: number;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

const TextAreaInput: FC<Props> = ({
  defaultValue = "",
  required = false,
  value: parentValue,
  label,
  hideLabel = false,
  rows = 4,
  onChange,
  disabled = false,
  className = "",
  placeholder = "",
}) => {
  const isMounted = useRef(null);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const validate = useCallback(() => {
    if (required && value.trim() === "") {
      setError("This field is required.");
    } else {
      setError("");
    }
  }, [required, value]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setValue(e.target.value);
      onChange && onChange(e.target.value, e);
    },
    [onChange]
  );

  useEffect(() => {
    if (!isMounted.current) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (parentValue !== undefined) {
      setValue(parentValue);
    }
  }, [parentValue]);

  return (
    <div className="flex flex-col w-full">
      {!hideLabel && !!label && <Label showRequiredSymbol={required}>{label}</Label>}

      <textarea
        className={`flex-1 p-3 rounded-lg border border-solid border-[#C2C0C3] text-[#393B41] text-xs not-italic font-medium leading-[normal] font-[Montserrat] ${className}`}
        value={value}
        onChange={handleChange}
        onBlur={validate}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={""}
      />
      {error && <p className="text-red-600 text-xs font-bold leading-[normal] font-[Montserrat]">{error}</p>}
    </div>
  );
};

export default TextAreaInput;
