import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { ARCADE_CARD_PRODUCT_ID } from "../../constants/Dashboard";
import { IFirebaseOrder } from "../../interfaces/firebaseEntities";
import { IOrder } from "shopify-api-node";
import { useArcadeCard } from "../../context/ArcadeCard";
import { IItem } from ".";
import CardNumber from "./CardNumberInput";

interface Props {
  order: IFirebaseOrder;
  handleViewOrderClick: (order: IOrder) => void;
}

const Row = (props: Props) => {
  const { order, handleViewOrderClick } = props;
  const { cardNumbers, generateId, filter, getItemsFromOrder } =
    useArcadeCard();

  const arcadeCardLineItems: IItem[] = useMemo(() => {
    const lineItems = order.line_items.filter(
      (li) => li.product_id === ARCADE_CARD_PRODUCT_ID
    );
    const items: IItem[] = [];

    lineItems.forEach((li) => {
      new Array(li.quantity)
        .fill(0)
        .map((_, i) => i + 1)
        .forEach((i) =>
          items.push({
            id: generateId(order.id, li.id, i),
            index: i,
            li: li,
            lineItemId: li.id,
            orderId: order.id,
          })
        );
    });

    return items;
  }, [order, generateId]);

  const isArchived = useMemo(() => {
    const items = getItemsFromOrder(order);
    return items.every((item) => cardNumbers[item.id]?.card_number);
  }, [order, getItemsFromOrder, cardNumbers]);

  return (
    <tr
      data-order-id={order.id}
      className={`${filter === "NOT_ARCHIVED" && isArchived ? "hidden" : ""}`} //If not archived tab is selected and order is archived then hide the row
    >
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full p-2">
            {`${order.customer?.first_name} ${order.customer?.last_name}`}
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full">
            <div className="flex items-center h-full">
              <div className="flex flex-col w-full h-full justify-evenly">
                {arcadeCardLineItems.map((li) => (
                  <div key={li.id} className="flex-1 flex items-center">
                    <div
                      className="truncate py-2"
                      key={li.id}
                    >{`$${li.li.price_set.shop_money.amount}`}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full">
            <div className="flex items-center h-full">
              <div className="flex flex-col w-full h-full justify-evenly">
                {arcadeCardLineItems.map((li) => (
                  <div key={li.id} className="flex-1 flex items-center">
                    <div className="truncate py-2" key={li.id}>
                      -
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full">
            <div className="flex items-center h-full">
              <div className="flex flex-col w-full h-full justify-evenly">
                {arcadeCardLineItems.map((li) => (
                  <div key={li.id} className="flex-1 flex items-center">
                    <div
                      className={`truncate py-2 rounded-md ${
                        li.li.variant_title === "10"
                          ? "bg-[#D2F3B7]"
                          : li.li.variant_title === "20"
                          ? "bg-[#F2F4B8]"
                          : li.li.variant_title === "50"
                          ? "bg-[#ECB4B2]"
                          : ""
                      } px-3`}
                    >{`${li.li.variant_title} taps`}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full">
            <div className="flex items-center h-full">
              <div className="flex flex-col w-full h-full justify-evenly">
                {arcadeCardLineItems.map((li) => (
                  <CardNumber
                    key={li.id}
                    card_number={cardNumbers[li.id]?.card_number}
                    item={li}
                    updatable={filter === "ARCHIVED"}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full py-2">
            <div className="flex flex-col items-center gap-1">
              <p className="text-[#777]">
                {DateTime.fromMillis(order.created_at_ts as number, {
                  zone: "Australia/Sydney",
                }).toFormat("hh:mm a")}
              </p>
              <p className="text-[#393B41]">
                {DateTime.fromMillis(order.created_at_ts as number, {
                  zone: "Australia/Sydney",
                }).toFormat("dd LLL yyyy")}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full">
            <div className="flex items-center h-full">
              <div className="flex flex-col w-full h-full justify-evenly">
                {arcadeCardLineItems.map((li) => (
                  <div key={li.id} className="flex-1 flex items-center">
                    <div className="truncate py-2" key={li.id}>
                      {li.orderId}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate hover:text-gray-600 h-full py-2">
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
              onClick={() => handleViewOrderClick(order)}
            >
              View Order
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
