import { ISessionWithBookings } from "../../interfaces/Session";
import {
  TBookingType,
  TMainTrackAgeFilters,
  TMainTrackRaceTypeFilter,
} from "../../interfaces";
import { EXPERT_RACE_TIMINGS } from "../../constants/Dashboard";
import { useCallback, useMemo, useState } from "react";
import { useModal } from "../../context/Modal";
import axios from "axios";
import { useSession } from "../../components/Session";
import { IProductVariant } from "shopify-api-node";
import { useBookingSession } from "../../context/BookingSession";

interface Props {
  session: ISessionWithBookings;
  type: TBookingType;
  hasUpdateSessionPermission: boolean;
}
const SessionVariantOptions = ({
  session,
  type,
  hasUpdateSessionPermission,
}: Props) => {
  const { setModalData, setshowModal } = useModal();
  const { refreshSessions } = useBookingSession();

  const variantId = useMemo(() => session.id, [session]);

  const onVariantUpdate = useCallback(() => {
    alert("Variant updated successfully!");
    setshowModal(false);
    refreshSessions();
  }, [refreshSessions, setshowModal]);

  const handleChangeAgeFilter: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        setModalData({
          id: "UPDATE_AGE_FILTER",
          onClose: () => setshowModal(false),
          title: "Update age filter",
          content: (
            <ConformationPopup
              onCancel={() => setshowModal(false)}
              payload={{
                id: variantId,
                data: {
                  option1: e.target.value,
                },
              }}
              onConfirm={onVariantUpdate}
            />
          ),
          className: "max-w-lg",
        });
        setshowModal(true);
      },
      [variantId, setModalData, setshowModal, onVariantUpdate]
    );

  const handleChangeRaceTypeFilter: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        setModalData({
          id: "UPDATE_RACE_TYPE_FILTER",
          onClose: () => setshowModal(false),
          title: "Update race type filter",
          content: (
            <ConformationPopup
              onCancel={() => setshowModal(false)}
              payload={{
                id: variantId,
                data: {
                  option2: e.target.value,
                },
              }}
              onConfirm={onVariantUpdate}
            />
          ),
          className: "max-w-lg",
        });
        setshowModal(true);
      },
      [variantId, setModalData, setshowModal, onVariantUpdate]
    );

  return (
    <>
      {type === "MAIN_TRACK" ? (
        <>
          {hasUpdateSessionPermission ? (
            <select
              value={session.option1 ?? undefined}
              onChange={handleChangeAgeFilter}
              className="w-full rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-bold text-xs leading-none text-gray-700"
            >
              <option value={TMainTrackAgeFilters["Ages 12-15"]}>
                {`12-15`}
              </option>
              <option value={TMainTrackAgeFilters["Ages 16+"]}>{`16+`}</option>
              <option value={TMainTrackAgeFilters["140cm+ | Ages under 12"]}>
                {`<12`}
              </option>
            </select>
          ) : (
            <span className="font-medium text-xs leading-tight text-gray-700">
              {session.option1}
            </span>
          )}
          <br />
          {hasUpdateSessionPermission ? (
            <select
              value={session.option2 ?? undefined}
              onChange={handleChangeRaceTypeFilter}
              className="w-full rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-bold text-xs leading-none text-gray-700"
            >
              <option value={TMainTrackRaceTypeFilter.Expert}>
                {TMainTrackRaceTypeFilter.Expert}
              </option>
              <option value={TMainTrackRaceTypeFilter.Standard}>
                {TMainTrackRaceTypeFilter.Standard}
              </option>
            </select>
          ) : (
            <span className="font-medium text-xs leading-tight text-gray-700">
              {session.option2}
            </span>
          )}
        </>
      ) : type === "MINI_TRACK" ? (
        <>
          <span className="font-medium text-xs leading-tight text-gray-700">
            {session.option3}
          </span>
          <br />
          <span className="font-medium text-xs leading-tight text-gray-700">
            {EXPERT_RACE_TIMINGS.includes(
              session.option1?.trim()?.toLowerCase() || ""
            )
              ? "Expert"
              : "Standard"}
          </span>
        </>
      ) : (type === "INTERMEDIATE_TRACK" || type === "JUNIOR_TRACK") ? (
        <>
          <span className="font-medium text-xs leading-tight text-gray-700">
            {session.option2}
          </span>
        </>
      ) : (
        <>
          <small className="font-medium text-xs leading-tight text-gray-700">
            {session.option2}
          </small>
        </>
      )}
    </>
  );
};

const ConformationPopup = ({
  onCancel,
  payload,
  onConfirm,
}: {
  onCancel: () => void;
  payload: { id: number; data: Partial<IProductVariant> };
  onConfirm: () => void;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { getToken } = useSession();

  const updatevariant = useCallback(async () => {
    try {
      setIsUpdating(true);
      const token = await getToken();
      await axios.put(
        "https://us-central1-hyper-karting.cloudfunctions.net/variant",
        // "http://127.0.0.1:5001/hyper-karting/us-central1/variant",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onConfirm && (await onConfirm());
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setIsUpdating(false);
    }
  }, [payload, getToken, onConfirm]);

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">Are you sure you want to make this change?</p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-red-500  hover:bg-red-700 rounded-full"
          onClick={onCancel}
          disabled={isUpdating}
        >
          Cancel
        </button>
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-green-500  hover:bg-green-700 rounded-full"
          onClick={updatevariant}
          disabled={isUpdating}
        >
          {isUpdating ? "..." : "Confirm"}
        </button>
      </div>
    </div>
  );
};

export default SessionVariantOptions;
