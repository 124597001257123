import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFirebase } from "../components/Firebase";
import { TLogDoc } from "../interfaces/types";
import {
  CollectionReference,
  OrderByDirection,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { ELogSortOptions, TObjFromArray } from "../interfaces";
import { handleQuerySnapshot } from "../utilities";

interface ILogContextType {
  loading: boolean;
  logs: TObjFromArray<TLogDoc>;
  sort: ELogSortOptions;
  pageNumber: number;
  applySort: (val: ELogSortOptions) => void;
  refresh: () => void;
  onPrevPageBtnClick: () => void;
  onNextPageBtnClick: () => void;
}

const LogContext = createContext<ILogContextType>(
  undefined as unknown as ILogContextType
);

const LogContextProvider: FC<PropsWithChildren<{ value: ILogContextType }>> = ({
  value,
  children,
}) => {
  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
};

export const useLog = () => {
  return useContext<ILogContextType>(LogContext);
};

interface Type {}
const pageSize = 250;

const Log: FC<PropsWithChildren<Type>> = ({ children }) => {
  const isMounted = useRef<boolean>();

  const [sort, setSort] = useState<ELogSortOptions>(ELogSortOptions.DESCENDING);
  const [pageNumber, setPageNumber] = useState(1);

  const [startAfterDoc, setStartAfterDoc] = useState<
    QueryDocumentSnapshot<TLogDoc> | undefined
  >();

  const [loading, setLoading] = useState(false);
  const [allLogs, setAllLogs] = useState<TObjFromArray<TLogDoc>>({});
  const [filteredLogs, setFilteredLogs] = useState<TObjFromArray<TLogDoc>>({});

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const logRef = useMemo(
    () => collection(db, "logs") as CollectionReference<TLogDoc>,
    [db]
  );

  const fetchLogs: (data?: {
    startAfterDoc?: QueryDocumentSnapshot<TLogDoc>;
    direction?: OrderByDirection;
  }) => Promise<TObjFromArray<TLogDoc>> = useCallback(
    async (data = {}) => {
      try {
        setLoading(true);
        const after = Object.keys(data || {}).includes("startAfterDoc")
          ? data?.startAfterDoc
          : startAfterDoc;

        const searchDirection: OrderByDirection = data?.direction
          ? data?.direction
          : sort === "ASCENDING"
          ? "asc"
          : "desc";

        const quertyInput = [
          logRef,
          orderBy("ts", searchDirection),
          limit(pageSize),
        ];

        if (after) {
          quertyInput.splice(quertyInput.length - 1, 0, startAfter(after));
        }

        const qs = (await getDocs(
          // @ts-ignore
          query(...quertyInput)
        )) as QuerySnapshot<TLogDoc>;

        const docs = qs.docs;
        const lastDocArr = docs.slice(-1);
        setStartAfterDoc(lastDocArr.length ? lastDocArr[0] : undefined);
        return handleQuerySnapshot<TLogDoc>(qs);
      } catch (error) {
        console.error(error);
        return {};
      } finally {
        setLoading(false);
      }
    },
    [logRef, sort, startAfterDoc]
  );

  const applySort = useCallback(
    (val: ELogSortOptions) => {
      setSort(val);
      fetchLogs({
        direction: val === ELogSortOptions.ASCENDING ? "asc" : "desc",
        startAfterDoc: undefined,
      }).then(setAllLogs);
    },
    [fetchLogs]
  );

  const onNextPageBtnClick = useCallback(async () => {
    const newPageNumber = pageNumber + 1;
    setPageNumber(newPageNumber);
    if (Object.keys(allLogs).length < newPageNumber * pageSize) {
      fetchLogs().then((newData) =>
        setAllLogs((oldData) => ({ ...oldData, ...newData }))
      );
    }
  }, [pageNumber, allLogs, fetchLogs]);

  const onPrevPageBtnClick = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const refresh = useCallback(() => {
    setSort(ELogSortOptions.DESCENDING);
    setPageNumber(1);
    fetchLogs({
      startAfterDoc: undefined,
    }).then(setAllLogs);
  }, [fetchLogs]);

  useEffect(() => {
    setFilteredLogs(
      Object.keys(allLogs)
        .filter((id, index) => {
          if (
            pageNumber * pageSize <= index ||
            (pageNumber - 1) * pageSize > index
          ) {
            return false;
          }
          return true;
        })
        .reduce((acc, id) => ({ ...acc, [id]: allLogs[id] }), {})
    );
  }, [pageNumber, allLogs]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      fetchLogs().then(setAllLogs);
    }
  }, [fetchLogs]);

  return (
    <LogContextProvider
      value={{
        logs: filteredLogs,
        loading,
        pageNumber,
        sort,
        applySort,
        onNextPageBtnClick,
        onPrevPageBtnClick,
        refresh,
      }}
    >
      {children}
    </LogContextProvider>
  );
};

export default Log;
