import { useCallback, useEffect, useMemo, useState } from "react";
import { useArcadeCard } from "../../context/ArcadeCard";
import { IItem } from ".";
import { XIcon } from "@heroicons/react/solid";

const CardNumber = ({
  card_number,
  item,
  variant = "medium",
  updatable = false,
}: {
  variant?: "small" | "medium";
  card_number: string;
  item: IItem;
  updatable?: boolean;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { saveCardNumber } = useArcadeCard();

  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState("");
  const changed = useMemo(
    () => (card_number ? card_number !== value : value),
    [card_number, value]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setSaving(true);
      await saveCardNumber({
        card_number: value,
        id: item.id,
        index: item.index,
        lineItemId: item.lineItemId,
        orderId: item.orderId,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  }, [item, saveCardNumber, value]);

  const handleCancelUpdateCardNumber = useCallback(() => {
    setValue(card_number || "");
    if (updatable) {
      setIsEditMode(false);
    }
  }, [card_number, updatable]);

  useEffect(() => {
    setValue(card_number || "");
  }, [card_number]);

  useEffect(() => {
    if (card_number) {
      if (!updatable) {
        setIsEditMode(false);
      }
    } else {
      setIsEditMode(true);
    }
  }, [updatable, card_number]);

  return (
    <div className="truncate py-2 flex gap-1">
      <input
        type="text"
        className={`border border-solid border-1 border-gray-300 rounded-md ${
          variant === "small" ? "text-xs italic font-medium p-1" : "p-2"
        }`}
        placeholder="Enter Card Number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={saving || !isEditMode}
      />
      {changed && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-green-500  hover:bg-green-700"
          onClick={handleSubmit}
          disabled={saving}
        >
          {saving ? "..." : "Save"}
        </button>
      )}
      {/* changed || (updatable && isEditMode && !!value) */}
      {isEditMode && !!value && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-red-500  hover:bg-red-700"
          onClick={handleCancelUpdateCardNumber}
        >
          <XIcon className="text-white w-3" />
        </button>
      )}
      {updatable && !isEditMode && (
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-slate-500  hover:bg-slate-700"
          onClick={() => setIsEditMode(true)}
          disabled={saving}
        >
          Edit
        </button>
      )}
    </div>
  );
};

export default CardNumber;
