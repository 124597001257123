import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { Transition } from "@headlessui/react";
import SideBar from "../Shared/SideBar";
import SideBarDialog from "../Shared/SideBarDialog";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { ROUTES } from "../../constants";
import { useFirebase } from "../Firebase";
import { useSession, SessionContext, doSignOut } from "../Session";
import { DefaultPermissions, SessionObject } from "../Session/useSession";
import PageNotFound from "../Shared/PageNotFound";
import PrivateRoute from "../Shared/PrivateRoute";
import Login from "../Shared/Login";
import { ToastProvider } from "../../context/ToastContext";
import { CommissionScreen } from "../CommissionScreen";
import LoadingSpinner from "../Loading";
import { MembersScreen } from "../MembersScreen";
import { FunctionCalendar } from "../FunctionCalendar";
import { PixelWall } from "../PixelWall";
import HamBurger from "../Shared/HamBurger";
import { Staff } from "../Staff";
import { IPermission } from "../../interfaces";
import { ArcadeGames, Dashboard, Issues, Logs, PriceChanger, SmsLogs, SpeedControl, EventsDay } from "../../pages";
import AppLogs from "../../pages/app-logs";

const AllowedDomains = ["@comet.cx", "@hyperkarting.com.au"];

const AppWithRoutes: React.FC = () => {
  const session = useSession();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const trackStaff = useMemo(() => session.permissions?.roles?.includes("TRACK_STAFF"), [session]);
  const hasSmsLogAccess = useMemo(() => session.permissions?.features?.includes("SMS_LOGS"), [session]);
  const hasEventDayTabAccess = useMemo(() => session.permissions?.features?.includes("EVENT_DAY_TAB"), [session]);

  const isDashboardRoute = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.pathname === "/dashboard";
    }
    return false;
  }, []);

  if (session.initializing) {
    return <LoadingSpinner />;
  }

  
  return (
    <>
      <ToastProvider>
        <Router>
          
          {!isDashboardRoute && <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <SideBarDialog setSidebarOpen={setSidebarOpen} />
            </Transition.Root>

            <HamBurger setSidebarOpen={setSidebarOpen} />
          </>}

          {/* Static sidebar for desktop */}
          <SideBar />

          {session.initializing ? (
            <LoadingSpinner />
          ) : (
            <Routes>
              <Route path={ROUTES.ROOT} element={<Navigate to={ROUTES.LOGIN} />} />
              <Route
                path={ROUTES.DASHBOARD}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.COMMISSION_SCREEN}
                element={
                  <PrivateRoute>
                    <CommissionScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.CALENDAR}
                element={
                  <PrivateRoute>
                    <FunctionCalendar />
                  </PrivateRoute>
                }
              />

              <Route
                path={ROUTES.ARCADE_CARDS}
                element={
                  <PrivateRoute>
                    <ArcadeGames />
                  </PrivateRoute>
                }
              />
              {!trackStaff && (
                <>
                  <Route
                    path={ROUTES.PIXEL_WALL}
                    element={
                      <PrivateRoute>
                        <PixelWall />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ROUTES.STAFF}
                    element={
                      <PrivateRoute>
                        <Staff />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ROUTES.SESSION_EDITOR}
                    element={
                      <PrivateRoute>
                        <PriceChanger />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
              {hasEventDayTabAccess && <Route path={ROUTES.EVENTS_DAY} element={<PrivateRoute><EventsDay /></PrivateRoute>} />}
              {hasSmsLogAccess && <Route path={ROUTES.SMS_LOGS} element={<SmsLogs />} />}
              <Route
                path={ROUTES.ISSUE}
                element={
                  <PrivateRoute>
                    <Issues />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.LOGS}
                element={
                  <PrivateRoute>
                    <Logs />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.APP_LOGS}
                element={
                  <PrivateRoute>
                    <AppLogs />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.SPEED_CONTROL}
                element={
                  <PrivateRoute>
                    <SpeedControl />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.MEMBERS} element={<MembersScreen />} />
              <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
              <Route path="*" element={<Navigate to={ROUTES.PAGE_NOT_FOUND} />} />
            </Routes>
          )}
        </Router>
      </ToastProvider>
    </>
  );
};

type TSessionState = Omit<SessionObject, "getToken">;

const AppWithAuth: React.FC = () => {
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const permissionsRef = useMemo(() => db.collection("permissions"), [db]);

  const [session, setSession] = useState<TSessionState>({
    initializing: true,
    auth: null,
    prof: null,
    permissions: DefaultPermissions,
  });

  const getToken = useCallback(async () => {
    if (session) {
      return session.auth?.getIdToken();
    }
    return undefined;
  }, [session]);

  useEffect(() => {
    // Retrieve the logged in user's profile
    let unsubscribeProfile = () => {};

    function onChange(newUser: any) {
      if (newUser === null) {
        // Not authenticated
        setSession({
          initializing: false,
          auth: null,
          prof: null,
          permissions: DefaultPermissions,
        });
      } else if (!AllowedDomains.some((domain) => newUser.email.endsWith(domain))) {
        alert("Email not allowed");
        doSignOut();
      } else {
        const email = newUser.email;

        permissionsRef
          .doc(email)
          .get()
          .then((doc: any) => {
            const permissions = (doc.data() as IPermission) ?? DefaultPermissions;

            setSession({
              initializing: false,
              auth: newUser,
              prof: null,
              permissions,
            });
          });
      }
    }

    // listen for auth state changes
    const unsubscribe = firebase.auth.onAuthStateChanged(onChange);

    return () => {
      unsubscribeProfile();
      unsubscribe();
    };
  }, [firebase, permissionsRef]);

  const sessionData: SessionObject = useMemo(() => ({ ...session, getToken }), [session, getToken]);

  return (
    <SessionContext.Provider value={sessionData}>
      <AppWithRoutes />
    </SessionContext.Provider>
  );
};

export { AppWithAuth };
