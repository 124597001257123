import React, { useCallback, useState } from 'react'
import { Tooltip } from "@material-tailwind/react";
import { ShieldExclamationIcon } from "@heroicons/react/solid"
import { IOrderRisk } from 'shopify-api-node';
import { useSession } from '../../components/Session';
import axios from 'axios';
import { useModal } from '../../context/Modal';
import Button from '../../components/Shared/Button';

const OrderRiskIcon = ({ risk, orderId, fraudulenceChecked, onClick }: { risk: IOrderRisk, orderId: number, fraudulenceChecked: boolean, onClick?: ()=>void }) => {
    const { setModalData, setshowModal } = useModal();

    const handleClick = useCallback(async () => {
        if(!fraudulenceChecked && orderId){
            try {
                console.log("orderId", orderId)

                setModalData({
                    id: "FRAUDULENCE_CHECK",
                    onClose: () => setshowModal(false),
                    title: "Fraudulence checked",
                    content: (
                      <ConformationModalContent
                        onCancel={() => setshowModal(false)} 
                        onUpdate={()=>{
                          if(onClick){
                            onClick()
                            setshowModal(false)
                          }
                        }}
                        orderId={orderId}
                      />
                    ),
                  });
                  setshowModal(true);
            } catch (error) {
                console.log(error)
                alert(error)
            }
        }
    },[orderId, fraudulenceChecked, onClick, setModalData, setshowModal])
    
    return (
        <Tooltip key={risk.id} content={
            <>
                <span>{`Risk LeveL: ${risk.recommendation}`}</span>
                <br />
                {fraudulenceChecked && <span>Fraudulent order has been checked</span>}
            </>
            } className="capitalize">
            <ShieldExclamationIcon
                className="hover:cursor-pointer"
                fill={`${fraudulenceChecked ? "#8a75ff" : (risk.recommendation === "cancel" ? "#E51C00" : "#f59e0b")}`}
                width={"1.75rem"}
                height={"1.75rem"}
                onClick={handleClick}
            />
        </Tooltip>
    )
}

const ConformationModalContent = ({ onCancel, onUpdate, orderId }: { onCancel: ()=> void, onUpdate: ()=> void, orderId: number })=>{  
    const [isLoading, setIsLoading] = useState(false);
    const { getToken } = useSession();

    const onClick = useCallback(async () => {
        try {
            setIsLoading(true)
            const token = await getToken();

            const { data } = await axios.delete(
                // "http://localhost:5000/hyper-karting/us-central1/orderRisk",
                "https://us-central1-hyper-karting.cloudfunctions.net/orderRisk",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        orderId
                    }
                }
            );
            console.log(data)
            if(data.success){
                onUpdate && onUpdate()
            }
        } catch (error) {
            console.log(error)
            alert(error)
        }finally{
            setIsLoading(false)
        }
    },[orderId, getToken, onUpdate])

    return (
      <div className="flex flex-col gap-1">
        <p className="my-4">
          {isLoading ? "Please wait…" :"Are you sure you would like to remove the fraudulent risk against this order?"}
        </p>
  
        <hr className="mt-2" />
        <div className="flex justify-end gap-2 mt-5">
  
          <Button onClick={onCancel} className="bg-red-500 cursor-pointer">
            No
          </Button>
          
          <Button onClick={onClick} loading={isLoading} disabled={isLoading}>
            Yes
          </Button>
        </div>
      </div>
    )
  }

export default OrderRiskIcon