import React, { useMemo } from 'react'
import { TLogType, TObjFromArray } from '../../interfaces'
import { IPriceChangerLog,  ISessionEditorLog,  TLogDoc } from '../../interfaces/types';
import THead from './THead';
import TRowOld from './TRowOld';
import { IProduct } from 'shopify-api-node';
import TRow from './TRow';

interface Props{
    searchText: string;
    logType: TLogType;
    logs: TObjFromArray<TLogDoc>;
    products: IProduct[];
    sessionEditorLogs: TObjFromArray<ISessionEditorLog>;
}

interface ISessionLog {
  variantId: string;
  log: ISessionEditorLog;
  lastLog?: ISessionEditorLog;
}

const Table = ({ logType, logs, products, searchText, sessionEditorLogs }:Props) => {
  const filteredLogs = useMemo(() => {
    let filteredLogs = Object.values(logs);
    if(logType === "PRICE_CHANGER"){
      filteredLogs =  getLatestLogByKey<IPriceChangerLog>(Object.values(logs) as IPriceChangerLog[], "priductId");
    }
    // if(logType === "QUANTITY_ADJUSTED"){
    //   return getLatestLogByKey<IQuantityAdjustLog>(Object.values(logs) as IQuantityAdjustLog[], "productId");
    // }
    return filteredLogs.filter(log => JSON.stringify(log || {}).toLowerCase().includes(searchText.toLowerCase()));
  }, [logs, logType, searchText])

  const filteredSessionEditorLogs:ISessionLog[] = useMemo(() => {
    const logObj: Record<string, ISessionEditorLog[]> = {};
    Object.values(sessionEditorLogs).filter(log => JSON.stringify(log?.user || {}).toLowerCase().includes(searchText.toLowerCase())).forEach(log=>{
      Object.keys((logType === "PRICE_CHANGER" ? log.price_changes : log.quantity_changes) || {}).forEach(variantId=>{
        if(logObj[variantId]){
          logObj[variantId].push(log);
        }else{
          logObj[variantId] = [log];
        }
      });
    });

    return Object.keys(logObj).map(variantId=>{
      const sortedLogs = logObj[variantId].sort((a, b) => b.ts - a.ts);
      return {
        variantId,
        log: sortedLogs[0],
        lastLog: sortedLogs.length > 1 ? sortedLogs[1] : undefined
      }
    });
  }, [sessionEditorLogs, logType,searchText])

  return (
    <div
        className={`dashboard-session-table-wrapper overflow-auto h-[calc(100%_-_3.5rem)] m-7`}
      >
        <table className="min-w-full h-[1px] ">
          <THead logType={logType}/>
          <tbody className="bg-white divide-y divide-gray-300">
            {filteredSessionEditorLogs.map(logData=>(
              <TRow logType={logType} log={logData.log} lastLog={logData.lastLog} variantId={+logData.variantId}/>
            ))}
            {filteredLogs.map((log) => (
              <TRowOld logType={logType} log={log} key={log.ts} allLogs={logs} products={products}/>
            ))}
          </tbody>
        </table>
        {(!Object.keys(logs).length && !filteredSessionEditorLogs.length) && <div className="text-center p-2 text-gray-400">No Records found</div>}
      </div>
  )
}

function getLatestLogByKey<T extends { ts: number }>(logs: T[],key: keyof T): T[] {
  const map = new Map<string, T>();

  logs.forEach(log => {
    const mapKey = log[key]?.toString(); // Convert the key to a string

    if (mapKey) {
      const existingLog = map.get(mapKey);

      if (!existingLog || log.ts > existingLog.ts) {
        map.set(mapKey, log);
      }
    }
  });

  return Array.from(map.values());
}

export default Table