import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  title: string;
  footerContent?: ReactNode;
  className?: string;
  headerContent?: ReactNode;
}
export default function ModalSection({
  title,
  children,
  footerContent,
  className,
  headerContent,
}: Props) {
  return (
    <div className={`p-4 border border-gray-200 rounded-md ${className || ""}`}>
      <div className="font-bold my-2 text-base leading-6 text-purple-600 flex">
        {title}
        {headerContent ? headerContent : <></>}
      </div>
      <hr className="mb-2" />
      {children}

      {footerContent && (
        <>
          <hr className="my-2" />
          {footerContent}
        </>
      )}
    </div>
  );
}
