import { FC } from "react";
import { ISMSLogDocument } from "../../interfaces/Logs";
import { DateTime } from "luxon";

interface Type {
  log: ISMSLogDocument;
}
const Row: FC<Type> = ({ log }) => {
  return (
    <tr>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div className="truncate">{log.type}</div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div>{log.user_phone_number}</div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          {DateTime.fromMillis(log.ts, {
            zone: "Australia/Sydney",
          }).toFormat("dd LLL yyyy hh:mm a")}
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div>{log?.user_name || ""}</div>
        </div>
      </td>
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 h-full">
          <div>{log?.user_email || ""}</div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
