import IssueContextProvider from "../../context/Issue";
import { PageLayout } from "../../layouts";
import Header from "./Header";
import IssuesPage from "./IssuesPage";

export const Issues = () => {
  return (
    <IssueContextProvider>
      <IssueComponent />
    </IssueContextProvider>
  );
};

const IssueComponent = () => (
  <>
    <PageLayout Header={<Header />}>
      <IssuesPage />
    </PageLayout>
  </>
);
