import { RefreshIcon } from "@heroicons/react/outline";
import DropDown from "../../components/Shared/DropDown";
import { ELogSortOptions } from "../../interfaces";
import { useLog } from "../../context/Log";

const Header = () => {
  const { sort, refresh, applySort } = useLog();
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
      <div className="flex-1"></div>

      <DropDown
        items={[
          {
            key: "ASCENDING",
            label: "Ascending",
          },
          {
            key: "DESCENDING",
            label: "Descending",
          },
        ]}
        selected={sort}
        onSelect={(val) => applySort(val as ELogSortOptions)}
      />

      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={console.log}
      >
        <RefreshIcon className="text-white w-6" onClick={refresh} />
      </div>
    </div>
  );
};

export default Header;
