import { PageLayout } from "../../layouts";
import Header from "./Header";
import Table from "./Table";

const LogsPage = () => {
  return (
    <PageLayout Header={<Header />}>
      <Table />
    </PageLayout>
  );
};

export default LogsPage;
