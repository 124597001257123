import { useEffect, useRef, useState } from "react";

interface Props {
  onChange?: (val: boolean) => void;
  defaultValue?: boolean;
  value?: boolean;
}
const ToggleSwitch = ({ onChange, defaultValue, value }: Props) => {
  const isMounted = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  useEffect(() => {
    if (!isMounted.current && defaultValue !== undefined) {
      setIsChecked(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== undefined) {
      setIsChecked(value);
    }
  }, [value]);

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id="switch"
        checked={isChecked}
        onChange={handleToggle}
        className="hidden"
      />
      <label
        htmlFor="switch"
        className="flex items-center cursor-pointer relative"
      >
        <div
          className={`relative w-8 h-4 rounded-full transition-colors ${
            isChecked ? "bg-purple-500" : "bg-gray-400"
          }`}
        ></div>
        <div
          className={`absolute w-3 h-3 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
            isChecked ? "translate-x-4" : "translate-x-0.5"
          }`}
        ></div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
