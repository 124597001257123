import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../components/Shared/Button";
import Checkbox from "../../components/Shared/Checkbox";
import DateInput from "../../components/Shared/DateInput";
import Label from "../../components/Shared/Label";
import NumberInput from "../../components/Shared/NumberInput";
import Radio from "../../components/Shared/Radio";
import TextAreaInput from "../../components/Shared/TextAreaInput";
import TextInput from "../../components/Shared/TextInput";
import TimeInput from "../../components/Shared/TimeInput";
import { EIssueModalTypes, useIssue } from "../../context/Issue";
import { EIssueType, ETower, EVrControllerType } from "../../interfaces";
import { IIssue } from "../../interfaces/Issue";
import { DateTime } from "luxon";
import { deepCopy } from "../../utilities";
import FileUpload from "../../components/Shared/FileUpload";

type Props =
  | {
      type: EIssueModalTypes.CREATE_ISSUE;
    }
  | {
      type: EIssueModalTypes.VIEW_ISSUE;
      issue: IIssue;
    };

const CreateIssueModalContent: FC<Props> = (props) => {
  const formId = useMemo(() => Date.now().toString(), []);
  const viewMode = useMemo(
    () => props.type === EIssueModalTypes.VIEW_ISSUE,
    [props]
  );
  const createMode = useMemo(
    () => props.type === EIssueModalTypes.CREATE_ISSUE,
    [props]
  );
  const [setsubmitBtnLoading, setSetsubmitBtnLoading] = useState(false);
  const [disableSumbitButton, setDisableSumbitButton] = useState(true);
  const { onCloseModal, createIssue, refresh } = useIssue();
  const [issue, setIssue] = useState<IIssue>(
    props.type === EIssueModalTypes.VIEW_ISSUE
      ? props.issue
      : {
          type: EIssueType.VR,
          status: "OPEN",
          timestamp: Date.now(),
          discription: "",
          staff_member_name: "",
          tower: ETower.A,
          controllers: [],
          headsets: 0,
          note: "",
          troubleshootingAttempt: "",
          vests: 0,
          imgs: [],
          formId,
        }
  );

  const handleChangeValue = useCallback(
    <K extends keyof IIssue>(key: K) =>
      (value: IIssue[K]) => {
        setIssue((oldState) => {
          const newState = deepCopy(oldState);
          newState[key] = value;
          return newState;
        });
      },
    []
  );

  const handleChangeDate = useCallback((val: Date) => {
    setIssue((oldState) => {
      const newState = deepCopy(oldState);
      let dt = DateTime.fromMillis(newState.timestamp);
      const parsedDt = DateTime.fromJSDate(val);

      if (dt.isValid) {
        dt = dt.set({
          day: parsedDt.day,
          month: parsedDt.month,
          year: parsedDt.year,
        });

        newState.timestamp = dt.toMillis();
      }
      return newState;
    });
  }, []);

  const handleChangeTime = useCallback((val: string) => {
    setIssue((oldState) => {
      const newState = deepCopy(oldState);
      const [hour, minute] = val.split(":");
      let dt = DateTime.fromMillis(newState.timestamp);

      if (hour && minute && +hour <= 24 && +minute <= 60 && dt.isValid) {
        dt = dt.set({
          hour: +hour,
          minute: +minute,
        });

        newState.timestamp = dt.toMillis();
      }
      return newState;
    });
  }, []);

  const onChangeController = useCallback(
    (controller: EVrControllerType) => (added: boolean) => {
      if (added) {
        setIssue((oldState) => {
          const newState = deepCopy(oldState);

          newState.controllers = [
            ...(new Set([...(newState.controllers || []), controller]) as any),
          ];

          return newState;
        });
      } else {
        setIssue((oldState) => {
          const newState = deepCopy(oldState);

          newState.controllers = (newState.controllers || []).filter(
            (e) => e !== controller
          );

          return newState;
        });
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setSetsubmitBtnLoading(true);

    createIssue(issue)
      .then(() => {
        refresh();
        onCloseModal(props.type);
      })
      .catch(console.log)
      .finally(() => {
        setSetsubmitBtnLoading(false);
      });
  }, [createIssue, refresh, issue, onCloseModal, props]);

  useEffect(() => {
    let validated = true;
    if (
      !DateTime.fromMillis(issue.timestamp).isValid ||
      !issue.staff_member_name ||
      !issue.tower ||
      !issue.discription
    ) {
      validated = false;
    }
    setDisableSumbitButton(!validated);
  }, [issue]);

  return (
    <div className="mt-8 h-full">
      <div className="flex flex-col gap-5 h-[calc(100%-110px)] overflow-auto">
        <div className="flex gap-4 items-end">
          <DateInput
            label="Timestamp:"
            required
            onChange={handleChangeDate}
            value={new Date(issue.timestamp)}
            disabled={viewMode}
          />
          <TimeInput
            onChange={handleChangeTime}
            value={DateTime.fromMillis(issue.timestamp).toFormat("HH:mm")}
            disabled={viewMode}
          />
        </div>
        <TextInput
          label="Staff Member Name:"
          required
          onChange={handleChangeValue("staff_member_name")}
          value={issue.staff_member_name}
          disabled={viewMode}
        />
        <div>
          <Label showRequiredSymbol>Tower/System:</Label>
          <div className="flex gap-4">
            <Radio
              label="Tower A"
              checked={issue.tower === ETower.A}
              onChange={() => handleChangeValue("tower")(ETower.A)}
              disabled={viewMode}
            />
            <Radio
              label="Tower B"
              checked={issue.tower === ETower.B}
              onChange={() => handleChangeValue("tower")(ETower.B)}
              disabled={viewMode}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <NumberInput
            label="Headsets:"
            className="max-w-xs"
            onChange={handleChangeValue("headsets")}
            value={issue.headsets}
            disabled={viewMode}
          />
          <NumberInput
            label="Vests:"
            className="max-w-xs"
            onChange={handleChangeValue("vests")}
            value={issue.vests}
            disabled={viewMode}
          />
          <div>
            <Label>Controllers:</Label>
            <div className="flex gap-4">
              <Checkbox
                label="Left"
                checked={!!issue.controllers?.includes(EVrControllerType.LEFT)}
                onChange={onChangeController(EVrControllerType.LEFT)}
                disabled={viewMode}
              />
              <Checkbox
                label="Right"
                checked={!!issue.controllers?.includes(EVrControllerType.RIGHT)}
                onChange={onChangeController(EVrControllerType.RIGHT)}
                disabled={viewMode}
              />
            </div>
          </div>
        </div>
        <TextInput
          label="Description of Issue:"
          required
          onChange={handleChangeValue("discription")}
          value={issue.discription}
          disabled={viewMode}
        />
        {createMode ? (
          <FileUpload
            label="Image Attachment:"
            accept={{
              "image/*": [".png", ".gif", ".jpeg", ".jpg"],
            }}
            onChange={handleChangeValue("imgs")}
            prefix="vr-issues"
            subPath={formId}
          />
        ) : (
          <>
            <Label>Image Attachments:</Label>
            {(issue?.imgs || []).map((url) => (
              <p
                className="text-gray-400 text-xs not-italic font-medium leading-[normal] font-[Montserrat] cursor-pointer"
                onClick={() => window.open(url)}
              >
                {url}
              </p>
            ))}
          </>
        )}
        <TextInput
          label="Troubleshooting Attempted:"
          onChange={handleChangeValue("troubleshootingAttempt")}
          value={issue.troubleshootingAttempt}
          disabled={viewMode}
        />
        <TextAreaInput
          label="Note:"
          onChange={handleChangeValue("note")}
          value={issue.note}
          disabled={viewMode}
        />
        <div className="h-2"></div>
      </div>
      <div className="mt-3 flex gap-4 justify-end">
        <Button onClick={() => onCloseModal(props.type)} type="secondary">
          {createMode ? "Cancel" : "Close"}
        </Button>
        {createMode && (
          <Button
            loading={setsubmitBtnLoading}
            onClick={handleSubmit}
            disabled={disableSumbitButton}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateIssueModalContent;
