import { DateTime } from "luxon";
import { TBookingType, TObjFromArray } from "../interfaces";
import { BREAK_BOOKING_NAME } from "../constants/Dashboard";
import { IOrder } from "shopify-api-node";
import { DocumentData, QuerySnapshot } from "firebase/firestore";

export function ValidateEmail(email: string) {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
}

export function ValidatePhone(phone: string) {
  return new RegExp(
    /^(\+\d{1,2}\s?)?1?-?.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  ).test(phone);
}

export const getDatetimeFromSku = (sku: string, track: TBookingType) => {
  let raceDate = sku?.split(".")?.[0];
  if (raceDate) {
    if (track === "MINI_TRACK") {
      raceDate = raceDate?.slice?.(1);
    }
    if (raceDate && !isNaN(raceDate as any)) {
      const dt = DateTime.fromFormat(raceDate, "yyMMddHHmm", {
        zone: "Australia/Sydney",
      });

      return dt;
    }
  }
  return null;
};

export const getTrackFromSku: (sku: string) => TBookingType = (sku) => {
  if (sku && sku.includes("VR")) {
    return "VR";
  } else if (sku && sku.includes("MI")) {
    return "MINI_TRACK";
  } else if (sku && sku.includes("J10")){
    return "JUNIOR_TRACK"
  } else if (sku && sku.includes("I10")){
    return "INTERMEDIATE_TRACK";
  }
  return "MAIN_TRACK";
};

export const isBreakOrder = (order: IOrder) => {
  return order?.customer?.first_name
    ?.toLowerCase()
    ?.includes?.(BREAK_BOOKING_NAME.toLowerCase());
};

export function setPropertyValue<T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): void {
  obj[key] = value;
}

export function deepCopy<T>(obj: T): T {
  if (typeof obj !== "object" || obj === null) {
    return obj as T; // Return primitive types, null, and undefined as is
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as T; // Recursively deep copy array elements
  }

  // Recursively deep copy object properties
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, deepCopy(value)])
  ) as T;
}

export function handleQuerySnapshot<T>(
  querySnapshot: QuerySnapshot<DocumentData>
): TObjFromArray<T> {
  const data: TObjFromArray<T> = {};
  querySnapshot.docs.forEach((doc) => {
    data[doc.id] = doc.data() as T;
  });
  return data;
}

export function formatPhoneNumber(number: string) {
  if (number.startsWith("04")) {
    return "614" + number.slice(2);
  }
  if (number.startsWith("+61")) {
    return number.slice(1);
  }
  if (number.startsWith("6104")) {
    return "614" + number.slice(4);
  }
  if (number.startsWith("4") && number.length === 9) {
    return "61" + number;
  }
  return number;
}

export function formatPhoneNumberForPodium(number: string) {
  return "+" + formatPhoneNumber(number);
}

export const getPossiblePhoneNumbers = (number: string) => [
  number,
  formatPhoneNumber(number),
  formatPhoneNumberForPodium(number),
  `0${number}`,
  `06${number}`,
  `61${number}`,
  `+61${number}`,
];

export function createBatches<T>(array: T[], batchSize: number): T[][] {
  const batches: T[][] = [];

  for (let i = 0; i < array.length; i += batchSize) {
    const batch = array.slice(i, i + batchSize);
    batches.push(batch);
  }

  return batches;
}
export const getAgeInYears = (dt: DateTime) => {
  return Math.abs(
    Math.ceil(
      dt.diffNow('years').years
    )
  )
}

export const getProductHandle = (date: Date, track: TBookingType) => {
  const dateString = DateTime.fromJSDate(date, {
    zone: "Australia/Sydney",
  }).toFormat("yyLLdd");
  if (track === "MAIN_TRACK") {
    return `${dateString}mt`;
  } else if (track === "MINI_TRACK") {
    return `0${dateString}mi`;
  } else if (track === "VR") {
    return `${dateString}vr`;
  } else if (track === "JUNIOR_TRACK") {
    return `${dateString}jt`;
  } else if (track === "INTERMEDIATE_TRACK") {
    return `${dateString}it`;
  }
  return undefined;
};

export const getMaxNumberOfSession = (type: TBookingType) => {
  return type === "MAIN_TRACK" ? 20 : (type === "MINI_TRACK" ? 8 : 6);
}

export const getTrackDisplayName = (type: TBookingType)=>{
  if(type === "MAIN_TRACK"){
    return "Adult Track"
  }else if(type === "MINI_TRACK"){
    return "Mini Track"
  }else if(type === "JUNIOR_TRACK"){
    return "Junior Track"
  }else if(type === "INTERMEDIATE_TRACK"){
    return "Intermediate Track"
  }else{
    return "Virtual Reality"
  }
}

export const getTrackColor = (type: TBookingType) =>{
  if(type === "MAIN_TRACK"){
    return "#FF00FF"
  }else if(type === "MINI_TRACK"){
    return "#9D62FE"
  }else if(type === "JUNIOR_TRACK"){
    return "#41BDFE"
  }else if(type === "INTERMEDIATE_TRACK"){
    return "#41BDFE"
  }else{
    return "#41BDFE"
  }
}

export const getOrderOccasion = (order: IOrder) => {
  const tags = order.tags.split(",")
  const occasionTag = tags.find(tag=>tag.includes("OCCASION:"))
  return occasionTag?.split(":")?.[1]
}