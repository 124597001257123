import { IKartItem } from "../../context/SpeedControl";

const Row = ({ currentKart }: { currentKart: IKartItem }) => {
  return (
    <tr data-kart-id={currentKart.kart_id}>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.best_time_raw}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.kart}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.total_laps}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.Penalty}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.pos}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.TopSpeed}</div>
        </div>
      </td>

      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.speedsetID}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.speedsetName}</div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
