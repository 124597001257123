import { classNames } from "../Utilities";
import { ROUTES } from "../../constants";
import { Link, useLocation } from "react-router-dom";
import useNavigation, {
  IPrimaryNavItem,
  ISecondaryNavItem,
} from "../../hooks/useNavigation";
import LogoutButton from "./LogoutButton";
import { useCallback, useMemo, useState } from "react";

const PrimaryNavItem = ({ item }: { item: IPrimaryNavItem }) => {
  return (
    <Link
      to={item.href}
      className={classNames(
        item.current
          ? "bg-gray-200 text-gray-900"
          : "text-gray-100 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      )}
      aria-current={item.current ? "page" : undefined}
    >
      <item.icon
        className={classNames(
          item.current
            ? "text-gray-500"
            : "text-gray-400 group-hover:text-gray-500",
          "mr-3 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {item.name}
    </Link>
  );
};

const SecondaryNavItem = ({ item }: { item: ISecondaryNavItem }) => {
  const [showChilds, setShowChilds] = useState(false);
  const childOpen = useMemo(
    () => item.childrens.find((children) => children.current),
    [item]
  );
  const toggleChilds = useCallback(() => {
    setShowChilds((oldVal) => !oldVal);
  }, []);

  return (
    <div className="bg-white bg-opacity-5 flex flex-col gap-2">
      <div
        className={classNames(
          childOpen
            ? "bg-gray-50 text-gray-900 bg-opacity-50"
            : "text-gray-100 hover:text-gray-900 hover:bg-gray-50 hover:bg-opacity-50",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
        onClick={toggleChilds}
      >
        {item.name}
        <svg
          className={`ml-auto w-4 h-4 transform transition-transform ${
            showChilds || childOpen ? "rotate-90" : ""
          }`}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
      {(showChilds || childOpen) &&
        item.childrens.map((childItem) => (
          <PrimaryNavItem item={childItem} key={childItem.name} />
        ))}
    </div>
  );
};

const SideBar = () => {
  const location = useLocation();
  const navigation = useNavigation();

  return location.pathname === ROUTES.LOGIN ? null : ( // Do not display the sidebar if we are on the login page
    <div className="hidden xl:flex xl:flex-col xl:w-64 xl:fixed xl:inset-y-0 xl:border-r xl:border-black xl:pt-5 xl:pb-4 xl:bg-black">
      <div className="flex items-center flex-shrink-0 px-6">
        <img
          className="w-auto"
          src="https://cdn.bfldr.com/LCMQM79M/as/6sjfqt5f64pvfb3v3fqhrqw7/Internal_App_Logos?auto=webp&format=png"
          alt="Hyper Karting"
        />
      </div>
      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
        {/* Navigation */}
        <nav className="px-3 mt-6">
          <div className="space-y-1">
            {navigation.map((item) =>
              item.type === "primary" ? (
                <PrimaryNavItem item={item} key={item.name} />
              ) : (
                <SecondaryNavItem item={item} key={item.name} />
              )
            )}
            <hr />
            <LogoutButton />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
