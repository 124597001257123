import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useFirebase } from "../../components/Firebase";
import {
  CollectionReference,
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import { handleQuerySnapshot } from "../../utilities";
import { TEvent } from "../../interfaces";

export type TModalSettings = {
  displayModal: boolean;
  displayFor: "Email Or SMS Confirmation" | "View Event Details" | null;
  confirmationFor: "SMS" | "Email" | null;
  eventId: string | null;
};

// Define the action type for the reducer
type ActionType =
  | {
      type: "SET_CREATE_EVENT_STATE";
      payload: {
        createEvent: TEvent;
      };
    }
  | {
      type: "SET_CREATED_EVENTS_LISTS_STATE";
      payload: {
        createEventsList: TEvent[];
      };
    }
  | {
      type: "SET_IS_MODAL_DISPLAYED";
      payload: {
        modalSettings: TModalSettings;
      };
    }
  | {
      type: "SET_VIEW_EVENT_DETAILS";
      payload: {
        viewEventDetails: TEvent;
      };
    };

// Define the context value
interface EventNotificationValue {
  createEvent: TEvent;
  dispatch: Dispatch<ActionType>;
  createEventsList: TEvent[];
  modalSettings: TModalSettings;
  viewEventDetails: TEvent;
  refetchEvents: () => void;
}

// Define the initial state for the context
const initialState: EventNotificationValue = {
  dispatch: () => {},
  createEvent: {
    id: uuidv4(),
    eventName: "",
    date: Date.now(),
    eventClosure: "",
    isSMS: false,
    isEmail: false,
    isClosed: false,
  } as TEvent,
  createEventsList: [] as TEvent[],
  modalSettings: {} as TModalSettings,
  viewEventDetails: {} as TEvent,
  refetchEvents: () => {}
};

// Create the context
const EventNotificationContext = createContext<
  EventNotificationValue | undefined
>(undefined);

// Define the reducer function
const reducer = (
  state: EventNotificationValue,
  action: ActionType
): EventNotificationValue => {
  switch (action.type) {
    case "SET_CREATE_EVENT_STATE":
      return {
        ...state,
        createEvent: action.payload.createEvent || initialState.createEvent,
      };
    case "SET_CREATED_EVENTS_LISTS_STATE":
      return { ...state, createEventsList: action.payload.createEventsList };
    case "SET_IS_MODAL_DISPLAYED":
      return { ...state, modalSettings: action.payload.modalSettings };
    case "SET_VIEW_EVENT_DETAILS":
      return { ...state, viewEventDetails: action.payload.viewEventDetails };
    default:
      return state;
  }
};

// Custom hook to consume the context
const useEventContext = (): EventNotificationValue => {
  const context = useContext(EventNotificationContext);
  if (!context) {
    throw new Error(
      "useEventContext must be used within an EventNotificationProvider"
    );
  }
  return context;
};

// Provider component
interface EventNotificationProviderProps {
  children: ReactNode;
}

// Provider component
const EventNotificationProvider: React.FC<EventNotificationProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const eventDayRef = useMemo(
    () => collection(db, "event_day") as CollectionReference<TEvent>,
    [db]
  );

  const refetchEvents = useCallback(() => {
    getDocs(query(eventDayRef)).then((snapshot) => {
      const events = handleQuerySnapshot<TEvent>(snapshot);
      const eventList = Object.values(events);
      console.log("events", events);
      dispatch({
        type: "SET_CREATED_EVENTS_LISTS_STATE",
        payload: {
          createEventsList: eventList,
        },
      });
    });
  }, [eventDayRef]);

  useEffect(() => {
    refetchEvents();
  }, [refetchEvents]);

  return (
    <EventNotificationContext.Provider
      value={{ ...state, dispatch, refetchEvents }}
    >
      {children}
    </EventNotificationContext.Provider>
  );
};

export { EventNotificationProvider, useEventContext };
