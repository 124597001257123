import React, { useCallback, useState } from "react";

type TComboButton = "LEFT" | "RIGHT";

interface Props {
  leftButtonName: string;
  rightButtonName: string;
  disableRightButton?: boolean;
  hideRightButton?: boolean;
  onChange?: (selected: TComboButton) => void;
}
export default function ComboButton({
  leftButtonName,
  rightButtonName,
  onChange,
  disableRightButton,
  hideRightButton,
}: Props) {
  const [selected, setSelected] = useState<TComboButton>("LEFT");

  const handleSelect = useCallback(
    (val: TComboButton) => () => {
      setSelected(val);
      onChange && onChange(val);
    },
    [onChange]
  );

  return (
    <div className="flex items-center justify-between rounded-full h-full bg-gray-100 hidden xl:flex">
      <Btn
        name={leftButtonName}
        selected={selected === "LEFT"}
        toggleSelect={handleSelect("LEFT")}
      />
      {!!!hideRightButton && (
        <Btn
          name={rightButtonName}
          selected={selected === "RIGHT"}
          toggleSelect={handleSelect("RIGHT")}
          disable={disableRightButton}
        />
      )}
    </div>
  );
}

function Btn({
  selected,
  name,
  toggleSelect,
  disable,
}: {
  selected: boolean;
  name: string;
  toggleSelect: () => void;
  disable?: boolean;
}) {
  return (
    <div
      className={`h-full rounded-full ${selected ? "hk-gradient-bg p-1" : ""} ${
        disable ? "pointer-events-none" : "cursor-pointer"
      }`}
      onClick={toggleSelect}
    >
      <div
        className={`flex justify-center items-center h-full px-4 font-bold text-sm rounded-full ${
          selected ? "bg-gray-800 text-white" : "bg-gray-100 text-gray-600"
        }`}
      >
        {name}
      </div>
    </div>
  );
}
