import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IFirebaseOrder } from "../../interfaces/firebaseEntities";
import ArcadeCardProvider, { useArcadeCard } from "../../context/ArcadeCard";
import CardNumber from "../arcade-cards/CardNumberInput";
import { Tooltip } from "@material-tailwind/react";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

interface Props {
  order: IFirebaseOrder;
  children: ReactNode;
  rowId: string;
}

const ArcadeCardTooltipWrapper = (props: Props) => {
  return (
    <ArcadeCardProvider type="order-level">
      <ArcadeCardTooltip {...props} />
    </ArcadeCardProvider>
  );
};

const ArcadeCardTooltip = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { order, children, rowId } = useMemo(() => props, [props]);
  const { updaseAllOrders } = useArcadeCard();

  useEffect(() => {
    if (open) {
      updaseAllOrders([order]);
    }
  }, [order, updaseAllOrders, open]);

  const toggleTooltip = useCallback(() => {
    setOpen((oldState) => !oldState);
  }, []);

  const closeTooltip = useCallback(() => {
    setOpen(false);
    updaseAllOrders([]);
  }, [updaseAllOrders]);

  return (
    <Tooltip
      placement="right"
      interactive
      open={open}
      handler={console.log}
      content={
        <TooltipContent
          order={order}
          closeTooltip={closeTooltip}
          rowId={rowId}
        />
      }
    >
      <div onClick={toggleTooltip}>{children}</div>
    </Tooltip>
  );
};

const TooltipContent = (props: {
  order: IFirebaseOrder;
  closeTooltip: () => void;
  rowId: string;
}) => {
  let navigate = useNavigate();

  const { order, closeTooltip, rowId } = useMemo(() => props, [props]);

  const { cardNumbers, getItemsFromOrder, loadingCardNumber, isOrderLoading } =
    useArcadeCard();

  const items = useMemo(
    () => getItemsFromOrder(order),
    [getItemsFromOrder, order]
  );

  const outSideClickHandler = useCallback(
    (e: any) => {
      const preventFromClose = e.target?.closest?.(`.${rowId}`);
      if (!preventFromClose) {
        closeTooltip();
      }
    },
    [closeTooltip, rowId]
  );

  const viewDetails = useCallback(() => {
    navigate(`${ROUTES.ARCADE_CARDS}`, {
      state: {
        ts: order.created_at_ts,
        orderId: order.id,
      },
    });
  }, [navigate, order]);

  if (loadingCardNumber || isOrderLoading) {
    return <div>Loading...</div>;
  }
  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item.id} className="flex items-center gap-4">
            <div className="flex gap-1">
              <p>{item.index}</p>
              <p>x</p>
              <p>{item.li.variant_title} taps</p>
            </div>
            <div className="text-black">
              <CardNumber
                variant="small"
                card_number={cardNumbers[item.id]?.card_number || ""}
                item={item}
              />
            </div>
          </div>
        ))}
        <button
          className="self-end items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full bg-purple-500  hover:bg-purple-700 hidden"
          onClick={viewDetails}
        >
          View
        </button>
      </div>
    </OutsideClickHandler>
  );
};

export default ArcadeCardTooltipWrapper;
