import { createContext, useContext } from "react";

import { User, signOut, getAuth } from "firebase/auth";

import { UserProfile } from "../Types/UserProfile";
import { IPermission } from "../../interfaces";

export interface SessionObject {
  initializing: boolean;
  auth: User | null;
  prof: UserProfile | null;
  permissions: IPermission;
  getToken: () => Promise<string | undefined>;
}

export const DefaultPermissions: IPermission = {
  roles: [],
};

const SessionContext = createContext<SessionObject>({
  auth: null,
  initializing: true,
  prof: null,
  permissions: DefaultPermissions,
  getToken: () => Promise.resolve(""),
});

const useSession = () => {
  const session = useContext(SessionContext);
  return session;
};

const doSignOut = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.log("Signed out");
    })
    .catch((error) => {
      console.error("Error signing out", error);
    });
};

export { SessionContext, useSession, doSignOut };
