import React, { Fragment, useMemo } from "react";
import { useBookingSession } from "../../context/BookingSession";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { ISessionWithBookings } from "../../interfaces/Session";
import PriceRow from "./PriceRow";
import { BookingDriverSeats } from "../../components/Common";
import { usePricechanger } from "../../context/PriceChanger";
import { TMainTrackAgeFilters } from "../../interfaces";

function PriceTable() {
  const { loading, filteredSessions, track, ageFilter, product, changeAgeFilter } = useBookingSession();
  const { recommendedPrices, config } = usePricechanger()
  const ageOptions = useMemo(() => (track === "MAIN_TRACK" ? Object.values(TMainTrackAgeFilters) : ["All Sessions"]), [track]);

  const sessions = useMemo(() =>filteredSessions.filter((session) => {
    let valid = true;
    if (track === "MAIN_TRACK") {
      if (ageFilter !== TMainTrackAgeFilters.ALL && !(session.option1 || "").toLowerCase().includes(ageFilter.toLowerCase())) {
        valid = false;
      }
    }
    return valid;
  }),[filteredSessions, ageFilter, track]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dashboard-session-table-wrapper m-7 h-[calc(100%_-_3.5rem)] overflow-auto">
      <table className="min-w-full h-[1px]">
        <thead>
          <tr>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
              scope="col"
            >
              Checklist
            </th>
            <th
              className="session-filter-header p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
              scope="col"
            >
              <select
                  className="mt-1 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={ageFilter}
                  onChange={(e) => changeAgeFilter(e.target.value as TMainTrackAgeFilters)}
                >
                  {ageOptions.map((opt) => {
                    return <option key={opt}>{opt}</option>;
                  })}
                </select>
            </th>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
              scope="col"
            >
              Qty
            </th>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left"
              scope="col"
            >
              New Prices
            </th>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
              scope="col"
            >
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {sessions?.length !== 0 &&
            sessions.map((session: ISessionWithBookings) => (
              <Fragment key={session.id}>
                <PriceRow session={session} recommendation={recommendedPrices.find(recommendedPrice => recommendedPrice.VARIANT_ID === session.id)} config={config} product={product}/>
                <tr>
                  <td colSpan={5} className="p-0">
                    <BookingDriverSeats session={session} view={track}/>
                  </td>
                </tr>
              </Fragment>
            ))}
          {sessions.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">
                  No records to display
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PriceTable;
