import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import "chart.js/auto";
import StaffInfoProvider from "../../context/Staff";
import BookingSessionProvider from "../../context/BookingSession";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Calendar } from "react-date-range";
import { TTrack } from "../../interfaces";
import StaffInfoChart from "./Chart";
import { getTrackDisplayName } from "../../utilities";

const getSloats: (date: DateTime) => number[] = (date) => {
  const sloats = [];

  let initTime = date.plus({ hour: 6, minute: 30 });
  for (let i = 0; i < 80; i++) {
    sloats.push(initTime.toMillis());
    initTime = initTime.plus({
      minute: 15,
    });
  }
  return sloats;
};

export const Staff = () => {
  const [selectedDate, setSelectedDate] = useState(
    DateTime.local({ zone: "Australia/Sydney" })
    // DateTime.fromFormat("230205", "yyMMdd", { zone: "Australia/Sydney" })
  );
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [track, setTrack] = useState<TTrack>("MAIN_TRACK");

  const handleSelect = (date: Date) => {
    setSelectedDate(DateTime.fromJSDate(date, { zone: "Australia/Sydney" }));
    setShowDateRange(false);
  };

  const handlePrevDayClick = () => {
    const date = selectedDate.minus({ days: 1 });
    setSelectedDate(date);
    setShowDateRange(false);
  };

  const handleNextDayClick = () => {
    const date = selectedDate.plus({ days: 1 });
    setSelectedDate(date);
    setShowDateRange(false);
  };

  const labels = useMemo(
    () =>
      getSloats(selectedDate.startOf("day")).map((sloat) =>
        DateTime.fromMillis(sloat, {
          zone: "Australia/Sydney",
        })
          .toFormat("h:mma")
          .toLowerCase()
      ),
    [selectedDate]
  );

  return (
    <>
      <StaffInfoProvider date={selectedDate.toJSDate()}>
        <BookingSessionProvider date={selectedDate.toJSDate()} track={track}>
          <div className="min-h-full">
            {/* Main column */}
            <div className="xl:pl-64 flex flex-col">
              {/* Search header */}
              <main className="flex-1">
                {/* Page title & actions */}
                <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:pl-4">
                  <div className="flex min-w-3 w-[100%] justify-between items-center">
                    <div className="date-range-wrapper flex justify-center relative ml-4 w-1/3">
                      <div className="flex items-center">
                        <ChevronLeftIcon
                          className="w-10 h-10 mr-2 cursor-pointer text-gray-500"
                          onClick={handlePrevDayClick}
                        />
                        <span
                          className="cursor-pointer text-gray-500 text-lg font-medium "
                          onClick={() => setShowDateRange(true)}
                        >
                          {selectedDate.toJSDate().toDateString()}
                        </span>
                        <ChevronRightIcon
                          className="w-10 h-10 ml-2 cursor-pointer text-gray-500"
                          onClick={handleNextDayClick}
                        />
                      </div>
                      {showDateRange && (
                        <Calendar
                          className="absolute right-0 top-10 shadow-md z-50"
                          date={selectedDate.toJSDate()}
                          onChange={handleSelect}
                        />
                      )}
                    </div>
                    <div className="flex justify-end w-1/3">
                      <span className="relative z-0 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className={`${
                            track === "MAIN_TRACK"
                              ? "bg-indigo-200 text-bold"
                              : "bg-white"
                          } relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`}
                          onClick={() => {
                            setTrack("MAIN_TRACK");
                            if (setShowDateRange) setShowDateRange(false);
                          }}
                        >
                          {getTrackDisplayName("MAIN_TRACK")}
                        </button>
                        <button
                          type="button"
                          className={`${
                            track === "MINI_TRACK"
                              ? "bg-indigo-200 text-bold"
                              : "bg-white"
                          } relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`}
                          onClick={() => {
                            setTrack("MINI_TRACK");
                            if (setShowDateRange) setShowDateRange(false);
                          }}
                        >
                          {getTrackDisplayName("MINI_TRACK")}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </main>

              <main className="flex-1">
                {/* Page title & actions */}
                <div className="pt-8 px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-xl font-semibold text-gray-900 pb-3">
                        Staff Info
                      </h1>
                      <p className="mt-2 text-sm text-gray-700"></p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      {/* BUTTON TO USE IN FUTURE? */}
                      {/* <button
                    type="button"
                    className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-2`}
                  >
                    Something
                  </button> */}
                    </div>
                  </div>
                </div>
                <div
                  className="hidden sm:block"
                  style={{ height: `calc(100vh - 80px)`, overflow: `hidden` }}
                >
                  <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-scroll h-[100%]">
                    <StaffInfoChart
                      labels={labels}
                      date={selectedDate}
                      track={track}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </BookingSessionProvider>
      </StaffInfoProvider>
    </>
  );
};
