import React from 'react'
import { TLogType } from '../../interfaces'

const THead = ({ logType }: {logType: TLogType})=>{

    if(logType === "QUANTITY_ADJUSTED"){
        return (
            <thead>
                <tr>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-start"
                        scope="col"
                    >
                        SESSION
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        CHANGE
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        BY
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        UPDATED ON
                    </th>
                </tr>
            </thead>
        )
    }
    if(logType === "PRICE_CHANGER"){
        return (
            <thead>
                <tr>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-start"
                        scope="col"
                    >
                        SESSION
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        LAST CHANGED BY
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        SINCE
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        CHANGED BY
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        CHANGE
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        QUANTITY
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        SEATS REMAINING
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        SKU
                    </th>
                    <th
                        className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-center"
                        scope="col"
                    >
                        UPDATED ON
                    </th>
                </tr>
            </thead>
        )
    }
    return (
        <></>
    )
}

export default THead