import SearchBar from "../../components/Shared/SearchBar";
import { RefreshIcon } from "@heroicons/react/outline";
import ComboButton from "../../components/Shared/ComboButton";
import { useIssue } from "../../context/Issue";
import Button from "../../components/Shared/Button";
import DropDown from "../../components/Shared/DropDown";
import { EVrIssueSortOptions } from "../../interfaces";

const Header = () => {
  const {
    sort,
    applySearchFilter,
    applyStatusFilter,
    createIssueBtnClick,
    refresh,
    applySort,
  } = useIssue();
  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
      <div className="w-1/3 h-full">
        <SearchBar onChange={applySearchFilter} />
      </div>

      <div className="flex-1"></div>

      <ComboButton
        leftButtonName="Open"
        rightButtonName="Closed"
        onChange={(val) => {
          if (val === "LEFT") {
            applyStatusFilter("OPEN");
          } else if (val === "RIGHT") {
            applyStatusFilter("CLOSED");
          }
        }}
      />

      <DropDown
        items={[
          {
            key: "ASCENDING",
            label: "Ascending",
          },
          {
            key: "DESCENDING",
            label: "Descending",
          },
        ]}
        selected={sort}
        onSelect={(val) => applySort(val as EVrIssueSortOptions)}
      />

      <Button onClick={createIssueBtnClick}>Report Issue</Button>

      <div
        className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
        onClick={console.log}
      >
        <RefreshIcon className="text-white w-6" onClick={refresh} />
      </div>
    </div>
  );
};

export default Header;
