import React from 'react'
import {  LogoutIcon } from "@heroicons/react/outline";
import { doSignOut } from "../Session";
import { classNames } from "../Utilities";

type Props = {
    setSidebarOpen? : any
}

const attemptSignOut = () => {
    doSignOut()
}

const LogoutButton = (props: Props) => {
  return (
    <button
        key="Logout"
        onClick={() => 
            { 
                attemptSignOut()
                if (props.setSidebarOpen)
                    props.setSidebarOpen(false)
            }}
        className={classNames("text-gray-100 hover:text-gray-900 hover:bg-gray-50 group w-[230px] flex items-center px-2 py-2 text-sm font-medium rounded-md")}
        >
        <LogoutIcon
            className={classNames("text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6")}
            aria-hidden="true"
        />
        Logout
    </button>
  )
}

export default LogoutButton