import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ICheckIn } from "../interfaces/CheckIn";
import axios from "axios";
import { DateTime } from "luxon";
import { useFirebase } from "../components/Firebase";
import { collection, doc, onSnapshot } from "firebase/firestore";

export interface ICheckedInContext {
  data: ICheckIn[];
  loadCheckedInOrders: () => void;
}

const CheckInContext = React.createContext<ICheckedInContext>({
  data: [],
  loadCheckedInOrders: () => {},
});

interface Type {
  date: Date;
  children: ReactNode;
}

const CheckInProvider: FC<Type> = ({ children, date }) => {
  const [data, setData] = useState<ICheckIn[]>([]);

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const checkInDBRef = useMemo(() => collection(db, "check_ins"), [db]);

  const loadCheckedInOrders = useCallback(async () => {
    try {
      // "http://localhost:5001/hyper-karting/us-central1/checkIn"||
      const { data } = await axios.get(
        "https://us-central1-hyper-karting.cloudfunctions.net/checkIn",
        {
          params: {
            ts: date.getTime(),
          },
        }
      );

      if (Array.isArray(data?.data)) {
        setData(Object.values(data.data) as ICheckIn[]);
      }
    } catch (error) {
      console.log("error in fetching loadCheckedInOrders data", error);
    }
  }, [date]);

  useEffect(() => {
    loadCheckedInOrders();
  }, [date, loadCheckedInOrders]);

  useEffect(() => {
    //Listen realtime checkin changes
    let d = DateTime.fromMillis(date.getTime()).setZone("Australia/Sydney");
    const day = d.toFormat("dd-MM-yyyy");

    const unsubscribe = onSnapshot(doc(checkInDBRef, day), (ss) => {
      const data = ss?.data();

      if (data) {
        const checkIns = Object.values(data) as ICheckIn[];
        setData(checkIns);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [date, checkInDBRef]);

  return (
    <CheckInContext.Provider value={{ data, loadCheckedInOrders }}>
      {children}
    </CheckInContext.Provider>
  );
};

export function useCheckIn() {
  return useContext(CheckInContext);
}

export default CheckInProvider;
