import { useEffect, useState } from "react";

import { Navigate, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants";
import { useFirebase } from "../Firebase";
import { useSession } from "../Session";

const Login: React.FC = () => {
  const firebase = useFirebase();
  const session = useSession();
  const prevRoute = useLocation();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    signInError: false,
  });

  const { email, password, signInError } = inputs;
  // Handle text box change
  const handleInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    event.persist();
    setInputs((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  // Listen for enter key
  const listenEnterKey = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      doSignIn();
    }
  };
  const doSignIn = async () => {
    try {
      setInputs((prev) => ({ ...prev, signInError: false }));
      console.log(email, password);
      await firebase.doSignInWithEmailAndPassword(email, password);
    } catch (e) {
      console.error(e);
      setInputs((prev) => ({ ...prev, signInError: true }));
    }
  };

  const doSignInWithGoogle = async () => {
    try {
      await firebase.doGoogleSignIn();
    } catch (e) {
      console.error(e);
      setInputs((prev) => ({ ...prev, signInError: true }));
    }
  };

  useEffect(() => {
    // Sample write to Firestore
    const accessFirestore = async () => {
      if (session.auth?.uid) {
        try {
          await firebase.db.collection("profiles").doc(session.auth.uid).set({
            key: "value",
          });
        } catch (error) {
          console.log("Error writing Firestore", error);
        }
      }
    };

    accessFirestore();
  }, [session.auth, firebase]);

  return (
    <>
      {session.auth && (
        <Navigate
          to={
            prevRoute && prevRoute.pathname !== ROUTES.LOGIN
              ? prevRoute.pathname
              : ROUTES.DASHBOARD
          }
        />
      )}
      {!session.auth && (
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-20 w-auto"
                  src="https://cdn.shopify.com/s/files/1/0496/8733/0976/files/hyperkaring_logo_400x.png"
                  alt="Workflow"
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Sign in to your account
                </h2>
              </div>

              <div className="mt-8">
                <button
                  className="flex items-center justify-center bg-white text-gray-600 py-2 px-4 rounded border border-gray-400 shadow m-auto"
                  onClick={doSignInWithGoogle}
                >
                  {/* <img
                    className="w-6 h-6 mr-2"
                    src={googleLogo}
                    alt="Google Logo"
                  /> */}
                  <span className="font-medium">Sign In with Google</span>
                </button>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          onChange={handleInputs}
                          value={inputs.email}
                          onKeyDown={listenEnterKey}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          onChange={handleInputs}
                          value={inputs.password}
                          placeholder="password"
                          onKeyDown={listenEnterKey}
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm">
                        <a
                          href="/"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>
                    {signInError && (
                      <p className="text-sm text-red-400">Error signing in!</p>
                    )}
                    <div>
                      <button
                        onClick={doSignIn}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://cdn.shopify.com/s/files/1/0496/8733/0976/files/HK--19_CheckoutImage_2.jpg"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
