import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";

import TimeAgo from "javascript-time-ago";
// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getApp } from "firebase/app";

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

export interface TimestampMap {
  _seconds: number;
  _nanoseconds: number;
  seconds: number;
  nanoseconds: number;
}

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const FUNCTION_NAMES = {
  getDrivers: "getDrivers",
  createDrivers: "createDrivers",
  updateDrivers: "updateDrivers",
  addDriverToBooking: "addDriverToBooking",
};

class Firebase {
  auth: any; // FIX ME find the correct types
  db: any; // FIX ME find the correct types
  functions: any; // FIX ME find the correct types
  twitterProvider: any; // FIX ME find the correct types
  googleProvider: any; // FIX ME find the correct types
  timeAgo: TimeAgo;
  FUNCTION_NAMES: typeof FUNCTION_NAMES;

  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    // For ease of access. Note that db normally refers to Firebase Realtime Database.
    this.db = app.firestore();
    this.functions = app.functions();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.timeAgo = new TimeAgo("en-US");
    this.FUNCTION_NAMES = FUNCTION_NAMES;
  }

  // *** Auth API ***

  // twitter signin handler
  doTwitterSignIn = async () => {
    this.auth.signInWithNavigate(this.twitterProvider);
  };

  // google signin handler
  doGoogleSignIn = async () => {
    this.googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    return this.auth.signInWithPopup(this.googleProvider);
  };

  // email and password signin handlers
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  getTimeText = (timeObject: any) => {
    // Convert to time text once it's of type firestore.Timestamp
    const getTextFromTimestamp = (timestamp: app.firestore.Timestamp) => {
      if (timestamp instanceof app.firestore.Timestamp) {
        return this.timeAgo.format(timestamp.toDate());
      } else {
        return "some time ago";
      }
    };
    if (timeObject instanceof app.firestore.Timestamp) {
      // Check if Timestamp (accessed from client SDK)
      return getTextFromTimestamp(timeObject);
    } else if (
      Object.prototype.toString.call(timeObject) === "[object Object]"
    ) {
      // Check if it's a Map (accessed from Cloud Functions)
      const timestamp = this.getTimestampFromMap(timeObject);
      if (timestamp) {
        return getTextFromTimestamp(timestamp);
      }
    }
    // Fallback
    console.log("Couldn't parse time. It is of type: " + typeof timeObject);
    console.log(timeObject);
    return "some time ago";
  };

  getTimestampDifference = (
    laterTime: app.firestore.Timestamp,
    earlierTime: app.firestore.Timestamp
  ) => {
    const msDifference = laterTime.toMillis() - earlierTime.toMillis();
    return msDifference;
  };

  getTimestampFromMap = (timeMap: TimestampMap) => {
    if (timeMap instanceof app.firestore.Timestamp) {
      throw new Error("Object is a timestamp, not a map");
    }
    const keyArr = Object.keys(timeMap);
    if (keyArr.includes("_seconds") && keyArr.includes("_nanoseconds")) {
      let seconds = timeMap["_seconds"];
      let nanoseconds = timeMap["_nanoseconds"];
      const timestamp = new app.firestore.Timestamp(seconds, nanoseconds);
      return timestamp;
    } else {
      console.error("Expected TimeMap keys not present");
      return null;
    }
  };

  getCallableFunction = (funName: string) => {
    if (!Object.keys(this.FUNCTION_NAMES).includes(funName)) {
      throw new Error("Function not exist");
    }
    const functions = getFunctions(getApp());

    if (process.env.REACT_APP_NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }

    const createDriver = httpsCallable(functions, funName);
    return createDriver;
  };
}

export { Firebase };
