import React, { useMemo } from "react";
import { PageLayout } from "../../layouts";
import SpeedControlProvider from "../../context/SpeedControl";
import Table from "./Table";
import Header from "./Header";
import { useSession } from "../../components/Session";

export const SpeedControl = () => {
  return (
    <SpeedControlProvider>
      <SpeedControlPage />
    </SpeedControlProvider>
  );
};

const SpeedControlPage = () => {
  const { permissions } = useSession();

  const hasManageScriptPermission = useMemo(
    () => permissions?.features?.includes("SPEED_CONTROL_SCRIPT_MANAGE"),
    [permissions]
  );

  return (
    <PageLayout Header={hasManageScriptPermission ? <Header /> : undefined}>
      <Table />
    </PageLayout>
  );
};
