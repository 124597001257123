import { DeviceMobileIcon, InboxIcon } from "@heroicons/react/outline";
import EventTableBody from "./EventTableBody";
import { useEventContext } from "./useEventsContext";
import EventsModal from "./EventsModal";
import { EEventsSortOptions, TDashboardView } from "./useEventDay";
import { useMemo } from "react";
import { deepCopy } from "../../utilities";
import { TEvent } from "../../interfaces";

const EventTable = ({
  dashboardView,
  sort,
}: {
  dashboardView: TDashboardView;
  sort: EEventsSortOptions;
}) => {
  const {
    modalSettings: { displayModal },
    createEventsList,
  } = useEventContext();

  const filteredEvents = useMemo(() => {
    const newEvents = deepCopy(createEventsList);
    newEvents.sort((a: TEvent, b: TEvent) => {
      if (a.date === null && b.date === null) return 0;
      if (a.date === null)
        return sort === EEventsSortOptions.ASCENDING ? 1 : -1;
      if (b.date === null)
        return sort === EEventsSortOptions.ASCENDING ? -1 : 1;
      return sort === EEventsSortOptions.ASCENDING
        ? a.date - b.date
        : b.date - a.date;
    });
    return newEvents.filter(
      (event) =>
        (dashboardView === "Open" && !event.isClosed) ||
        (dashboardView === "Closed" && event.isClosed)
    );
  }, [dashboardView, sort, createEventsList]);

  return (
    <div className="overflow-x-auto  h-[calc(100%_-_4.5rem)] mx-7 my-[15px]">
      <table className="w-full min-w-full">
        <thead className="bg-gray-50">
          <tr className="text-left">
            <th className="font-[500] text-left text-sm text-[#393B41] uppercase p-[15px]">
              Event name
            </th>
            <th className="font-[500] text-left text-sm text-[#393B41] uppercase p-[15px]">
              event date
            </th>
            <th className="font-[500] text-left text-sm text-[#393B41] uppercase p-[15px]">
              relevant closures
            </th>
            <th className="font-[500] text-left text-sm text-[#393B41] uppercase p-[15px]">
              <div className="flex items-center gap-[2px]">
                <DeviceMobileIcon className="h-[15px]" />
                <span>sms</span>
              </div>
            </th>
            <th className="font-[500] text-left text-sm text-[#393B41] uppercase p-[15px]">
              <div className="flex items-center gap-[2px]">
                <InboxIcon className="h-[15px]" />
                <span>email</span>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        {filteredEvents && filteredEvents.length > 0 && (
          <>
            <tbody className="bg-white w-full">
              {filteredEvents.map(
                (event: TEvent) =>
                  event.id && (
                    <EventTableBody
                      key={event.id}
                      id={event.id}
                      eventName={event.eventName}
                      date={event.date}
                      eventClosure={event.eventClosure}
                      isSMS={event.isSMS}
                      isEmail={event.isEmail}
                      isClosed={event.isClosed}
                      enableEditing={event.enableEditing}
                    />
                  )
              )}
            </tbody>
          </>
        )}
      </table>
      {displayModal && <EventsModal />}
    </div>
  );
};

export default EventTable;
