import { useEffect, useMemo, useState } from "react";
import {
  NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK,
  NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK,
  NUMBER_OF_SEATS_IN_A_VR,
} from "../../constants/Dashboard";
import { ISessionWithBookings } from "../../interfaces/Session";
import {
  getDriverCount,
  mainTrackSeatsSoldColour,
  miniTrackSeatsSoldColour,
  vrSeatsSoldColour,
} from "../../pages/dashboard/utilities";
import { TBookingType } from "../../interfaces";

const BookingDriverSeats = ({ session, view }: BookingDriverSeatsProps) => {
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [bookedPercentage, setBookedPercentage] = useState(0);
  const seatsSoldColour = useMemo(() => view === "VR" ? vrSeatsSoldColour(totalDrivers) : view === "MAIN_TRACK" ? mainTrackSeatsSoldColour(totalDrivers) : miniTrackSeatsSoldColour(totalDrivers), [view, totalDrivers]);

  useEffect(() => {
    let total = 0;
    let totalNumberOfSeats = view === "VR" ? NUMBER_OF_SEATS_IN_A_VR : view === "MAIN_TRACK" ? NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK : NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK;
    if(session.inventory_quantity > totalNumberOfSeats){
      totalNumberOfSeats = session.inventory_quantity;
    }
    session.bookings.forEach((x) => {
      const bookingDriverCount = getDriverCount(x);
      total += bookingDriverCount;
    });
    setTotalDrivers(total);
    const percentage = (total / totalNumberOfSeats) * 100;
    setBookedPercentage(percentage);
  }, [session, view]);

  return (
    <div className="h-4 w-full flex relative">
      <div
        className="text-black absolute z-[9] text-right pr-2 font-medium text-xs leading-3 rounded-r-md"
        style={{
          width: `${bookedPercentage}%`,
          maxWidth: "100%",
          backgroundColor: seatsSoldColour,
          lineHeight: "16px",
        }}
      >
        {totalDrivers}
      </div>
    </div>
  );
};

interface BookingDriverSeatsProps {
  session: ISessionWithBookings;
  view: TBookingType;
}

export default BookingDriverSeats;
