import { FC, useCallback } from "react";

interface Props {
  defaultValue?: boolean;
  checked: boolean;
  onChange?: (val: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
}
const Radio: FC<Props> = ({ checked, disabled = false, onChange, label }) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange && onChange(e.target.checked, e);
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-2">
      <input
        type="radio"
        className="accent-[#9D62FE] w-4 h-4"
        onChange={changeHandler}
        disabled={disabled}
        checked={checked}
      />
      {!!label && (
        <label className="text-xs not-italic font-medium leading-[normal] text-[#393B41] font-[Montserrat]">
          {label}
        </label>
      )}
    </div>
  );
};

export default Radio;
