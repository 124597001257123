import { useCallback } from "react";
import { IPriceRecommendation } from "../../interfaces";
import ConditionalWrapper from "../../components/Shared/ConditionalWrapper";
import { Tooltip } from "@material-tailwind/react";

interface IPriceBlockProps {
    isActualPrice: boolean;
    isSelected: boolean;
    price: number;
    bgColor: string;
    onClick: (val: number) => void;
    recommendation?: IPriceRecommendation;
    disabled?: string;
}

function PriceBlock({
    isActualPrice,
    isSelected,
    price,
    bgColor,
    onClick,
    recommendation,
    disabled
}: IPriceBlockProps) {
    const handleClickPriceBlock = useCallback(() => {
        if(!disabled){
            onClick(price);
        }
    }, [onClick, price, disabled]);

    return (
        <ConditionalWrapper  condition={!!disabled} Wrapper={<Tooltip content={disabled}><></></Tooltip>}>
            <div
                className={`h-full hk-gradient-bg rounded-md ${isActualPrice ? "pointer-events-none" : "cursor-pointer"} ${recommendation ? "p-1" : ""} ${!!disabled ? "opacity-50" : ""}`}
                onClick={handleClickPriceBlock}
            >
                <div className={`h-full font-bold border border-gray-300 rounded-md text-center p-3 flex justify-center items-center ${isActualPrice ? "text-gray-600 bg-gray-300" : ""} ${isSelected ? `${bgColor} text-white` : "bg-white"}`}>
                    ${price}
                </div>
            </div>
        </ConditionalWrapper>
    );
}

export default PriceBlock;